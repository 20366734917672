import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useAtomValue } from 'jotai';
import {
  getIdsAndSources,
  IMappingsRowDataModel,
  isPrimaryWebsite,
  isSecondaryWebsite,
} from '../mappingDataUtils';

import { companyState } from '../../../../services/state/CompanyState';
import { selectedCompanyIdProfileAtom } from '../../../CompanyProfiles/state/UIStateJ';
import { MappingsDataRenderer } from '../MappingsDataRenderer';

const commonCompanyColDefs = {
  filter: 'agTextColumnFilter',
};

export function useMappingGridColDefs() {
  const companyId = useAtomValue(selectedCompanyIdProfileAtom);
  const company = useRecoilValue(companyState(companyId));

  const colDefs: ColDef<IMappingsRowDataModel>[] = useMemo(
    () =>
      !company
        ? []
        : [
            {
              headerName: 'Provider',
              field: 'displayName',
              sortable: false,
            },
            {
              headerName: 'Category',
              field: 'category',
              rowGroup: true,
              hide: true,
            },
            {
              headerName: company.name,
              colId: String(company.id),
              ...commonCompanyColDefs,
              valueGetter: (params: ValueGetterParams<IMappingsRowDataModel>) => {
                const { data } = params;
                if (!data) return null;
                const companyId = Number(company.id);
                if (isPrimaryWebsite(data)) return data[companyId]?.primary;
                else if (isSecondaryWebsite(data)) return data[companyId]?.secondary;
                else return getIdsAndSources(data[companyId]);
              },
              cellRenderer: MappingsDataRenderer,
              sortable: false,
            },
          ],
    [company]
  );

  return colDefs;
}
