import { useRecoilCallback } from 'recoil';
import { disconnectIntegration } from '../../../services/queries/MaggieIntegrationsQueries';
import { integrationsState } from '../state/IntegrationsState';

export function useIntegrationActions() {
  const disconnect = useRecoilCallback(({ snapshot, gotoSnapshot }) => async (integrationId: number) => {
    const next = snapshot.map(({ set }) => {
      set(integrationsState, (prev) => {
        return (prev ?? []).map((integration) => {
          if (integration.id === integrationId) {
            return {
              ...integration,
              isConnected: false,
            };
          }
          return integration;
        });
      });
    });
    gotoSnapshot(next);

    try {
      await disconnectIntegration(integrationId);
    } catch (error) {
      console.error(error);
      gotoSnapshot(snapshot);
    }
  });

  return {
    disconnect,
  };
}
