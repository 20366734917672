import { useMemo } from 'react';
import { SchemaDescription, SchemaObjectDescription } from 'yup';
import { noop } from 'lodash-es';
import { Alert } from '@mui/material';
import { CardList, ICardProps } from '../components/KPISection/CardList';
import { SubSection } from '../../CompanyProfiles/Summary/components/SubSection';
import { FormatterService } from '../../../util/formatter-service';
import { FundMetricsKpiData } from '../../../data-models/fund-metrics.data-model';
import { fundMetricsByFundFormSchema } from '../../../schemas/FundMetrics.schema';
import { IDisplayField } from '../../../view-models/display-field.view-model';
import { FundFilterIndicator } from '../components/OverviewTable/Table/FundFilterIndicator';

export enum KPIAggFunc {
  dpi = 'dpi',
  irr = 'irr',
  tvpi = 'tvpi',
  moic = 'moic',
  rvpi = 'rvpi',
  sum = 'sum',
}

const KPIsToShow: Set<keyof FundMetricsKpiData> = new Set([
  'contributions',
  'distributions',
  'gpIncentive',
  'lpNav',
  'netIRR',
  'netReturn',
]);

interface IFundKPIsProps {
  kpiData?: FundMetricsKpiData;
  error: boolean;
  loading: boolean;
}

export function FundsKPIs({ kpiData, error, loading }: IFundKPIsProps) {
  const kpiCards = useMemo(() => {
    const { fields } = fundMetricsByFundFormSchema().fields.metrics.describe() as SchemaObjectDescription;

    return Object.keys(fields).reduce((res, fieldName) => {
      if (KPIsToShow.has(fieldName as keyof FundMetricsKpiData)) {
        const fieldDesc = fields[fieldName] as SchemaDescription;
        const format = FormatterService.get().getFormatterForFormField(
          fieldDesc.meta as IDisplayField<unknown>
        );
        const label = fieldDesc.label!;
        const value = (kpiData as FundMetricsKpiData)?.[fieldName as keyof FundMetricsKpiData];
        const formattedValue = typeof value === 'number' ? format(value) : '-';

        res.push({
          id: label,
          title: label,
          value: formattedValue,
          indicator: null,
        });
      }

      return res;
    }, [] as ICardProps[]);
  }, [kpiData]);

  if (error) return <Alert severity='error'>Error loading data</Alert>;

  return (
    <SubSection title={'Key Performance Indicators'} noCard endAdornment={<FundFilterIndicator />}>
      <CardList
        kpis={kpiCards}
        onDelete={noop}
        onReorder={noop}
        style={{
          gridTemplateColumns: 'repeat(auto-fit, minmax(10.5rem, 1fr))',
          opacity: loading ? 0.8 : 1,
        }}
      />
    </SubSection>
  );
}
