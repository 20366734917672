import { ValueFormatterFunc, ValueFormatterParams } from 'ag-grid-community';
import { get } from 'lodash-es';
import { getFormatterModelForField3, IField3 } from '../data-models/field3.data-model';
import { IDisplayField } from '../view-models/display-field.view-model';
import {
  FormatterFn,
  FormatterService,
  IPartialFormatterDataModel,
  StandardFormatterId,
} from './formatter-service';

export class AgFormatterService {
  static #instance: AgFormatterService | undefined;

  getFormatterForFieldV3<TData, TValue>(field: IField3<unknown>): ValueFormatterFunc<TData, TValue> {
    const model = getFormatterModelForField3(field);

    return this.#createFormatter(model);
  }

  getFormatterForId<TData, TValue>(formatterId: StandardFormatterId): ValueFormatterFunc<TData, TValue> {
    return this.#createFormatterForId(formatterId) ?? defaultAgFormatter;
  }

  getFormatterForFormField<TData, TValue>(field: IDisplayField<unknown>) {
    const formatter = FormatterService.get().getFormatterForFormField(field);

    return this.#createGridFormatter<TData, TValue>(formatter);
  }

  getFormatterForModel(formatterDataModel: IPartialFormatterDataModel<unknown>) {
    if (formatterDataModel.id) {
      this.#createFormatter(formatterDataModel);
    }

    return this.#createFormatter(formatterDataModel);
  }

  #createFormatter<TData, TValue>(
    formatterDataModel: IPartialFormatterDataModel<unknown>
  ): ValueFormatterFunc<TData, TValue> {
    const formatter = FormatterService.get().getFormatterForModel(formatterDataModel);
    const gridFormatter = this.#createGridFormatter<TData, TValue>(formatter);
    return gridFormatter;
  }

  #createFormatterForId<TData, TValue>(formatterId: StandardFormatterId): ValueFormatterFunc<TData, TValue> {
    const formatter = FormatterService.get().getFormatterForId(formatterId);
    const gridFormatter = this.#createGridFormatter<TData, TValue>(formatter);
    return gridFormatter;
  }

  #createGridFormatter<TData, TValue>(
    formatter: FormatterFn<TValue>,
    fieldPath?: string
  ): ValueFormatterFunc<TData, TValue> {
    return (params) => {
      const value = fieldPath ? get(params.data, fieldPath) : params.value;
      if (value === undefined || value === null) {
        return '';
      }
      return formatter(value);
    };
  }

  static initService() {
    if (!this.#instance) {
      this.#instance = new AgFormatterService();
    }

    return this.#instance;
  }

  static get() {
    if (!this.#instance) {
      this.#instance = this.initService();
    }
    return this.#instance;
  }

  static destroyService() {
    this.#instance = undefined;
  }
}

export function defaultAgFormatter<TData, TValue>(params: ValueFormatterParams<TData, TValue>) {
  return params.value;
}
