import { ButtonBase, Menu } from '@mui/material';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MouseEvent, ReactElement, useCallback, useMemo, useState } from 'react';
import { useRecoilState } from 'recoil';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { theme } from '../../../theme';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { AccountMenu } from '../../AccountMenu/AccountMenu';
import { CurrentUserAvatar } from '../../Avatar/CurrentUserAvatar';
import { Icon, IconTypes } from '../../Icon';
import { isNavigationBarStateOpen } from '../state/NavigationState';
import { PermissionService } from '../../../services/PermissionService';
import { PermissionKey, RoleKey } from '../../../services/PermissionAndRolesKeys';
import { useHasAdditionalNav } from '../../../hooks/useHasAdditionalNav';
import { HeaderButton } from '../../../constants/styles';
import { AddDealButton } from './AddModal';
import { NavItem } from './NavItem';
import { MenuToggle } from './MenuToggle';

export const MENU_WIDTH = 72;

const Wrapper = styled('nav')`
  display: grid;
  justify-content: center;

  grid-template-rows: auto 1fr auto;
  width: ${MENU_WIDTH}px;
  color: ${theme.colors.primary['0']};
  height: 100dvh;
  padding: 1rem 0;
  background: #232465;
  row-gap: 1rem;
`;

const HeaderContentWrapper = styled('div')`
  display: grid;
  grid-template-rows: 34px auto;
  justify-content: center;
  align-items: start;
  width: 100%;
  row-gap: 1rem;
`;

const SettingsWrapper = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const MainMenuWrapper = styled('div')`
  display: grid;
  overflow: auto;
  width: 100%;
  align-content: start;
  @media screen and (min-height: 900px) {
    margin-top: 1rem;
  }
`;

const AvatarWrapper = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-top: 10px;
  cursor: pointer;
`;

export function Navbar(): ReactElement {
  const { showDataCollectionModule, showElasticSearchResults } = useFlags<MaggieFeatureFlags>();
  const permSvc = PermissionService.get();
  const showAdminPanel = permSvc.hasPermission(PermissionKey.canViewAppAdminPanel);
  const [isOpen, setIsOpen] = useRecoilState(isNavigationBarStateOpen);

  const hasAdditionalNav = useHasAdditionalNav();

  const MAIN_NAV_ITEMS = useMemo(() => {
    if (PermissionService.get().hasRole(RoleKey.managedServices)) {
      return [
        {
          icon: <Icon type={IconTypes.KPI_COLORED} color={'rgba(0, 0, 0, 0)'} />,
          text: 'KPIs',
          path: `${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}`,
          showItem: showDataCollectionModule && permSvc.hasPermission(PermissionKey.hasDataCollectionModule),
        },
      ];
    }
    return [
      {
        icon: <Icon type={IconTypes.COMPANIES_COLORED} color={'rgba(0, 0, 0, 0)'} />,
        text: 'Companies',
        path: ROUTES.COMPANIES,
        showItem: permSvc.hasPermission(PermissionKey.canViewList),
      },
      {
        icon: <Icon type={IconTypes.PORTFOLIO_COLORED} color={'rgba(0, 0, 0, 0)'} />,
        text: 'Portfolio',
        path: ROUTES.PORTFOLIO,
        showItem: permSvc.hasPermission(PermissionKey.canViewMetrics),
      },
      {
        icon: <Icon type={IconTypes.ANALYSIS_COLORED} color={'rgba(0, 0, 0, 0)'} />,
        text: 'Analysis',
        path: ROUTES.COMPANY_COMPARISON,
        showItem: permSvc.hasPermission(PermissionKey.hasAnalysisModule),
      },
      {
        icon: <Icon type={IconTypes.FINANCE_COLORED} color={'rgba(0, 0, 0, 0)'} />,
        text: 'Finance',
        path: `${ROUTES.FINANCE2}/${ROUTES.FINANCE2_OVERVIEW}`,
        showItem: permSvc.hasPermission(PermissionKey.canViewTransaction),
      },
      {
        icon: <Icon type={IconTypes.DEALBOARD_COLORED} color={'rgba(0, 0, 0, 0)'} />,
        text: 'Pipeline',
        path: `${ROUTES.DEAL_FLOW}/${ROUTES.DEALS}/`,
        showItem: permSvc.hasPermission(PermissionKey.canViewDeal),
      },

      {
        icon: <Icon type={IconTypes.KPI_COLORED} color={'rgba(0, 0, 0, 0)'} />,
        text: 'KPIs',
        path: `${ROUTES.KPI}/${ROUTES.KPI_REQUESTS}`,
        showItem: showDataCollectionModule && permSvc.hasPermission(PermissionKey.hasDataCollectionModule),
      },
    ];
  }, [permSvc, showDataCollectionModule]);

  const handleOnAdditionalMenuClick = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, [setIsOpen]);

  return (
    <Wrapper data-testid='navbar'>
      <HeaderContentWrapper>
        {hasAdditionalNav ? <MenuToggle isOpen={isOpen} onClick={handleOnAdditionalMenuClick} /> : <div />}
        {permSvc.hasPermission(PermissionKey.canEditDeal) && !showElasticSearchResults && <AddDealButton />}
      </HeaderContentWrapper>
      <MainMenuWrapper>
        {MAIN_NAV_ITEMS.map(({ icon, text, path, showItem }) =>
          showItem ? <NavItem key={path} icon={icon} text={text} path={path} /> : null
        )}
      </MainMenuWrapper>
      <SettingsWrapper>
        {showAdminPanel && (
          <NavItem
            key={ROUTES.ADMIN_PANEL}
            icon={<Icon type={IconTypes.ADMIN_PANEL_COLORED} color={'rgba(0, 0, 0, 0)'} />}
            text='Admin Panel'
            path={ROUTES.ADMIN_PANEL}
          />
        )}
        {!showElasticSearchResults && <UserMenu />}
      </SettingsWrapper>
    </Wrapper>
  );
}

const RoundedButton = css`
  border-radius: 50%;
  & .MuiAvatar-circular {
    ${HeaderButton};
    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export function UserMenu() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { showElasticSearchResults } = useFlags<MaggieFeatureFlags>();

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  return (
    <>
      {showElasticSearchResults ? (
        <ButtonBase
          css={RoundedButton}
          onClick={(e: MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <CurrentUserAvatar size='mediumLarge' />
        </ButtonBase>
      ) : (
        <AvatarWrapper
          onClick={(e: MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            handleClick(e);
          }}
        >
          <CurrentUserAvatar size={'medium'} style={{ color: 'black' }} />
        </AvatarWrapper>
      )}

      <Menu
        anchorEl={anchorEl}
        open={open}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        onClose={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
          e.stopPropagation();
          handleCloseMenu();
        }}
      >
        <AccountMenu onCloseMenu={handleCloseMenu} />
      </Menu>
    </>
  );
}
