import { Fund } from '../../schemas/Fund.schema';
import { IInitializedParser } from './IInitializedParser';

export class FundParser implements IInitializedParser<Map<number, Fund>, number | null> {
  #fundNameToFund: Map<string, Fund> | undefined;

  init = (funds: Map<number, Fund>) => {
    this.#fundNameToFund = [...funds.values()].reduce((map, fund: Fund) => {
      map.set(fund.name.toLowerCase(), fund);
      fund.aliases?.forEach((alias) => map.set(alias.toLowerCase(), fund));
      return map;
    }, new Map<string, Fund>());
    return this;
  };

  parse = (value?: string | null) => {
    return this.#fundNameToFund?.get(value?.toLowerCase() ?? '')?.id ?? null;
  };
}
