import * as React from "react";
const SvgSlack = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: "40px", height: "40px", viewBox: "0 0 40 40", ...props }, /* @__PURE__ */ React.createElement("g", { id: "surface1" }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(87.843138%,11.764706%,35.294119%)",
  fillOpacity: 1
}, d: "M 8.566406 25.195312 C 8.566406 27.496094 6.707031 29.355469 4.410156 29.355469 C 2.109375 29.355469 0.253906 27.496094 0.253906 25.195312 C 0.253906 22.898438 2.109375 21.039062 4.410156 21.039062 L 8.566406 21.039062 Z M 10.644531 25.195312 C 10.644531 22.898438 12.503906 21.039062 14.804688 21.039062 C 17.101562 21.039062 18.960938 22.898438 18.960938 25.195312 L 18.960938 35.589844 C 18.960938 37.890625 17.101562 39.746094 14.804688 39.746094 C 12.503906 39.746094 10.644531 37.890625 10.644531 35.589844 Z M 10.644531 25.195312 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(21.176471%,77.254903%,94.117647%)",
  fillOpacity: 1
}, d: "M 14.804688 8.503906 C 12.503906 8.503906 10.644531 6.644531 10.644531 4.347656 C 10.644531 2.046875 12.503906 0.1875 14.804688 0.1875 C 17.101562 0.1875 18.960938 2.046875 18.960938 4.347656 L 18.960938 8.503906 Z M 14.804688 10.613281 C 17.101562 10.613281 18.960938 12.472656 18.960938 14.773438 C 18.960938 17.070312 17.101562 18.929688 14.804688 18.929688 L 4.378906 18.929688 C 2.078125 18.929688 0.21875 17.070312 0.21875 14.773438 C 0.21875 12.472656 2.078125 10.613281 4.378906 10.613281 Z M 14.804688 10.613281 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(18.039216%,71.372551%,49.019608%)",
  fillOpacity: 1
}, d: "M 31.464844 14.773438 C 31.464844 12.472656 33.324219 10.613281 35.621094 10.613281 C 37.921875 10.613281 39.78125 12.472656 39.78125 14.773438 C 39.78125 17.070312 37.921875 18.929688 35.621094 18.929688 L 31.464844 18.929688 Z M 29.386719 14.773438 C 29.386719 17.070312 27.527344 18.929688 25.226562 18.929688 C 22.929688 18.929688 21.070312 17.070312 21.070312 14.773438 L 21.070312 4.347656 C 21.070312 2.046875 22.929688 0.1875 25.226562 0.1875 C 27.527344 0.1875 29.386719 2.046875 29.386719 4.347656 Z M 29.386719 14.773438 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(92.54902%,69.803923%,18.039216%)",
  fillOpacity: 1
}, d: "M 25.226562 31.433594 C 27.527344 31.433594 29.386719 33.292969 29.386719 35.589844 C 29.386719 37.890625 27.527344 39.746094 25.226562 39.746094 C 22.929688 39.746094 21.070312 37.890625 21.070312 35.589844 L 21.070312 31.433594 Z M 25.226562 29.355469 C 22.929688 29.355469 21.070312 27.496094 21.070312 25.195312 C 21.070312 22.898438 22.929688 21.039062 25.226562 21.039062 L 35.652344 21.039062 C 37.953125 21.039062 39.8125 22.898438 39.8125 25.195312 C 39.8125 27.496094 37.953125 29.355469 35.652344 29.355469 Z M 25.226562 29.355469 " })));
export default SvgSlack;
