import { useRecoilValue } from 'recoil';
import { useEffect, useMemo, useState } from 'react';
import { useAtomValue } from 'jotai';
import { lastFieldUpdateDateState, providerConfigByNameMapState } from '../../state/MappingsState';
import { useGetProviderData } from '../../../CompanyProfiles/hooks/useGetProviderData';
import { IProviderFieldsDataModel } from '../../../../data-models/provider-fields.data-model';
import {
  formatCategoryName,
  getProvidersMap,
  IMappingsRowDataModel,
  isSecondaryWebsite,
  prepProviderData,
} from '../mappingDataUtils';
import { companyState } from '../../../../services/state/CompanyState';
import { selectedCompanyIdProfileAtom } from '../../../CompanyProfiles/state/UIStateJ';

export function useMappingsRowData() {
  const providersConfigByNameMap = useRecoilValue(providerConfigByNameMapState);
  const companyId = useAtomValue(selectedCompanyIdProfileAtom);
  const company = useRecoilValue(companyState(companyId));
  const getProvidersForCompanies = useGetProviderData();
  const [allProviders, setAllProviders] = useState<IProviderFieldsDataModel<unknown>[]>();
  const [loading, setLoading] = useState(false);
  const lastFieldUpdateDate = useRecoilValue(lastFieldUpdateDateState);

  useEffect(() => {
    if (!company) return;
    setLoading(true);
    getProvidersForCompanies([company.id]).then((allProviders) => {
      const flatData = Array.from(allProviders.values()).flat();
      setAllProviders(prepProviderData(flatData));
      setLoading(false);
    });
  }, [company, getProvidersForCompanies, lastFieldUpdateDate]);

  const providersMap = useMemo(() => {
    if (!allProviders) return null;
    return getProvidersMap(allProviders);
  }, [allProviders]);

  const rowData: IMappingsRowDataModel[] | null = useMemo(() => {
    if (!providersMap || !providersConfigByNameMap) return null;
    return Array.from(providersMap.keys()).map((providerDisplayName) => {
      const providerDataForAllCompanies = providersMap.get(providerDisplayName);
      const row = {} as IMappingsRowDataModel;
      return providerDataForAllCompanies!.reduce((record, providerData) => {
        const companyId = providerData.companyId;
        record[companyId] = providerData;
        record.id = providerData.id;
        record.provider = providerData.provider;
        record.category = formatCategoryName(providerData.provider);
        // if error is already set, don't overwrite it because there may be multiple companies,
        // and we want to show the error if any of them have it
        if (!record.hasError) {
          record.hasError = !isSecondaryWebsite(providerData) && providerData.primary === null;
        }
        record.displayName = providerDisplayName;
        return record;
      }, row);
    });
  }, [providersConfigByNameMap, providersMap]);

  return { rowData, loading };
}
