import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
  RawAxiosRequestHeaders,
} from 'axios';
import { getEnvVariable } from '../util/environment';

export interface IMaggieAxiosHttpConf {
  baseURL: string;
  on401Callback?: (response: AxiosResponse) => void;
  getToken: () => Promise<string | undefined>;
}

export class MaggieAxiosHttp {
  private readonly config: IMaggieAxiosHttpConf;

  private axiosInstance: AxiosInstance;

  constructor(config: IMaggieAxiosHttpConf) {
    this.config = config;
    this.axiosInstance = this.initAxios();
  }

  async delete<ResponseType>(
    url: string,
    config: AxiosRequestConfig<null> | undefined = undefined
  ): Promise<AxiosResponse<ResponseType>> {
    return this.axiosInstance.delete<ResponseType, AxiosResponse<ResponseType>, null>(url, config);
  }

  async get<ResponseType>(
    url: string,
    config: AxiosRequestConfig<null> | undefined = undefined
  ): Promise<AxiosResponse<ResponseType>> {
    return this.axiosInstance.get<ResponseType, AxiosResponse<ResponseType>, null>(url, config);
  }

  async post<RequestType, ResponseType>(
    url: string,
    data?: RequestType,
    config?: AxiosRequestConfig<RequestType>
  ): Promise<AxiosResponse<ResponseType>> {
    return this.axiosInstance.post<ResponseType, AxiosResponse<ResponseType>, RequestType>(url, data, config);
  }

  async put<RequestType, ResponseType>(
    url: string,
    data?: RequestType,
    config?: AxiosRequestConfig<RequestType>
  ): Promise<AxiosResponse<ResponseType>> {
    return this.axiosInstance.put<ResponseType, AxiosResponse<ResponseType>, RequestType>(url, data, config);
  }

  private initAxios(): AxiosInstance {
    const { baseURL, on401Callback, getToken } = this.config;
    const axiosInstance = axios.create();
    axiosInstance.defaults.baseURL = baseURL;

    axiosInstance.interceptors.request.use(async (config) => {
      if (!getToken) {
        throw new Error('No token provided');
      }
      config.headers = {
        ...((config.headers || {}) as RawAxiosRequestHeaders),
        Authorization: `Bearer ${(await getToken()) ?? ''}`,
      } as AxiosRequestHeaders;

      return config;
    });
    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if ((error?.response as Response)?.status === 401) {
          // FIXME: Atm we are not getting the 401 see MAGGIE-2853
          on401Callback?.(error);
        }
        return Promise.reject(error);
      }
    );

    return axiosInstance;
  }

  getAxiosInstance() {
    return this.axiosInstance;
  }
}

export class MaggieHttp {
  static maggie: MaggieAxiosHttp;

  static init(config: Omit<IMaggieAxiosHttpConf, 'baseURL'>) {
    this.maggie = new MaggieAxiosHttp({
      baseURL: getEnvVariable('VITE_MAGGIE_API'),
      ...config,
    });
  }
}

export class MaggieSourcingHttp {
  static maggie: MaggieAxiosHttp;

  static init(config: Omit<IMaggieAxiosHttpConf, 'baseURL'>) {
    this.maggie = new MaggieAxiosHttp({
      baseURL: getEnvVariable('VITE_MAGGIE_SOURCING_API'),
      ...config,
    });
  }
}
