import * as yup from 'yup';
import { IAggFunc, IAggFuncParams, RowClassParams } from 'ag-grid-community';
import { useRecoilValue } from 'recoil';
import { useAtomValue } from 'jotai';
import { merge } from 'lodash-es';
import { FundReserves, InvestmentType } from '../../../schemas/FundReserves.schema';
import { usdField } from '../../../schemas/common-schema-defs';
import { RendererType } from '../../../data-models/field.data-model';
import { portCosByIdMapState } from '../../../services/state/PortCosState';
import { createCompanyDataModel, ICompanyDataModel } from '../../../data-models/company.data-model';
import { FMT } from '../../../util/formatter-service';
import { roundsMapAtom } from '../../../services/state/AppConfigStateJ';
import { IRoundDataModel } from '../../../data-models/round.data-model';
import { ReservesRemainingRenderer } from './reservesGridColDefs';

/*
- All investment amounts except the initial one will count towards reserves used (current ones will count towards reserves invested, future ones towards reserves assigned)
*/

function remainingReservesSchema() {
  return yup
    .object({
      reservesInvested: usdField().nullable().label('Reserves Invested'),
      reservesAssigned: usdField().nullable().label('Reserves Assigned'),
      value: usdField().nullable().label('Reserves Remaining'),
    })
    .nullable()
    .gridMeta({
      formatter: 'usdRound',
      aggFunc: 'remainingReservesAggFunc',
      // only makes sense at aggregate level
      cellRenderer: ReservesRemainingRenderer,
    });
}

export const reservesAggFuncs: Record<string, IAggFunc<ReservesRowData>> = {
  remainingReservesAggFunc: (params: IAggFuncParams<ReservesRowData>) => {
    const formatter =
      (remainingReservesSchema().describe() as yup.SchemaDescription).meta?.format ?? 'string';
    // there is also a field reserves.summary.reservesAllocationRemaining, but we might
    // want to do it dynamically as hypothetical transactions are added
    const aggData = params.rowNode?.aggData;
    if (aggData) {
      return (aggData?.reservesAssigned ?? 0) - (aggData?.reservesInvested ?? 0);
    }
    let reservesAssigned = 0;
    let reservesInvested = 0;
    params.values?.forEach((value) => {
      reservesAssigned += value.reservesAssigned ?? 0;
      reservesInvested += value.reservesInvested ?? 0;
    });
    return {
      reservesInvested,
      reservesAssigned,
      value: reservesAssigned - reservesInvested,
      toString: () => {
        return FMT.format(formatter, reservesAssigned - reservesInvested);
      },
    };
  },
};

export function ReservesRowDataFields() {
  return {
    company: yup.object(),
    companyId: yup.number().nullable().label('Company').gridMeta({
      renderer: RendererType.companyId,
    }),
    companyName: yup.string().nullable().label('Company'),
    date: yup.date(),
    hypothetical: yup.boolean().nullable().gridMeta({
      hide: true,
    }),
    initialInvestment: usdField().nullable().gridMeta({
      formatter: 'usdRound',
      aggFunc: 'sum',
    }),
    investmentAmount: usdField().nullable().gridMeta({
      formatter: 'usdRound',
      aggFunc: 'sum',
    }),
    investmentId: yup.number().nullable(),
    reservesInvested: usdField().nullable().gridMeta({
      formatter: 'usdRound',
      aggFunc: 'sum',
    }),
    reservesAssigned: usdField().nullable().label('Assigned Reserves').gridMeta({
      formatter: 'usdRound',
      aggFunc: 'sum',
    }),
    reservesRemaining: remainingReservesSchema(),
    transactionGroupName: yup.string().nullable(),
    type: yup.string().nullable().label('Investment'),
    uuid: yup.string().nullable(),
  };
}
export function ReservesRowDataSchema() {
  return yup.object().shape(ReservesRowDataFields());
}
export type ReservesRowData = yup.InferType<ReturnType<typeof ReservesRowDataSchema>> & {
  company: Omit<ICompanyDataModel, 'id'>;
};

export function createReservesRowData(overrides: Partial<ReservesRowData> = {}): ReservesRowData {
  return merge({}, ReservesRowDataSchema().getDefault(), overrides);
}

export function useReservesGridData(reserves: FundReserves) {
  const portcos = useRecoilValue(portCosByIdMapState);
  const roundMap = useAtomValue(roundsMapAtom);
  return getReservesGridData(reserves, portcos, roundMap);
}

export function getReservesGridData(
  reserves: FundReserves,
  portcosMap: Map<number, ICompanyDataModel>,
  roundMap: Map<number, IRoundDataModel>
) {
  const data: ReservesRowData[] = [];

  (reserves.investments ?? []).forEach((investment) => {
    const { companyId, name: companyName } = investment;

    const company =
      portcosMap.get(companyId ?? -1) ??
      createCompanyDataModel({ id: -1, name: companyName ?? 'Unknown Company' });

    investment.transactions.forEach((transaction) => {
      const { hypothetical, uuid } = transaction;
      const reservesAssigned = hypothetical ? transaction.amount : null; // sumByProperty('amount', futureInvestment.transactions)
      const reservesInvested =
        !hypothetical && transaction.type === InvestmentType.FollowOn ? transaction.amount : null; // fundMetrics.metrics.followOnInvestmentAmount
      data.push({
        company,
        companyId: companyId ?? null,
        companyName: companyId ? null : companyName,
        date: transaction.date as Date,
        hypothetical,
        investmentAmount: transaction.amount,
        investmentId: investment.id,
        initialInvestment: transaction.type === InvestmentType.Initial ? transaction.amount : null,
        reservesAssigned,
        reservesInvested,
        reservesRemaining: {
          reservesAssigned,
          reservesInvested,
          value: (reservesAssigned ?? 0) - (reservesInvested ?? 0),
        },
        transactionGroupName: hypothetical
          ? 'Hypothetical'
          : (roundMap.get(transaction.roundId ?? -1)?.name ?? 'N/A'),
        type: transaction.type,
        uuid,
      });
    });
  });
  return data;
}

export const reservesRowClassRules = {
  currentInvestment: (params: RowClassParams) => {
    return params.data?.hypothetical === false;
  },
};
