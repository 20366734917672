import { Icon, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC, useCallback, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValueLoadable } from 'recoil';
import { Loader } from '../../components/Loader/Loader';
import { AppName } from '../../data-models/integrations-app.data-model';
import { ErrorOccurredMessage } from '../CompanyProfiles/components/Messages/HelpMessage';
import { connectToAirTable } from './Airtable/hooks/useConnectToAirtable';
import { AppBox } from './AppBox';
import { useIntegrationActions } from './hooks/useIntegrationActions';
import { integrationsState } from './state/IntegrationsState';
import { AppMap, canViewApp, ConfigurableApps } from './utils/uiAppData';

const MainContainer = styled('div')`
  width: 100%;
  padding: 1rem 2rem;
`;

const AppContainer = styled('section')`
  display: grid;
  grid-template-columns: repeat(auto-fill, 20rem);
  gap: 1rem;
`;

export const IntegrationApps: FC = () => {
  const path = useLocation().pathname;
  const activeApp = path.split('/')[3];

  return <MainContainer>{activeApp ? <Outlet /> : <AllApps />}</MainContainer>;
};

function AllApps() {
  const integrationsLoadable = useRecoilValueLoadable(integrationsState);
  const integrationsData = integrationsLoadable.valueMaybe();
  const actions = useIntegrationActions();
  const location = useLocation();
  const navigate = useNavigate();

  const navigateToConfig = useCallback(
    (appIndex: number) => {
      if (!integrationsData) return;
      const appName = integrationsData[appIndex].appName;
      navigate(`${location.pathname}/${appName}`);
    },
    [integrationsData, location.pathname, navigate]
  );

  const handleConnect = useCallback(
    (appIndex: number) => {
      if (!integrationsData) return;
      const { appName, oauthUrl } = integrationsData[appIndex];

      if (appName === AppName.AirTable) {
        connectToAirTable(oauthUrl);
      } else {
        window.location.assign(`${oauthUrl}`);
      }
    },
    [integrationsData]
  );

  const handleDisconnect = useCallback(
    (appIndex: number) => {
      if (!integrationsData) return;

      actions.disconnect(integrationsData[appIndex].id);
    },
    [actions, integrationsData]
  );

  const apps = useMemo(() => {
    if (!integrationsData) return null;
    return integrationsData.reduce((acc, app, index) => {
      const appData = AppMap.get(app.appName);
      if (!appData) return acc;
      const isConnected = integrationsData[index].isConnected;
      if (!canViewApp(app.appName)) return acc;

      acc.push(
        <AppBox
          configurationHandler={ConfigurableApps.has(app.appName) ? () => navigateToConfig(index) : null}
          connectHandler={() => handleConnect(index)}
          description={appData.description || ''}
          disconnectHandler={() => handleDisconnect(index)}
          icon={appData.icon || <Icon />}
          isConnected={Boolean(isConnected)}
          key={appData.displayName}
          name={appData.displayName}
        />
      );
      return acc;
    }, [] as React.JSX.Element[]);
  }, [integrationsData, navigateToConfig, handleConnect, handleDisconnect]);

  if (integrationsLoadable.state === 'loading') return <Loader />;
  if (integrationsLoadable.state === 'hasError') return <ErrorOccurredMessage />;
  if (!integrationsLoadable.valueMaybe()) return null;

  return (
    <>
      <Typography variant='h4' mb='2rem'>
        Apps
      </Typography>
      <AppContainer>{apps}</AppContainer>
    </>
  );
}
