import { AxiosError } from 'axios';

export const ConflictErrorStatus = 409;
interface ErrorData {
  message: string;
}
export function getErrorMessage(error: unknown, fallbackMessage: string) {
  if (error instanceof AxiosError) {
    const message = (error.response?.data as ErrorData)?.message;
    if (message) return JSON.stringify(message);
  }
  return fallbackMessage;
}

export function getErrorStatusCode(error: unknown) {
  if (error instanceof AxiosError) {
    return error.response?.status;
  }
  return null;
}
