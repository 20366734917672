import { useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import { debounce } from 'lodash-es';
import { ControllerRenderProps } from 'react-hook-form';
import { css } from '@emotion/react';
import { Rubric } from '../../../../../schemas/Rubric.schema';
import { InlineColorStatusSx } from '../../../../../components/Form/ColorStatus/ColorStatusWithLabel';
import { colorStatusField } from '../../../../../data-fields/CommonFields';
import { FieldFactory } from '../../../../../components/Form/FieldFactory';
import { getPlainDateString } from '../../../../../services/queries/MaggieMetricsQueries';
import { Breakpoint, ContainerName } from '../../../../../constants/styles';
import { cardStyles } from '../../../../../theme/component-styles';
import { colors } from '../../../../../theme/colors';
import { useLoadingBarState } from '../../../../../components/LoadingBar/LoadingBarContext';
import { LoadingId } from '../../../../../types';
import { AddRubric } from './AddRubric';
import { useCreateOrEditRubric } from './rubricActions';
import { useRubricFormData } from './useRubricFormData';
import { RubricHistory } from './RubricHistory';

const RubricsTableStyles = css`
  display: grid;
  gap: 0.25rem;
  grid-template-columns: minmax(0, 1fr);
  @container ${ContainerName.rubricSectionContainer} (width >= ${Breakpoint.profilePanels}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const RowCardStyle = css({
  ...cardStyles,
  paddingBlock: '0.15rem',
});

export const RubricsRowStyles = css`
  ${RowCardStyle};
  display: grid;
  grid-template-columns: 1fr 3rem;

  &:hover {
    background-color: ${colors.neutral[10]}aa;
  }
`;

interface IRubricListProps {
  companyId: number;
  rubrics: Rubric[];
}
export function RubricList({ rubrics, companyId }: IRubricListProps) {
  const { state } = useLoadingBarState();
  const isLoading = state.isLoading && state.ids.includes(LoadingId.createOrEditRubric);

  return (
    <Box
      sx={{
        ...InlineColorStatusSx,
        opacity: isLoading ? 0.8 : 1,
        transition: 'all 0.3s',
      }}
    >
      <div css={RubricsTableStyles}>
        {rubrics.map((rubric) => (
          <motion.div css={RubricsRowStyles} key={rubric.id} layout transition={{ duration: 0.2 }}>
            <RubricItem rubric={rubric} />
          </motion.div>
        ))}

        <AddRubric companyId={companyId} />
      </div>
    </Box>
  );
}

export function RubricItem({ rubric }: { rubric: Rubric }) {
  const { getConfigLabel, selectedDate } = useRubricFormData();
  const [currentValue, setCurrentValue] = useState(rubric.value);
  const editOrCreateRubric = useCreateOrEditRubric();

  const formProps = {
    onChange: setCurrentValue,
    value: currentValue,
  } as ControllerRenderProps;

  const updateRubric = useMemo(
    () =>
      debounce(async () => {
        const payload = { ...rubric, value: currentValue, date: getPlainDateString(selectedDate) };
        editOrCreateRubric(payload);
      }, 300),
    [currentValue, editOrCreateRubric, rubric, selectedDate]
  );

  const { value } = rubric;
  useEffect(() => {
    if (!currentValue || currentValue === value) return;
    updateRubric();
  }, [currentValue, updateRubric, value]);

  const rubricField = useMemo(() => {
    return {
      ...colorStatusField({
        key: String(rubric.id),
        label: getConfigLabel(rubric.rubricConfigId) ?? 'Unknown',
        inlineLabel: true,
      }),
    };
  }, [getConfigLabel, rubric.id, rubric.rubricConfigId]);
  return (
    <>
      <FieldFactory formField={rubricField} formProps={formProps} />
      <RubricHistory rubric={rubric} />
    </>
  );
}
