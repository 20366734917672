import { ColDef, ColDefField } from 'ag-grid-community';
import { RendererType } from '../../../data-models/field.data-model';
import { ISelectMeta } from '../../../data-models/field3.data-model';
import { IDisplayField } from '../../../view-models/display-field.view-model';
import { IGridField } from '../../../view-models/grid.view-model';
import { fieldToParser } from '../../../util/schema-utils';

export type IAgCellEditorMeta = Pick<
  ColDef,
  | 'cellEditor'
  | 'cellEditorParams'
  | 'valueGetter'
  | 'valueSetter'
  | 'valueFormatter'
  | 'cellEditorPopup'
  | 'valueParser'
> & {
  field?: ColDefField<unknown>;
};

export function getAgColDefFromGridField<T>(field: IGridField<unknown>): ColDef<T> {
  const agEditor = field.editable ? getAgEditor(field) : {};

  return {
    colId: field.key,
    field: field.key as ColDefField<unknown>,
    editable: field.editable,
    headerName: field.label ?? field.key,
    valueParser: fieldToParser(field),
    ...agEditor,
  };
}

export function getAgEditor(field: IDisplayField<unknown>): IAgCellEditorMeta {
  const formatter = field.formatter;
  switch (field.renderer) {
    case RendererType.singleSelect: {
      const keyValuesMap = new Map<number, string>(
        (field.rendererMeta as ISelectMeta<number>).values.map((entry) => [
          entry.value as number,
          entry.displayName ?? (String(entry.value) as string),
        ])
      );

      return {
        field: field.key as ColDefField<unknown>,
        cellEditor: 'agRichSelectCellEditor',
        cellEditorPopup: true,
        cellEditorParams: {
          values: Array.from(keyValuesMap.keys()),
          formatValue: (value: number) => keyValuesMap.get(value),
          allowTyping: true,
          filterList: true,
          searchType: 'match',
          highlightMatch: true,
          valueListMaxHeight: 200,
          valuePlaceholder: 'Select an option',
        },
        valueFormatter: (params) => keyValuesMap.get(Number(params.value)) ?? '',
      };
    }

    case RendererType.number: {
      return {
        field: field.key as ColDefField<unknown>,
        cellEditor: 'agNumberCellEditor',
        cellEditorParams: {
          precision: formatter === 'integer' ? 0 : 2,
        },
      };
    }

    default: {
      return {
        field: field.key as ColDefField<unknown>,
        cellEditor: 'agTextCellEditor',
      };
    }
  }
}
