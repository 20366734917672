import { ICompanyDataModel } from '../../data-models/company.data-model';
import { IFundMetricsResponseDataModel } from '../../data-models/fund-metrics.data-model';
import {
  ICompanyMetricsDataModel,
  IMetricsDataModel,
  IMetricsResponseDataModel,
} from '../../data-models/metrics.data-model';
import { GARaw } from '../../types';
import { IInvestmentDataModel } from '../../data-models/investment.data-model';
import { MaggieHttp } from '../MaggieHttp';
import { Person } from '../../schemas/Person.schema';
import { SecurityReport } from '../../schemas/SecurityReport.schema';
import { FundContributionsResponse } from '../../schemas/FundContributions.schema';
import { dateToPlainDateString } from '../../util/date-utilts';

export const ROUND_TRACKER = 'round-tracker';

export async function getCompanyMetrics(companyId?: number | null): Promise<ICompanyMetricsDataModel> {
  const res = await MaggieHttp.maggie.get<IMetricsResponseDataModel>(`/metrics`, {
    params: {
      companyIds: [companyId],
    },
  });
  const metrics = { ...getMetricsWithCompany(res.data.metrics[0], res.data) };
  return metrics ? { ...metrics } : ({} as ICompanyMetricsDataModel);
}

export async function fetchCompanyMetrics(companyId: number): Promise<IMetricsResponseDataModel> {
  const res = await MaggieHttp.maggie.get<IMetricsResponseDataModel>(`/metrics`, {
    params: {
      companyIds: [companyId],
    },
  });
  return res.data;
}

export async function fetchRawGrowthAnalysis(companyId: string | number) {
  const { data } = await MaggieHttp.maggie.get<GARaw>(`/growth-analysis/metrics/${companyId}`);

  return data;
}

export async function fetchRoundTracker(asOfDate?: string): Promise<IInvestmentDataModel[]> {
  const response = await MaggieHttp.maggie.get<IInvestmentDataModel[]>('/metrics/roundtracker', {
    params: { asOfDate },
  });

  return response.data;
}

export function getPlainDateString(dateArg?: Date | null): string {
  const date = !dateArg ? new Date() : dateArg;
  return dateToPlainDateString(date);
}
export function getMetricsWithCompany(metrics: IMetricsDataModel, data: IMetricsResponseDataModel) {
  const company = data.included?.find((comp) => comp.id === metrics.companyId);
  return { ...metrics, company: company as ICompanyDataModel, companyId: company!.id! };
}

export async function fetchMetrics(asOfDate: string = getPlainDateString()) {
  const { data } = await MaggieHttp.maggie.get<IMetricsResponseDataModel>(`/metrics`, {
    params: { asOfDate },
  });

  return data;
}
export async function getFundMetrics(asOfDate: string = getPlainDateString(), fundIds?: number[]) {
  const { data } = await MaggieHttp.maggie.get<IFundMetricsResponseDataModel>(`metrics/fundmetrics`, {
    params: { asOfDate, fundIds },
  });

  return data;
}

export async function getFundContributions(asOfDate: string = getPlainDateString(), fundIds?: number[]) {
  const { data } = await MaggieHttp.maggie.get<FundContributionsResponse[]>(
    `metrics/fundmetrics/contributions`,
    {
      params: { asOfDate, fundIds },
    }
  );
  return data;
}

export async function getPeople() {
  const { data } = await MaggieHttp.maggie.get<Person[]>('/metrics/people-reporting');
  return data;
}

interface IGetSecurityReportParams {
  companyIds?: number[];
  date?: string;
}
export async function getSecurityReport(params: IGetSecurityReportParams = {}): Promise<SecurityReport[]> {
  const { data } = await MaggieHttp.maggie.get<SecurityReport[]>('/metrics/securityReport', {
    params,
  });

  return data;
}
