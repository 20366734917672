import {
  IAirTableBasesResponseDataModel,
  IAirTableTablesResponseDataModel,
} from '../../data-models/airtable-base.data-model';
import {
  IAirTableEntityDataModel,
  IForesightFieldDataModel,
} from '../../data-models/airtable-entity.data-model';
import {
  IAirtableSyncDataModel,
  IAirtableSyncPayloadDataModel,
  IEstablishedSyncDataModel,
  ISyncColumnsDataModel,
} from '../../data-models/airtable-sync.data-model';
import { IIntegrationsAppDataModel } from '../../data-models/integrations-app.data-model';
import { MaggieHttp } from '../MaggieHttp';

export async function fetchIntegrations() {
  const { data } = await MaggieHttp.maggie.get<IIntegrationsAppDataModel[]>(`/integrations`, {
    params: {
      callback: window.location.href,
    },
  });
  return data;
}

export async function disconnectIntegration(integrationId: number) {
  const { data } = await MaggieHttp.maggie.delete(`/integrations/${integrationId}`);

  return data;
}

export async function reconnectAirtable(callback?: string) {
  return await MaggieHttp.maggie.get<IIntegrationsAppDataModel>(`/airtable/oauth/reconnect`, {
    params: {
      callback,
    },
  });
}

export async function fetchConnectedBases() {
  const { data } = await MaggieHttp.maggie.get<IAirTableBasesResponseDataModel>(`/airtable/base`);
  return data;
}

export async function fetchTablesForBase(baseId: string) {
  const { data } = await MaggieHttp.maggie.get<IAirTableTablesResponseDataModel>(`/airtable/base/${baseId}`);
  return data;
}

export async function fetchAirtableEntities() {
  const { data } = await MaggieHttp.maggie.get<IAirTableEntityDataModel[]>(`/airtable/sync/entities`);
  return data;
}

export async function createAirtableSync(payload: IAirtableSyncPayloadDataModel) {
  const { data } = await MaggieHttp.maggie.post<IAirtableSyncPayloadDataModel, IAirtableSyncDataModel>(
    `/airtable/sync`,
    payload
  );
  return data;
}

export async function fetchSyncColumns({
  baseId,
  tableId,
  entity,
}: {
  baseId: string;
  tableId: string;
  entity?: string;
}) {
  const { data } = await MaggieHttp.maggie.get<ISyncColumnsDataModel>(`/airtable/sync/columns`, {
    params: {
      baseId,
      tableId,
      entity,
    },
  });
  return data;
}

export async function fetchEstablishedSyncs() {
  const { data } = await MaggieHttp.maggie.get<IEstablishedSyncDataModel[]>(`/airtable/sync`);
  return data;
}

export async function deleteEstablishedSync(id: number) {
  const { data } = await MaggieHttp.maggie.delete<IEstablishedSyncDataModel>(`/airtable/sync/${id}`);
  return data;
}

export async function deleteEstablishedColumnSync(id: number) {
  const { data } = await MaggieHttp.maggie.delete<ISyncColumnsDataModel>(`/airtable/sync/columns/${id}`);
  return data;
}

export async function fetchAllFDFields() {
  const { data } = await MaggieHttp.maggie.get<IForesightFieldDataModel[]>(`/airtable/sync/columns`);
  return data;
}
