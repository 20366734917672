import { LinearProgress } from '@mui/material';
import { ColDef, GetRowIdParams, GridApi, GridOptions, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { AgTable } from '../../../../components/AgTable/AgTable';
import { GenericFallbacksWrapper } from '../../../../components/Fallback/GenericFallbacksWrapper';
import { LoadingStatus } from '../../../../types';
import { competitiveIntelligenceMetrics } from '../../hooks/useConnectCompetitiveIntelligenceMetrics';
import { currentCompaniesCI, selectedCompaniesCI } from '../../state/CompaniesState';
import { fieldsCI, groupOrderMapCI, selectedFieldsCI } from '../../state/DashboardsState';
import { groupComparator } from '../../util/groupComparator';
import { FieldDataCellRenderer } from './CellRenderers/FieldDataCellRenderer';
import { GroupInnerRenderer } from './CellRenderers/GroupInnerRenderer';
import { getColumnDefinitionsTransposed } from './Columns/getColumnDefinitionsTransposed';
import { getFieldsMap } from './Columns/getFieldsMap';
import { OverlayComponent } from './OverlayComponent/OverlayComponent';
import { getCellValue } from './RowData/getCellValue';
import { prepInitialRowDataTransposed } from './RowData/prepInitialRowDataTransposed';

import { prepRowDataTransposed } from './RowData/prepRowDataTransposed';
import { gridStyleOptions, TableStyleWrapper } from './TableStyleWrapper';

interface TransposedTableProps {
  distanceFromTopInRems?: number;
  companyProfilesView?: boolean;
}

export const TransposedTable: FC<TransposedTableProps> = ({ distanceFromTopInRems, companyProfilesView }) => {
  const selectedCompanies = useRecoilValue(selectedCompaniesCI);
  const currentCompanies = useRecoilValue(currentCompaniesCI);
  const fields = useRecoilValue(fieldsCI);
  const selectedFields = useRecoilValue(selectedFieldsCI);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [rowDataTransposed, setRowDataTransposed] = useState<Record<string, unknown>[]>([]);
  const fieldsMap = useMemo(() => (!fields ? null : getFieldsMap(fields)), [fields]);
  const { data, status, partialData } = useRecoilValue(competitiveIntelligenceMetrics);

  const groupOrderMap = useRecoilValue(groupOrderMapCI);

  const colDefsTransposed = useMemo(
    () => getColumnDefinitionsTransposed(currentCompanies),
    [currentCompanies]
  );

  useEffect(() => {
    // init rowData
    if (!currentCompanies?.length || !fields?.length) return;
    setRowDataTransposed(prepInitialRowDataTransposed(currentCompanies, fields));
  }, [fields, currentCompanies]);

  useEffect(() => {
    if (status !== LoadingStatus.success) return;
    console.debug('success');
    // still not ideal - if row data is already set from partial data, it shouldn't be overwritten
    // but if the partial data started coming in half-way through the loading process, then this is necessary
    setRowDataTransposed(prepRowDataTransposed(currentCompanies, fields, data));
  }, [data, fields, currentCompanies, status]);

  useEffect(() => {
    if (!gridApi || !partialData || status === LoadingStatus.success || status == LoadingStatus.idle) return;
    partialData.forEach((d) => {
      const row = gridApi.getRowNode(String(d.fieldId));

      if (row) {
        const value = getCellValue(d);
        // should prevent error reported in https://foresightdata.atlassian.net/browse/MAGGIE-2476
        if (gridApi.getColumnDef(String(d.companyId))) row.setDataValue(String(d.companyId), value);
      }
    });
  }, [partialData, status, gridApi, colDefsTransposed]);

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api);
  };

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      width: 200,
      minWidth: 200,
      resizable: true,
      sortable: false,
      menuTabs: ['filterMenuTab', 'generalMenuTab'],
      cellRenderer: FieldDataCellRenderer,
      cellRendererParams: { fieldsMap, loading: status === LoadingStatus.loading },
      autoHeaderHeight: false,
      keyCreator: undefined, // reset to default to avoid using UNKNOWN_GROUP_KEY
    }),
    [fieldsMap, status]
  );

  const getRowId = useCallback((params: GetRowIdParams) => params.data?.id, []);

  const isExternalFilterPresent = useCallback(
    () => selectedFields.length !== fields.length,
    [fields.length, selectedFields.length]
  );
  const doesExternalFilterPass = useCallback(
    (node: IRowNode) => {
      if (!selectedFields || !fields) return false;
      else return selectedFields.some((field) => field.id.toString() === node.data?.id);
    },
    [fields, selectedFields]
  );

  useEffect(() => {
    gridApi?.onFilterChanged();
  }, [gridApi, selectedFields]);

  useEffect(() => {
    if (companyProfilesView || !gridApi) return;

    const selectedCompaniesIds = selectedCompanies.map((company) => String(company.id));

    gridApi?.setColumnsVisible(selectedCompaniesIds, true);
    const nonSelectedCompaniesIds = currentCompanies.filter(
      (company) => !selectedCompaniesIds.includes(String(company.id))
    );
    gridApi.setColumnsVisible(
      nonSelectedCompaniesIds.map((company) => String(company.id)),
      false
    );
  }, [gridApi, currentCompanies, selectedCompanies, companyProfilesView]);

  const options: GridOptions = useMemo(
    () => ({
      ...gridStyleOptions,
      groupDefaultExpanded: 1,
      domLayout: 'normal',
      loadingOverlayComponent: OverlayComponent,
    }),
    []
  );

  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      comparator: (valueA: string, valueB: string) => {
        return groupComparator(valueA, valueB, groupOrderMap);
      },
      sort: 'asc',
      sortable: false,
      headerName: 'Group',
      width: 220,
      cellRendererParams: {
        innerRenderer: GroupInnerRenderer,
      },
    }),
    [groupOrderMap]
  );

  const key = useMemo(() => currentCompanies.map((c) => c.id).join('-'), [currentCompanies]);

  return (
    <GenericFallbacksWrapper>
      <div style={{ position: 'relative' }}>
        <TableStyleWrapper className='Transposed' distanceFromTopInRems={distanceFromTopInRems}>
          {status === LoadingStatus.loading ? (
            <LinearProgress variant='indeterminate' color='primary' />
          ) : (
            <div style={{ height: '4px' }}></div> // height of progress bar
          )}
          <AgTable
            key={key}
            columnDefs={colDefsTransposed}
            rowData={rowDataTransposed}
            defaultColDef={defaultColDef}
            gridOptions={options}
            onGridReady={onGridReady}
            getRowId={getRowId}
            isExternalFilterPresent={isExternalFilterPresent}
            doesExternalFilterPass={doesExternalFilterPass}
            suppressMovableColumns={true}
            autoGroupColumnDef={autoGroupColumnDef}
          />
        </TableStyleWrapper>
      </div>
    </GenericFallbacksWrapper>
  );
};
