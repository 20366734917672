import { Outlet, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { PageContainer } from '../../../../components/Layout/PageContainer';
import { ROUTE_TITLE_MAPPING, ROUTES } from '../../../../constants/RouteNameMapping';
import { kpisRequestsListState } from '../../../../services/state/KPI/KPIRequestsState';
import { KPIRequestsTabs } from './KPIRequestsTabs';
import KPIResponsesTabs from './KPIResponsesTabs';

export default function KPIRequests() {
  useRecoilValue(kpisRequestsListState);

  return (
    <PageContainer>
      <Typography variant='h4'>{ROUTE_TITLE_MAPPING[ROUTES.KPI_REQUESTS]}</Typography>
      <KPIRequestsTabs />
      <Outlet />
    </PageContainer>
  );
}

export function KPIResponses() {
  useRecoilValue(kpisRequestsListState);
  const isFullPageView = useIsFullPageView();

  return (
    <PageContainer>
      {!isFullPageView && (
        <>
          <Typography variant='h4'>{ROUTE_TITLE_MAPPING[ROUTES.KPI_RESPONSES]}</Typography>
          <KPIResponsesTabs />
        </>
      )}
      <Outlet />
    </PageContainer>
  );
}

function useIsFullPageView() {
  const { pathname } = useLocation();
  return (
    pathname ===
    `/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}/${ROUTES.KPI_RESPONSES_ACCEPTED}/${ROUTES.KPI_RESPONSES_ANALYSIS}`
  );
}
