import { ReactNode } from 'react';
import AirtableLogo from '../../../assets/icons/airtable.svg?react';
import CartaLogo from '../../../assets/icons/carta.svg?react';
import GoogleDriveLogo from '../../../assets/icons/google-drive.svg?react';
import DropboxLogo from '../../../assets/icons/dropbox.svg?react';
import QuickbooksLogo from '../../../assets/icons/quickbooks.svg?react';
import SalesforceLogo from '../../../assets/icons/salesforce.svg?react';
import SlackLogo from '../../../assets/icons/slack.svg?react';
import { AppName } from '../../../data-models/integrations-app.data-model';
import { PermissionKey } from '../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../services/PermissionService';

export const AppMap = new Map<AppName, { displayName: string; icon: ReactNode; description: string }>();
AppMap.set(AppName.AirTable, {
  displayName: 'Airtable',
  description: 'Airtable is an online collaboration platform for creating and sharing relational databases',
  icon: <AirtableLogo />,
});
AppMap.set(AppName.Dropbox, {
  displayName: AppName.Dropbox,
  description: "Dropbox is a place where all your team's content comes together.",
  icon: <DropboxLogo />,
});
AppMap.set(AppName.GoogleDrive, {
  displayName: AppName.GoogleDrive,
  description: 'Create and share your work online and access your documents from anywhere.',
  icon: <GoogleDriveLogo />,
});
AppMap.set(AppName.QuickBooks, {
  displayName: AppName.QuickBooks,
  description:
    'QuickBooks is cloud-based accounting software that can be accessed on multiple devices, anytime, anywhere',
  icon: <QuickbooksLogo />,
});
AppMap.set(AppName.Salesforce, {
  displayName: AppName.Salesforce,
  description: 'An intuitive CRM to better tack and manage leads, and close deals faster.',
  icon: <SalesforceLogo />,
});
AppMap.set(AppName.Carta, {
  displayName: AppName.Carta,
  description: 'A platform that helps companies and investors manage their cap tables and valuations.',
  icon: <CartaLogo />,
});

AppMap.set(AppName.Slack, {
  displayName: AppName.Slack,
  description:
    'Slack brings team communication and collaboration into one place so you can get more work done.',
  icon: <SlackLogo />,
});

export const ConfigurableApps = new Set<AppName>([AppName.AirTable]);

export function canViewApp(appName: AppName) {
  switch (appName) {
    case AppName.AirTable:
    case AppName.Carta:
    case AppName.Dropbox:
    case AppName.GoogleDrive:
    case AppName.QuickBooks:
    case AppName.Salesforce:
    case AppName.Slack:
      return PermissionService.get().hasPermission(PermissionKey.hasIntegrationsModule);
    default:
      return false;
  }
}
