import { ICompanyFinancialsDataModel } from '../../data-models/company-financials.data-model';
import { ICompanyFundingsDataModel } from '../../data-models/company-fundings.data-model';
import {
  ICompanyRealIdResponse,
  ICompanySearchResponseDataModel,
} from '../../data-models/company-search-result.data-model';
import { ICompanyDataModel, ICompanyPersonUnlinkDataModel } from '../../data-models/company.data-model';
import { INewsDataModel } from '../../data-models/news.data-model';
import { IPersonDataModel, IPersonEmailsDataModel } from '../../data-models/person.data-model';
import { ISearchResultDataModel } from '../../data-models/search-result.data-model';
import { addAllToCompanyResultCache } from '../../pages/CompanyComparison/state/CompanyComparisonUIState';
import { TGaStatus } from '../../types';
import { MaggieHttp } from '../MaggieHttp';
import { IMetricFundDataModel } from '../../data-models/metrics.data-model';
import { CompanySocialMedia } from '../../schemas/CompanySocialMedia.schema';
import {
  IBulkUpdateReportingStatusPayload,
  IBulkUpdateReportingStatusResponse,
} from '../../pages/KPI/components/KPICompanies/hooks/useSetReportingStatus';

export enum SearchType {
  Company = 'company',
  Portfolio = 'portfolio',
  // Person = 'person',
  // Note = 'note',
  Investor = 'investor',
}

export type UpdateCompanyFundPayload = {
  payload: Partial<IMetricFundDataModel>;
  companyFundId: number;
};

export function createCompany(companyPayload: Partial<ICompanyDataModel>) {
  const payload = transformCompanyCreationPayload(companyPayload);
  return MaggieHttp.maggie.post<Partial<ICompanyDataModel>, ICompanyDataModel>('/company', payload);
}

export async function getRealCompanyId(companyId: string) {
  const { data } = await MaggieHttp.maggie.get<ICompanyRealIdResponse>(`/company/${companyId}/realId`);

  return data;
}

export type UpdateCompanyProps = {
  payload: Partial<ICompanyDataModel>;
  companyId: number;
};
export async function updateCompany({ payload, companyId }: UpdateCompanyProps) {
  const { data } = await MaggieHttp.maggie.put<Partial<ICompanyDataModel>, ICompanyDataModel>(
    `/company/${companyId}`,
    payload
  );

  return data;
}

export async function fetchCompanyRaw(companyId: number) {
  const { data } = await MaggieHttp.maggie.get<ICompanyDataModel>(`company/${companyId}`);
  return data;
}

export async function fetchCompanyNews(companyId: number) {
  const { data } = await MaggieHttp.maggie.get<INewsDataModel[]>(`company/${companyId}/news`);
  return data;
}

export async function fetchCompanyFundings(companyId: number) {
  const { data } = await MaggieHttp.maggie.get<ICompanyFundingsDataModel>(`company/${companyId}/fundings`);
  return data;
}

export async function fetchCompaniesByWebsite(website: string) {
  const { data } = await MaggieHttp.maggie.get<ICompanyDataModel[]>(`/company?website=${website}`);
  return data;
}

// without `to` and `from` params, api returns all available data
export async function fetchCompanyFinancials({
  companyId,
  fromDate,
  toDate,
}: {
  companyId: number;
  fromDate?: Date;
  toDate?: Date;
}) {
  const params: Record<string, string> = {};
  if (fromDate && toDate) {
    params['from'] = fromDate.toISOString();
    params['to'] = toDate.toISOString();
  }

  const { data } = await MaggieHttp.maggie.get<ICompanyFinancialsDataModel[]>(
    `companyFinancials/${companyId}`,
    {
      params,
    }
  );

  return data;
}

let searchCompaniesController: AbortController | undefined;
export async function searchCompanies(
  searchTerm: string,
  type: SearchType = SearchType.Company,
  abortConsecutive = false,
  elasticSearch = false
): Promise<ICompanySearchResponseDataModel[]> {
  if (!searchTerm || searchTerm.length < 2) return [];

  if (searchCompaniesController && abortConsecutive) {
    searchCompaniesController.abort();
  }
  searchCompaniesController = new AbortController();

  try {
    let res;

    if ((type === SearchType.Company || type === SearchType.Portfolio) && elasticSearch) {
      res = await searchCompaniesElastic(searchTerm);
    } else {
      const { data } = await MaggieHttp.maggie.get<ISearchResultDataModel>('/search', {
        params: { text: searchTerm, types: [type] },
        signal: searchCompaniesController.signal,
      });
      res = data[type] ?? [];
    }
    searchCompaniesController = undefined;
    addAllToCompanyResultCache(res);

    return res;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function searchCompaniesElastic(searchTerm: string): Promise<ICompanySearchResponseDataModel[]> {
  if (!searchTerm) return [];
  const { data } = await MaggieHttp.maggie.get<ICompanySearchResponseDataModel[]>('elasticsearch/search', {
    params: { name: searchTerm },
  });

  return data || [];
}

export async function fetchGAStatus(companyId: number) {
  const { data: gaStatus } = await MaggieHttp.maggie.get<TGaStatus>(`/growth-analysis/status/${companyId}`);

  return gaStatus;
}

export async function updateCompanyFund({ payload, companyFundId }: UpdateCompanyFundPayload) {
  const { data } = await MaggieHttp.maggie.put<Partial<IMetricFundDataModel>, IMetricFundDataModel>(
    `/fundCompany/${companyFundId}`,
    payload
  );

  return data;
}

export async function fetchCompanyPeople(companyId: number): Promise<IPersonDataModel[]> {
  const { data } = await MaggieHttp.maggie.get<IPersonDataModel[]>(`/company/${companyId}/people`);

  return data;
}

export async function hidePerson(payload: ICompanyPersonUnlinkDataModel) {
  const { data } = await MaggieHttp.maggie.post(`/personCompanyLink/unlink`, payload);
  return data;
}

export async function unHidePerson(payload: ICompanyPersonUnlinkDataModel) {
  const { data } = await MaggieHttp.maggie.post(`/personCompanyLink/removeUnlink`, payload);
  return data;
}

export async function fetchPerson(personId: number): Promise<IPersonDataModel> {
  const { data } = await MaggieHttp.maggie.get<IPersonDataModel>(`/person/${personId}`);
  return data;
}

export async function updatePersonEmail(personId: number): Promise<{ email: string | null }> {
  const { data } = await MaggieHttp.maggie.get<{ email: string | null }>(`/person/${personId}/email`);
  return data;
}

export async function fetchPersonEmails(personId: number): Promise<IPersonEmailsDataModel> {
  const { data } = await MaggieHttp.maggie.get<IPersonEmailsDataModel>(`/person/${personId}/email`);
  return data;
}

export async function fetchAllPortCos() {
  const { data } = await MaggieHttp.maggie.get<ICompanyDataModel[]>(`company/portfolio`);
  return data;
}

export interface IMergeCompanyParams {
  id: number; // old companyId that will be removed
  intoId: number; // newer companyId that we want to keep
}

export async function mergeCompanies({ id, intoId }: IMergeCompanyParams) {
  await MaggieHttp.maggie.put(`/company/${id}/merge/${intoId}`);
}

function transformCompanyCreationPayload(payload: Partial<ICompanyDataModel>) {
  const { website, ...rest } = payload;

  return {
    website: website ? website : null,
    ...rest,
  };
}

export interface UpdateDropboxFolderPayload {
  folder: string;
}
export interface UpdateDropboxFolderResponse {
  dropboxUrl: string;
}
export async function updateDropboxFolder(
  companyId: number,
  payload: UpdateDropboxFolderPayload
): Promise<UpdateDropboxFolderResponse> {
  const { data } = await MaggieHttp.maggie.put<UpdateDropboxFolderPayload, UpdateDropboxFolderResponse>(
    `/company/${companyId}/dropbox`,
    payload
  );

  return data;
}

export async function updateGoogleDriveFolder(companyId: number, folderId: string) {
  const { data } = await MaggieHttp.maggie.put(`/google-drive`, { folderId: folderId, companyId });

  return data;
}

export async function bulkUpdateReportingStatus(
  companyIds: number[],
  reporting: boolean
): Promise<IBulkUpdateReportingStatusResponse | void> {
  const res = await MaggieHttp.maggie.put<
    IBulkUpdateReportingStatusPayload,
    IBulkUpdateReportingStatusResponse
  >(`/company/bulk-reporting`, { value: reporting, companyIds });

  return res?.data;
}

export async function getCompanySocials(companyId: number): Promise<CompanySocialMedia> {
  const { data } = await MaggieHttp.maggie.get<CompanySocialMedia>(`/company/${companyId}/social-media`);
  return data;
}
