import { MaggieHttp } from '../MaggieHttp';
import { IKPITemplate, IKPITemplateSectionDataModel } from '../../data-models/kpi-template.data-model';
import { TemplateAssignment } from '../../schemas/template-assignment-schema';
import { BulkTemplateAssignmentResponse } from '../../schemas/BulkTemplateAssignmentResponse.schema';

export async function fetchTemplatesAssignments(): Promise<TemplateAssignment[]> {
  const { data } = await MaggieHttp.maggie.get<TemplateAssignment[]>('/templateAssignment');

  return data;
}

export async function createTemplateAssignment(
  templateAssignment: Partial<TemplateAssignment>
): Promise<TemplateAssignment> {
  const { data } = await MaggieHttp.maggie.post<Partial<TemplateAssignment>, TemplateAssignment>(
    `/templateAssignment`,
    templateAssignment
  );
  return data;
}

export type BulkAssignTemplatePayload = Omit<TemplateAssignment, 'id' | 'templateId'>;
export async function bulkAssignTemplateQuery(
  payload: BulkAssignTemplatePayload[]
): Promise<BulkTemplateAssignmentResponse> {
  const { data } = await MaggieHttp.maggie.put<Partial<TemplateAssignment>[], BulkTemplateAssignmentResponse>(
    `/templateAssignment/bulk`,
    payload
  );
  return data;
}

export async function updateTemplateAssignment(
  templateAssignment: Partial<TemplateAssignment>
): Promise<TemplateAssignment> {
  const { data } = await MaggieHttp.maggie.put<Partial<TemplateAssignment>, TemplateAssignment>(
    `/templateAssignment/${templateAssignment.id}`,
    templateAssignment
  );
  return data;
}

export async function deleteTemplateAssignment(
  templateAssignmentId: number
): Promise<Partial<TemplateAssignment>> {
  const { data } = await MaggieHttp.maggie.delete<Partial<TemplateAssignment>>(
    `/templateAssignment/${templateAssignmentId}`
  );

  return data;
}

export async function fetchKPITemplates(): Promise<IKPITemplate[]> {
  const { data } = await MaggieHttp.maggie.get<IKPITemplate[]>('/template');

  return data;
}

export async function fetchKPITemplateById(templateId: number): Promise<IKPITemplate> {
  const { data } = await MaggieHttp.maggie.get<IKPITemplate>(`/template/${templateId}`);

  return data;
}

export async function createKPITemplate(template: Partial<IKPITemplate>): Promise<IKPITemplate> {
  const { data } = await MaggieHttp.maggie.post<Partial<IKPITemplate>, IKPITemplate>(`/template`, template);
  return data;
}

export async function updateKPITemplate(template: Partial<IKPITemplate>) {
  const { data } = await MaggieHttp.maggie.put<Partial<IKPITemplate>, IKPITemplate>(
    `/template/${template.id}`,
    template
  );

  return data;
}

export async function deleteKPITemplate(templateId: number) {
  const { data } = await MaggieHttp.maggie.delete<Partial<IKPITemplate>>(`/template/${templateId}`);

  return data;
}

export async function fetchAllTemplateSections() {
  const { data } = await MaggieHttp.maggie.get<IKPITemplateSectionDataModel[]>('/template/all-sections');
  return data;
}
