import { Fund } from '../../schemas/Fund.schema';
import {
  CapitalAllocationResponse,
  ProjectedFeesAndExpensesTransaction,
} from '../../schemas/FundCapitalAllocation.schema';
import { FundData } from '../../schemas/FundData.schema';
import { FundProjectedInvestmentAudit } from '../../schemas/FundProjectedInvestmentAudit.schema';
import { FundInvestment, FundReserves } from '../../schemas/FundReserves.schema';
import { MaggieHttp } from '../MaggieHttp';
import { getPlainDateString } from './MaggieMetricsQueries';

export interface IUpdateFundFields extends Partial<Fund> {
  id: number;
}

export async function updateFund(fund: IUpdateFundFields): Promise<Fund> {
  const response = await MaggieHttp.maggie.put<IUpdateFundFields, Fund>(`/fund/${fund.id}`, fund);
  return response.data;
}
export async function fetchFundData(fundId: number): Promise<FundData[]> {
  const response = await MaggieHttp.maggie.get<FundData[]>(`/fund/${fundId}/data`);
  return response.data;
}

export interface IUpdateFundDataPayload
  extends Partial<Pick<FundData, 'netAssets' | 'deemedContributions' | 'contributedSecurities'>>,
    Pick<FundData, 'id' | 'fundId' | 'date'> {}

export async function updateFundData(fundData: IUpdateFundDataPayload): Promise<FundData> {
  const response = await MaggieHttp.maggie.put<IUpdateFundDataPayload, FundData>(
    `/fund/data/${fundData.id}`,
    fundData
  );
  return response.data;
}
export interface ICreateFundDataPayload
  extends Partial<Pick<FundData, 'netAssets' | 'deemedContributions' | 'contributedSecurities'>>,
    Pick<FundData, 'fundId' | 'date'> {}
export async function createFundData(fundData: ICreateFundDataPayload): Promise<FundData> {
  const response = await MaggieHttp.maggie.post<ICreateFundDataPayload, FundData>(`/fund/data`, fundData);
  return response.data;
}

export async function fetchFundReserves(date: Date, fundId: number): Promise<FundReserves> {
  const response = await MaggieHttp.maggie.get<FundReserves>(`/fund/${fundId}/reserves`, {
    params: { asOfDate: getPlainDateString(date) },
  });
  return response.data;
}

export async function getFundProjectedInvestmentHistory(
  fundId: number
): Promise<FundProjectedInvestmentAudit[]> {
  const response = await MaggieHttp.maggie.get<FundProjectedInvestmentAudit[]>(
    `/fund/${fundId}/reserves-history`
  );
  return response.data;
}

export async function createFundInvestment(investment: FundInvestment): Promise<FundInvestment> {
  const res = await MaggieHttp.maggie.post<FundInvestment, FundInvestment>(
    '/fund-projected-investment',
    investment
  );
  return res.data;
}

export async function updateFundInvestment(investment: FundInvestment): Promise<FundInvestment> {
  const res = await MaggieHttp.maggie.put<FundInvestment, FundInvestment>(
    `/fund-projected-investment/${investment.id!}`,
    investment
  );
  return res.data;
}

export async function deleteFundInvestment(investmentId: number) {
  await MaggieHttp.maggie.delete(`/fund-projected-investment/${investmentId}`);
}

interface IFetchCapitalAllocationParams {
  id: number;
  asOfDate: string;
}
export async function fetchCapitalAllocation({ id, asOfDate }: IFetchCapitalAllocationParams) {
  const res = await MaggieHttp.maggie.get<CapitalAllocationResponse>(`/fund/${id}/capital-allocation`, {
    params: { asOfDate },
  });
  return res?.data;
}

export async function createProjectedFeesAndExpensesTransaction(
  transaction: Partial<ProjectedFeesAndExpensesTransaction>
) {
  const res = await MaggieHttp.maggie.post<
    Partial<ProjectedFeesAndExpensesTransaction>,
    ProjectedFeesAndExpensesTransaction
  >('/fund-projected-fees-and-expenses/', transaction);
  return res.data;
}

export async function updateProjectedFeesAndExpensesTransaction(
  transaction: Partial<ProjectedFeesAndExpensesTransaction>
) {
  if (!transaction.id) throw new Error('Missing transaction id');
  const res = await MaggieHttp.maggie.put<
    Partial<ProjectedFeesAndExpensesTransaction>,
    ProjectedFeesAndExpensesTransaction
  >(`/fund-projected-fees-and-expenses/${transaction.id!}`, transaction);
  return res.data;
}

export async function deleteProjectedFeesAndExpensesTransaction(transactionId: number) {
  await MaggieHttp.maggie.delete(`/fund-projected-fees-and-expenses/${transactionId}`);
}
