import { useRecoilCallback } from 'recoil';
import { useToastMessageState } from '../../components/ToastMessage/ToastMessageProvider';
import { IMergeCompanyParams, mergeCompanies } from '../queries/MaggieCompanyQueries';
import { getProviderFields } from '../queries/MappingsQueries';
import { getErrorMessage } from '../queryHelpers';
import { fieldsMappingByCompanyState } from '../state/CompanyProvidersState';
import { companyState } from '../state/CompanyState';

export function useMergeCompanies() {
  const { pushErrorToast } = useToastMessageState();

  const handleMergeCompanies = useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async ({ id, intoId }: IMergeCompanyParams) => {
        try {
          await mergeCompanies({ id, intoId });
          // refresh provider data for merged company
          const providerData = await getProviderFields<unknown>(intoId);

          const updatedSnapshot = snapshot.map((mutable) => {
            mutable.set(fieldsMappingByCompanyState(intoId), providerData);
            mutable.set(companyState(id), null); // this company will be deleted and merged into the other one
          });
          gotoSnapshot(updatedSnapshot);
        } catch (err) {
          const message = getErrorMessage(err, 'Error merging companies');
          pushErrorToast({ message });
        }
      },
    [pushErrorToast]
  );

  return handleMergeCompanies;
}
