import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { Button, ButtonProps, Typography, styled } from '@mui/material';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { useFundSelector } from '../../../../../../pages/PortfolioOverview/hooks/useFundSelector';
import {
  selectedFundsAsOptionsPFState,
  fundOptionsState,
} from '../../../../../../pages/PortfolioOverview/state/FundFilterState';
import { SelectItem } from '../../../../../../types';
import { IPopupMenuProps, PopupMenu } from '../../../../../PopupMenu/PopupMenu';
import { ContainerName, Breakpoint } from '../../../../../../constants/styles';

const StyledLabel = styled(Typography)`
  @container ${ContainerName.headerContainer} (max-width: ${Breakpoint.small}) {
    font-size: 0.825rem;
  }
`;

interface IFundFilterPF2Props {
  ActionComponent?: IPopupMenuProps['ActionComponent'];
}
export function FundFilterPF2({ ActionComponent }: IFundFilterPF2Props) {
  const { onChangeSelection, onSelectAll } = useFundSelector();
  const fundOptions = useRecoilValue(fundOptionsState);

  const selected = useRecoilValue(selectedFundsAsOptionsPFState);
  const label = <StyledLabel variant='body2'>{`Fund ${selected.length}/${fundOptions.length}`}</StyledLabel>;

  const _onChange = useCallback(
    (value: SelectItem[]) => {
      if (value.length === fundOptions.length) {
        onSelectAll();
        return;
      }
      onChangeSelection(value);
    },
    [fundOptions.length, onChangeSelection, onSelectAll]
  );

  return (
    <PopupMenu
      items={fundOptions}
      groupable
      searchable
      label={label}
      onChange={_onChange}
      selectedItems={selected}
      ActionComponent={ActionComponent ?? FundSelectorButton}
    />
  );
}

function FundSelectorButton({ children, ...rest }: ButtonProps) {
  return (
    <Button
      endIcon={<FilterListOutlinedIcon />}
      size='large'
      title='Filter by fund'
      variant='outlined'
      sx={{ height: '2.5rem', mt: '0.15rem' }}
      {...rest}
    >
      {children}
    </Button>
  );
}
