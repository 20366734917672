import { css } from '@emotion/react';
import { Button, Chip, Paper, Typography, useTheme } from '@mui/material';
import { ReactNode } from 'react';

const cardCss = css`
  padding: 1rem;
  width: 20rem;
  height: 12rem;
  display: grid;
  align-items: start;
  align-content: space-between;
  background: radial-gradient(
    100% 204.54% at 0% 0%,
    rgba(249, 250, 250, 0.8) 0%,
    rgba(255, 255, 255, 0.32) 100%
  );
  border: 2px solid #ffffff;
  box-shadow:
    0 -4px 40px rgba(255, 255, 255, 0.06),
    0 4px 24px rgba(16, 37, 62, 0.04),
    0 24px 48px rgba(75, 82, 93, 0.02);
`;

const cardTitleCss = css`
  display: grid;
  align-items: center;
  gap: 0.5rem;
  grid-template-columns: auto 1fr auto;
  min-height: 0;
  max-height: fit-content;
`;

const actionButtonsCss = css`
  display: flex;
  justify-content: space-between;
`;

interface IAppBoxProps {
  configurationHandler: (() => void) | null;
  connectHandler: () => void;
  description: string;
  disconnectHandler: () => void;
  icon: ReactNode;
  isConnected: boolean;
  name: string;
}

export function AppBox({
  configurationHandler,
  connectHandler,
  description,
  disconnectHandler,
  icon,
  isConnected,
  name,
}: IAppBoxProps) {
  const { colors } = useTheme();

  let actionButtons;
  if (!isConnected) {
    actionButtons = [
      <Button
        color='secondary'
        key={'connect'}
        onClick={connectHandler}
        style={{ alignSelf: 'end', justifySelf: 'start' }}
        variant={'contained'}
      >
        {'Connect'}
      </Button>,
    ];
  } else {
    actionButtons = [
      configurationHandler ? (
        <Button
          color='secondary'
          key={'manage'}
          onClick={configurationHandler}
          style={{ alignSelf: 'end', justifySelf: 'start' }}
          variant={'outlined'}
        >
          {'Manage Configuration'}
        </Button>
      ) : (
        <div />
      ),
      <Button
        color='error'
        key={'disconnect'}
        onClick={disconnectHandler}
        style={{ alignSelf: 'end', justifySelf: 'start' }}
        variant={'contained'}
      >
        {'Disconnect'}
      </Button>,
    ];
  }

  return (
    <Paper css={cardCss}>
      <div css={cardTitleCss}>
        {icon}
        <Typography variant='h6'>{name}</Typography>
        {isConnected && <Chip label='Connected' variant='outlined' color='success' size='medium' />}
      </div>
      <Typography variant='body2' color={colors.neutral[60]} style={{ alignSelf: 'start' }}>
        {description}
      </Typography>
      <div css={actionButtonsCss}>{actionButtons}</div>
    </Paper>
  );
}
