import { ICellRendererParams } from 'ag-grid-community';
import { StatusRenderer } from '../../../../../../components/AgTable/cell-renderers/StatusRenderer';
import { isGroupColumn } from '../../../../../../components/AgTable/cell-renderers/GenericIdRenderer';
import { IKpiCompanyData } from '../../AllCompaniesData';

export function AssignmentStatusRenderer(params: ICellRendererParams<IKpiCompanyData, boolean>) {
  const { value, node } = params;
  const assigned = value === true || node.key === 'true';

  let formattedValue = '';
  if (assigned) formattedValue = 'Assigned';
  else if (value === false) formattedValue = 'Unassigned';

  if (value === undefined || value === null || (node.group && !isGroupColumn(params))) {
    return null;
  }

  return <StatusRenderer status={assigned ? 'success' : 'warning'} value={formattedValue} />;
}
