import { FC, useCallback, useMemo } from 'react';
import { GridReadyEvent, ProcessCellForExportParams } from 'ag-grid-community';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { useLocation } from 'react-router-dom';
import { genericComparator } from '../../../util/comparators';
import { AgTable } from '../../../components/AgTable/AgTable';
import { commonProcessCb } from '../../../components/AgTable/exportToExcelDefs';
import { Loader } from '../../../components/Loader/Loader';
import {
  RoundCellRenderer,
  RoundCellRendererName,
} from '../../../components/AgTable/cell-renderers/RoundCellRenderer';
import { financeTransactionsByCompanyState } from '../state/FinanceState';
import { selectedCompanyIdStateFinance } from '../../../services/state/PortCosState';
import { ErrorOccurredMessage } from '../../CompanyProfiles/components/Messages/HelpMessage';
import { defaultColDef } from '../common-grid-defs/defaultColDefs';
import { FadeInGridWrapper } from '../../../components/grid-renderers/FadeInGridWrapper';
import { useAllTransactionsColumnDefs } from './useAllTransactionsColumnDefs';

export const AllTransactionsTable: FC = () => {
  const selectedCompanyId = useRecoilValue(selectedCompanyIdStateFinance);

  const fundFilter = useLocation().state?.fundFilter;

  const onGridReady = useCallback(
    (event: GridReadyEvent) => {
      event.api?.getColumnFilterInstance('fund.name').then((fundFilterModel) => {
        if (fundFilterModel && fundFilter) {
          fundFilterModel.setModel({ values: [fundFilter] });
          event.api.onFilterChanged();
        }
      });
    },
    [fundFilter]
  );

  const transactionsLoadable = useRecoilValueLoadable(
    financeTransactionsByCompanyState(selectedCompanyId ?? -1)
  );

  const columnDefs = useAllTransactionsColumnDefs();

  const processCellCallback = useCallback((params: ProcessCellForExportParams) => {
    return commonProcessCb(params);
  }, []);

  const frameworkComponents = useMemo(
    () => ({
      [RoundCellRendererName]: RoundCellRenderer,
    }),
    []
  );

  if (transactionsLoadable.state === 'loading') return <Loader height='80%' />;
  if (transactionsLoadable.state === 'hasError') return <ErrorOccurredMessage />;

  return (
    <FadeInGridWrapper key={selectedCompanyId}>
      <div style={{ height: '70vh', paddingTop: '2rem' }}>
        <AgTable
          key={selectedCompanyId}
          columnDefs={columnDefs}
          rowData={transactionsLoadable.valueMaybe()}
          defaultColDef={{ ...defaultColDef }}
          defaultExcelExportParams={{ processCellCallback }}
          defaultCsvExportParams={{ processCellCallback }}
          autoGroupColumnDef={{ comparator: genericComparator }}
          components={frameworkComponents}
          onGridReady={onGridReady}
          grandTotalRow='bottom'
        />
      </div>
    </FadeInGridWrapper>
  );
};
