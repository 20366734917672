import { useMemo, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { getPlainDateString } from '../../../../../services/queries/MaggieMetricsQueries';
import {
  rubricConfigByIdState,
  mostRecentCompanyRubricsByConfigIdState,
} from '../../../../../services/state/RubricsState';
import { selectedMetricsDateCPState } from '../../../state/CompanyFinancialsDateState';
import { selectedCompanyIdProfile } from '../../../state/UIState';

export function useRubricFormData() {
  const companyId = useRecoilValue(selectedCompanyIdProfile);
  const rubricConfigsById = useRecoilValue(rubricConfigByIdState);
  const selectedDate = useRecoilValue(selectedMetricsDateCPState(companyId));
  const rubricsByConfigId = useRecoilValue(
    mostRecentCompanyRubricsByConfigIdState({ companyId, date: getPlainDateString(selectedDate) })
  );

  const configOptions = useMemo(() => {
    return [...rubricConfigsById.values()].reduce((acc, c) => {
      if (!rubricsByConfigId.has(c.id)) {
        acc.push(c.id);
      }
      return acc;
    }, [] as number[]);
  }, [rubricConfigsById, rubricsByConfigId]);

  const getConfigLabel = useCallback(
    (id: number) => {
      return rubricConfigsById.get(id)?.displayName ?? '';
    },
    [rubricConfigsById]
  );

  return { configOptions, getConfigLabel, selectedDate };
}
