import { Button, ButtonProps, Stack } from '@mui/material';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';
import { colors } from '../../../../../theme/colors';
import { ActionButtonStack, ActionButtonWrapper } from '../../KPIRequests/UnsentKpiRequestsGrid';
import { allCompaniesActionState, selectedKpiCompaniesStateDC } from '../AllCompaniesState';
import { selectedCompaniesDC } from '../hooks/useAllCompaniesRowSelection';
import { useAllKpiCompaniesActions } from './useAllKpiCompaniesActions';

interface IBulkAssignButtonsProps {
  deselectAll: () => void;
}

export function AllCompaniesBulkActions({ deselectAll }: IBulkAssignButtonsProps) {
  const setSelectedCompanies = useSetRecoilState(selectedKpiCompaniesStateDC);
  const setSelectedAction = useSetRecoilState(allCompaniesActionState);
  const { handleSelectAction } = useAllKpiCompaniesActions();
  const selected = useAtomValue(selectedCompaniesDC);

  const handleAssign = useCallback(() => {
    setSelectedCompanies(selected);
    setSelectedAction('assign');
    deselectAll();
  }, [setSelectedCompanies, selected, setSelectedAction, deselectAll]);

  const handleStopReporting = useCallback(() => {
    handleSelectAction(selected, 'confirmStopReporting');
    deselectAll();
  }, [deselectAll, handleSelectAction, selected]);

  const onCancel = useCallback(() => {
    deselectAll();
  }, [deselectAll]);

  if (!selected.length) return null;

  return (
    <Stack display={'grid'} gridTemplateColumns={'auto auto'} justifyContent={'end'} gap='1rem'>
      <ActionButtonWrapper>
        <ActionButtonStack direction={'row'}>
          <ActionButton onClick={handleStopReporting}>Stop Reporting</ActionButton>
          <Button onClick={handleAssign} color={'secondary'} variant='contained'>
            Assign
          </Button>
          <Button onClick={onCancel} color={'secondary'} variant='outlined'>
            Cancel
          </Button>
        </ActionButtonStack>
      </ActionButtonWrapper>{' '}
    </Stack>
  );
}

function ActionButton(props: ButtonProps) {
  const { children, ...rest } = props;
  return (
    <Button
      variant='contained'
      color='secondary'
      sx={{ width: '10rem', borderColor: colors.primary[0] }}
      {...rest}
    >
      {children}
    </Button>
  );
}
