import { FC, useCallback, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Alert, Collapse, IconButton, Stack, styled, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { cloneDeep } from 'lodash-es';
import { ControllerRenderProps } from 'react-hook-form';
import { FormField, FormFieldsContainer, Label } from '../../../components/Form/FormComponents';
import { createCompanyDataModel, ICompanyDataModel } from '../../../data-models/company.data-model';
import { useGetCompanyData } from '../../CompanyProfiles/hooks/useGetCompanyData';
import { MUIAvatar } from '../../../components/Avatar/MUIAvatar';
import { CompanySearchResponseWithRealId } from '../../../schemas/CompanySearchResponse.schema';
import { createFormField } from '../../../view-models/form.view-model';
import { RendererType } from '../../../data-models/field.data-model';
import { SearchType } from '../../../services/queries/MaggieCompanyQueries';
import { ICompanySearchMeta } from '../../../data-models/field3.data-model';
import { FieldFactory } from '../../../components/Form/FieldFactory';
import { companyDataModelToSearchResultDataModel } from '../../../util/companyUtils';
import { useFocusElement } from '../../../hooks/useFocusElement';
import { useCreateOrUpdateCompanyHandler } from './CompanyFormUtils';
import { CompanyFormStep } from './CompanyFormStep';
import { StepperFormButtons } from './StepperFormButtons';
import {
  companyDataState,
  currentStepState,
  investmentDataState,
  selectedCompanyIdState,
} from './TransactionFormUIState';

const defaultValues = createCompanyDataModel({ id: undefined });

export function CompanySelectForm() {
  const [selectedCompanyId, setSelectedCompanyId] = useRecoilState(selectedCompanyIdState);
  const setSelectedStep = useSetRecoilState(currentStepState);
  const setInvestmentData = useSetRecoilState(investmentDataState);
  const onSubmit = useCreateOrUpdateCompanyHandler();
  const getCompany = useGetCompanyData();

  const selectedCompany = useRecoilValue(companyDataState);

  const [showForm, setShowForm] = useState(false);

  const [initialValues, setInitialValues] = useState<Partial<ICompanyDataModel>>(defaultValues);
  const [error, setError] = useState<string | null>(null);

  const onCompanySelect = useCallback(
    async (selectedCompany: CompanySearchResponseWithRealId | null) => {
      setError(null);
      if (!selectedCompany) {
        setSelectedCompanyId(null);
        return;
      }
      try {
        const [company] = await getCompany([Number(selectedCompany.id)]);
        if (company.companyType === 'portfolio') {
          setSelectedCompanyId(Number(selectedCompany.id));
          setSelectedStep(1);
          return;
        } else {
          setShowForm(true);
          setInitialValues(cloneDeep(company));
        }
      } catch (err) {
        setError('Error fetching company data. Please try again.');
        setSelectedCompanyId(null);
      }
    },
    [getCompany, setSelectedCompanyId, setSelectedStep]
  );

  const handleCancel = () => {
    setInitialValues(defaultValues);
    setShowForm(false);
  };

  const onCompanyCreateClick = (name: string) => {
    setInitialValues({ ...defaultValues, name });
    setShowForm(true);
  };

  const _onSubmit = useCallback(
    async (data: Partial<ICompanyDataModel>): Promise<void | ICompanyDataModel> => {
      const company = await onSubmit(data);
      if (company) {
        setSelectedCompanyId(company.id!);
        return company;
      }
    },
    [onSubmit, setSelectedCompanyId]
  );

  const handleChangeSelectedCompany = useCallback(() => {
    setSelectedCompanyId(null);
    setInitialValues(defaultValues);
    setInvestmentData(null);
  }, [setInvestmentData, setSelectedCompanyId]);

  const handleEditSelectedCompany = useCallback(() => {
    if (!selectedCompany) return;
    setInitialValues(selectedCompany);
    setShowForm(true);
  }, [selectedCompany]);

  const companyExists = initialValues.id !== undefined;
  const companySearchProps = useMemo(() => {
    return {
      formProps: {
        onChange: onCompanySelect,
        value: selectedCompany ? companyDataModelToSearchResultDataModel(selectedCompany) : null,
      } as unknown as ControllerRenderProps,
      formField: createFormField<ICompanySearchMeta>({
        key: 'company',
        label: 'Company',
        renderer: RendererType.companySearch,
        rendererMeta: {
          createOnSelection: true,
          multiSelect: false,
          showAdd: true,
          searchType: SearchType.Company,
          onAdd: (name: unknown) => onCompanyCreateClick(name as string),
        },
      }),
    };
  }, [onCompanySelect, selectedCompany]);

  useFocusElement('company-field', 'input');

  return showForm ? (
    <CompanyFormStep
      defaultValues={initialValues}
      onSubmit={_onSubmit}
      title={companyExists ? 'Edit Company Details' : 'Create New Company'}
      onCancel={handleCancel}
    />
  ) : (
    <>
      <FormFieldsContainer style={{ padding: '0 0 1rem', alignSelf: 'start', gap: '1rem' }}>
        <Typography variant='body1'>Select a Company</Typography>
        <FormField>
          <Label required>Company</Label>
          {selectedCompany ? (
            <SelectedCompanyInfo
              company={selectedCompany}
              onClear={handleChangeSelectedCompany}
              onEdit={handleEditSelectedCompany}
            />
          ) : (
            <div id='company-field'>
              <FieldFactory {...companySearchProps} />
              <Collapse in={Boolean(error)}>
                <Alert severity='error'>{error}</Alert>
              </Collapse>
            </div>
          )}
        </FormField>
      </FormFieldsContainer>

      <StepperFormButtons
        stepIsValid={() => Promise.resolve(selectedCompanyId != null)}
        nextButtonLabel={'Next'}
      />
    </>
  );
}

const StyledCard = styled('div')`
  border-radius: 4px;
  box-shadow: 0px 4px 10px 0px rgba(44, 46, 49, 0.1);
  margin-inline: 0.1rem;
  padding-inline: 0.5rem;
  height: 2.5rem;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-items: center;
  &:hover {
    box-shadow: 0px 4px 10px 0px rgba(44, 46, 49, 0.15);
  }
`;

interface ISelectedCompanyInfoProps {
  company: ICompanyDataModel;
  onClear?: () => void;
  onEdit?: () => void;
}
export const SelectedCompanyInfo: FC<ISelectedCompanyInfoProps> = ({ company, onClear, onEdit }) => {
  return (
    <StyledCard>
      <Typography variant='body2' display={'flex'} alignItems={'center'} gap='1rem' component='div'>
        <MUIAvatar src={company.logoUrl} nameParts={company.name.split(' ')} size='medium' />
        {company.name}
      </Typography>
      <Stack direction='row' gap='0.5rem'>
        {onEdit && (
          <IconButton size='small' color='secondary' onClick={onEdit} title='edit'>
            <EditOutlinedIcon fontSize='small' />
          </IconButton>
        )}
        {onClear && (
          <IconButton size='small' color='secondary' onClick={onClear} title='remove'>
            <CloseOutlinedIcon fontSize='small' />
          </IconButton>
        )}
      </Stack>
    </StyledCard>
  );
};
