import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { MappingsFormDialog } from './MappingsFormDialog';
import { MappingsGrid } from './MappingsGrid/MappingsGrid';

export function Mappings() {
  const { colors } = useTheme();

  return (
    <Box mx='2rem' my='1rem'>
      <MappingsFormDialog />
      <Stack direction={'row'} alignItems={'center'} gap='0.2rem' sx={{ color: 'text.secondary' }}>
        <InfoOutlinedIcon htmlColor={colors.neutral[50]} />
        <Typography variant='body2' color={colors.neutral[60]}>
          Here you can match vendor identification with companies and ensure all vendor information is sourced
          correctly
        </Typography>
      </Stack>
      <MappingsGrid />
    </Box>
  );
}
