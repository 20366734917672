import { debounce } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useRecoilCallback } from 'recoil';
import { fetchCompanyMetrics } from '../../../services/queries/MaggieMetricsQueries';
import {
  fetchAllInvestmentRounds,
  fetchTransactionsByCompany2,
} from '../../../services/queries/MaggieTransactionQueries';
import { companyMetricsByIdState } from '../../../services/state/CompanyMetricsByIdState';
import { FormatterService } from '../../../util/formatter-service';
import { InvestmentRoundFormatter } from '../../../util/formatters/InvestmentRoundFormatter';
import { forceFundMetricsUpdate, forceMetricsUpdate } from '../../PortfolioOverview/state/MetricsState';
import { forceRoundTrackerRefresh } from '../../PortfolioOverview/state/RoundTrackerMetricsState';
import {
  allInvestmentRoundsState,
  financeTransactionsByCompanyState,
  forceAllTransactionsByDateUpdate,
} from '../state/FinanceState';
import { useFetchAndSavePortCos } from './usePortCos';

export function useInvalidateFinanceData() {
  const fetchAndSavePortCos = useFetchAndSavePortCos();

  const invalidateInvestments = useRecoilCallback(
    ({ set }) =>
      async () => {
        try {
          const data = await fetchAllInvestmentRounds();
          set(allInvestmentRoundsState, data);
          const investmentRoundFormatter = new InvestmentRoundFormatter().init(
            new Map(data.map((round) => [round.id, round]))
          );
          FormatterService.get().setFormatterForId('investmentRound', investmentRoundFormatter.format);
        } catch (err) {
          console.error(err);
        }
      },
    []
  );

  const invalidateTransactions = useRecoilCallback(
    ({ set }) =>
      async (companyId: number) => {
        try {
          const data = await fetchTransactionsByCompany2(companyId);
          set(financeTransactionsByCompanyState(companyId), data);
          set(forceAllTransactionsByDateUpdate, (curr) => curr + 1);
        } catch (err) {
          console.error(err);
        }
      },
    []
  );

  const invalidatePortCos = useCallback(async () => {
    try {
      await fetchAndSavePortCos();
    } catch (err) {
      console.error(err);
    }
  }, [fetchAndSavePortCos]);

  const invalidateMetrics = useRecoilCallback(
    ({ set }) =>
      async (companyId: number) => {
        set(forceRoundTrackerRefresh, (curr) => curr + 1);
        set(forceFundMetricsUpdate, (curr) => curr + 1);
        set(forceMetricsUpdate, (curr) => curr + 1);
        const companyMetrics = await fetchCompanyMetrics(companyId);
        set(companyMetricsByIdState(companyId), companyMetrics);
      },
    []
  );
  const debouncedInvalidateMetrics = useMemo(
    () => debounce((companyId: number) => invalidateMetrics(companyId), 0),
    [invalidateMetrics]
  );

  const invalidateAll = useCallback(
    async (companyId: number) => {
      await Promise.all([
        invalidateInvestments(),
        invalidateTransactions(companyId),
        debouncedInvalidateMetrics(companyId),
        invalidatePortCos(),
      ]);
    },
    [invalidateInvestments, debouncedInvalidateMetrics, invalidatePortCos, invalidateTransactions]
  );

  return {
    invalidateInvestments,
    invalidateTransactions,
    invalidatePortCos,
    invalidateMetrics,
    invalidateAll,
  };
}
