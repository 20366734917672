import { ColDef, NestedFieldPaths } from 'ag-grid-community';
import { set } from 'lodash-es';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import * as yup from 'yup';
import { StringListCellRenderer } from '../../../../../components/AgTable/cell-renderers/StringListCellRenderer';
import { RendererType } from '../../../../../data-models/field.data-model';
import { IField } from '../../../../../data-models/field2.data-model';
import { IKPIRequestDataModel, KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';
import { IKPITemplateSectionDataModel } from '../../../../../data-models/kpi-template.data-model';
import { dateField } from '../../../../../schemas/common-schema-defs';
import { templateAssignmentFields } from '../../../../../schemas/template-assignment-schema';
import { fetchAllTemplateSections } from '../../../../../services/queries/KPITemplatesQueries';
import { kpisRequestsListState } from '../../../../../services/state/KPI/KPIRequestsState';
import { withOverrides } from '../../../../../util/ag-grid-utils';
import { useAsync } from '../../../../../util/hook-utils';
import { useSchemaToGrid } from '../../../../../util/schema-utils';
import { sanitizeToAlphaNumeric } from '../../../../../util/stringUtils';
import { useCommonKpiColDefs } from '../hooks/useCommonKpiColDefs';
export interface ResponseAnalysisRowData extends IKPIRequestDataModel {
  response: Record<string, string>;
}

const overrides: Record<string, ColDef<ResponseAnalysisRowData>> = {
  companyId: {
    initialSort: 'asc',
    pinned: 'left',
  },
  frequency: {
    minWidth: 130,
  },
  period: {
    minWidth: 130,
  },
  ['kpiRequestResponse.respondedAt']: {
    minWidth: 150,
  },
  respondent: {
    filter: 'agSetColumnFilter',
  },
};

function responseAnalysisRowDataSchema() {
  const { frequency, templateUuid } = templateAssignmentFields();
  return yup.object().shape({
    companyId: yup.number().label('Company').gridMeta({
      renderer: RendererType.companyId,
    }),
    frequency,
    respondent: yup.array().of(yup.string().required()).label('Respondents').gridMeta({
      formatter: 'userByEmail',
      cellRenderer: StringListCellRenderer,
    }),
    templateUuid: templateUuid.label('Template').gridMeta({
      renderer: RendererType.id,
      formatter: 'kpiTemplateName',
    }),
    kpiRequestResponse: yup
      .object()
      .nullable()
      .shape({
        respondedAt: dateField().label('Response Date'),
      }),
  });
}

export function useResponseAnalysisDefs() {
  const requests = useRecoilValue(kpisRequestsListState);
  const { data: sections } = useAsync(useCallback(() => fetchAllTemplateSections(), []));
  const textualSectionsById = useMemo(() => {
    return new Map<number, IKPITemplateSectionDataModel>(
      sections?.reduce(
        (acc, s) => {
          if (!isTextualSection(s)) return acc;
          acc.push([s.id!, s]);
          return acc;
        },
        [] as [number, IKPITemplateSectionDataModel][]
      )
    );
  }, [sections]);

  const { periodColDef } = useCommonKpiColDefs();
  const schemaToGrid = useSchemaToGrid();
  const accepted = useMemo(
    () => (requests ?? []).filter((r) => r.status === KPIRequestStatus.Accepted),
    [requests]
  );

  const rowData: ResponseAnalysisRowData[] = useMemo(() => {
    if (!sections) return [];
    return getRowData(accepted, textualSectionsById);
  }, [accepted, sections, textualSectionsById]);

  const colDefs: ColDef<ResponseAnalysisRowData>[] = useMemo(() => {
    if (!sections) return [];
    const textSectionCols = Array.from(new Set(getTextSectionColDefs(accepted, textualSectionsById)));
    const schema = responseAnalysisRowDataSchema();
    const otherDefs: ColDef[] = schemaToGrid(schema, [
      'companyId',
      'kpiRequestResponse.respondedAt',
      'respondent',
      'templateUuid',
      'frequency',
    ]);
    return withOverrides(
      [otherDefs[0], ...textSectionCols, ...otherDefs.slice(1), periodColDef],
      overrides
    ) as ColDef<ResponseAnalysisRowData>[];
  }, [accepted, periodColDef, schemaToGrid, sections, textualSectionsById]);

  return { colDefs, rowData };
}

function isTextualSection(section?: IKPITemplateSectionDataModel) {
  return (section?.meta as IField<unknown>)?.type === 'text';
}

export function getRowData(
  accepted: IKPIRequestDataModel[],
  textualSectionsById: Map<number, IKPITemplateSectionDataModel>
) {
  return accepted.reduce((res, req) => {
    if (
      !req.kpiRequestResponse ||
      !req.kpiRequestResponse.sectionData?.some((d) => isTextualSection(textualSectionsById.get(d.sectionId)))
    )
      return res;
    const row: ResponseAnalysisRowData = {
      ...req,
      response: {} as Record<string, string>,
    };
    req.kpiRequestResponse.sectionData.forEach((sectionData) => {
      const section = textualSectionsById.get(sectionData.sectionId);
      if (!section) return;
      const question = (section.meta as IField<unknown>)?.entityField;
      if (question) set(row, `response.${sanitizeToAlphaNumeric(question)}`, sectionData.value ?? '');
    });
    res.push(row);
    return res;
  }, [] as ResponseAnalysisRowData[]);
}

export function getTextSectionColDefs(
  acceptedRequests: IKPIRequestDataModel[],
  textualSectionsById: Map<number, IKPITemplateSectionDataModel>
) {
  const allAnsweredTextualSectionIds = acceptedRequests.reduce((res, req) => {
    if (!req.kpiRequestResponse) return res;
    return res.concat(
      req.kpiRequestResponse.sectionData.reduce((acc, sd) => {
        if (isTextualSection(textualSectionsById.get(sd.sectionId))) {
          return acc.concat(sd.sectionId);
        }
        return acc;
      }, [] as number[])
    );
  }, [] as number[]);

  const uniqueQuestions = new Set<string>();
  return allAnsweredTextualSectionIds.reduce((res, id) => {
    const section = textualSectionsById.get(id);
    if (!section) return res;
    // section name is saved in entityField instead of displayName
    const question = (section.meta as IField<unknown>)?.entityField;
    if (isTextualSection(section) && !uniqueQuestions.has(question)) {
      uniqueQuestions.add(question);
      const path = `response.${sanitizeToAlphaNumeric(question)}`;
      res.push({
        colId: path,
        field: path as NestedFieldPaths<ResponseAnalysisRowData>,
        filter: false,
        headerName: question,
        headerTooltip: section.meta.description ?? '',
        minWidth: 300,
      });
    }
    return res;
  }, [] as ColDef<ResponseAnalysisRowData>[]);
}
