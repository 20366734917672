import { Typography } from '@mui/material';
import { atom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GenericFallbacksWrapper } from '../../../../../components/Fallback/GenericFallbacksWrapper';
import { Loader } from '../../../../../components/Loader/Loader';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { LoadingStatus } from '../../../../../types';
import { useAsync } from '../../../../../util/hook-utils';
import {
  KPISubmissionState,
  loadRecipientViewInitialData,
  RecipientViewInitialData,
  useKPIRequestRecipientActions,
} from './hooks/useKPIRequestRecipientActions';
import { KPINoPendingRequest } from './KPINoPendingRequest';
import { KPIRecipientViewHeader3 } from './KPIRecipientViewHeader3';
import { KPIRenderedTemplate } from './KPIRenderedTemplate';
import {
  Background,
  headerWrapperCSS,
  headerWrapperInnerCSS,
  kpiContentsInnerWrapperCss,
  kpiContentsWrapperCss,
} from './KpiViewCommonStyles';

export function KPIRecipientView3() {
  const { companyId } = useParams<{ companyId: string }>();
  const { error, loading, data } = useAsync(
    useCallback(() => loadRecipientViewInitialData(Number(companyId)), [companyId])
  );

  switch (loading) {
    case LoadingStatus.loading:
      return <Loader />;

    case LoadingStatus.error:
      return <Typography>{error}</Typography>;

    case LoadingStatus.success:
    default:
      if (!data.company || data.requestsForCompany.length === 0) {
        return <KPINoPendingRequest />;
      }
      return <Layout initialData={data} />;
  }
}

type LayoutProps = {
  initialData: RecipientViewInitialData;
};

function Layout(props: LayoutProps) {
  const navigate = useNavigate();
  const { company, requestsForCompany, firstRequestResponse, firstRequestTemplate } = props.initialData;
  const componentState = useMemo(
    () =>
      atom<KPISubmissionState>({
        allRequests: requestsForCompany,
        currentRequest: requestsForCompany[0],
        currentResponse: firstRequestResponse,
        currentRequestIndex: 0,
        currentTemplate: firstRequestTemplate,
        done: false,
        isLoadingData: false,
        isSubmitting: false,
        submissionError: null,
      }),
    [firstRequestResponse, firstRequestTemplate, requestsForCompany]
  );
  const { currentRequest, currentResponse, currentTemplate, done, isSubmitting } =
    useAtomValue(componentState);
  const actions = useKPIRequestRecipientActions(componentState);

  useEffect(() => {
    if (done) {
      navigate(`/${ROUTES.KPI_THANK_YOU_SCREEN}`);
    }
  }, [done, navigate]);

  return (
    <Background>
      <div css={headerWrapperCSS}>
        <div css={headerWrapperInnerCSS}>
          <KPIRecipientViewHeader3
            kpiRequest={currentRequest}
            company={company}
            onSkipResponse={actions.skipKPIResponse}
            onSubmitResponse={actions.submitKPIResponse}
            isSubmitting={isSubmitting}
          />
        </div>
      </div>
      <div css={kpiContentsWrapperCss}>
        {!done ? (
          <div css={kpiContentsInnerWrapperCss}>
            <GenericFallbacksWrapper>
              {currentTemplate ? (
                <KPIRenderedTemplate
                  onKPIGridDataUpdated={actions.onKPIGridDataUpdated}
                  onSectionDataUpdated={actions.onSectionDataUpdated}
                  request={currentRequest}
                  response={currentResponse}
                  template={currentTemplate}
                />
              ) : (
                <Typography>Invalid Template</Typography>
              )}
            </GenericFallbacksWrapper>
          </div>
        ) : null}
      </div>
    </Background>
  );
}
