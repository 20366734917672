import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetAtom } from 'jotai';
import { useToastMessageState } from '../../../components/ToastMessage/ToastMessageProvider';
import { getPlainDateString } from '../../../services/queries/MaggieMetricsQueries';
import { getErrorMessage } from '../../../services/queryHelpers';
import { uploadGeneralLedger } from '../../../services/queries/MaggieTransactionQueries';
import { ROUTES } from '../../../constants/RouteNameMapping';
import { invalidateGeneralLedgerData } from './GeneralLedger';

export function useUploadGeneralLedger() {
  const { pushInfoToast, pushErrorToast } = useToastMessageState();
  const navigate = useNavigate();
  const setInvalidator = useSetAtom(invalidateGeneralLedgerData);

  return useCallback(
    async (file: File, date: Date) => {
      const { formData, date: dateStr } = prepareUploadPayload(file, date);
      try {
        await uploadGeneralLedger(formData, dateStr);
        setInvalidator((prev) => prev + 1);
        pushInfoToast({
          message: 'Upload successful. You can review transactions in Finance module',
          buttonLabel: 'Go to Finance',
          buttonAction: () => {
            navigate(`/${ROUTES.FINANCE2}/${ROUTES.FINANCE2_OVERVIEW}`);
          },
        });
      } catch (error) {
        console.error(error);
        pushErrorToast({ message: getErrorMessage(error, 'Failed to upload General Ledger') });
      }
    },
    [setInvalidator, pushInfoToast, navigate, pushErrorToast]
  );
}

function prepareUploadPayload(file: File, date: Date) {
  const formData = new FormData();
  formData.append('file', file);
  const dateStr = getPlainDateString(date);
  return { formData, date: dateStr };
}
