import { FormControlLabel, Checkbox } from '@mui/material';
import { FC } from 'react';
import { useAtom } from 'jotai';
import { filterOutErrorsState } from '../state/MappingsUIState';

export const ErrorFilter: FC = () => {
  const [filterErrors, setFilterErrors] = useAtom(filterOutErrorsState);

  return (
    <FormControlLabel
      sx={{
        '.MuiFormControlLabel-label': { fontSize: '0.875rem' },
        mt: '0.5rem',
        color: 'text.secondary',
        maxWidth: 'fit-content',
      }}
      value='end'
      control={<Checkbox />}
      label='Only display vendors with errors'
      labelPlacement='end'
      checked={filterErrors}
      onChange={() => setFilterErrors((curr) => !curr)}
    />
  );
};
