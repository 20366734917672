import { useMemo } from 'react';
import {
  ColDef,
  GetQuickFilterTextParams,
  IRowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { CustomCellRendererProps } from 'ag-grid-react';
import { Stack, Typography } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { useSchemaToGrid } from '../../../util/schema-utils';
import { withOverrides } from '../../../util/ag-grid-utils';
import { colors } from '../../../theme/colors';
import { FundCompanyCellRenderer } from '../../../components/grid-renderers/CompanyCellRenderer';
import { numericCellClasses } from '../../Finance2/common-grid-defs/commonColDefs';
import { CompanyType, ICompanyDataModel } from '../../../data-models/company.data-model';
import { portCosByNameMapState } from '../../../services/state/PortCosState';
import { InvestmentType, investmentTypeToLabel } from '../../../schemas/FundReserves.schema';
import { ReservesRowData, ReservesRowDataSchema } from './reservesGridData';
import { InvestmentActionsRenderer } from './InvestmentActionsRenderer';

export function useReservesColDefs() {
  const schemaToGrid = useSchemaToGrid();

  return useMemo((): ColDef<ReservesRowData>[] => {
    const schema = ReservesRowDataSchema();

    const overrides: Partial<Record<keyof ReservesRowData, ColDef<ReservesRowData>>> = {
      company: {
        field: 'company.name',
        initialRowGroup: true,
        rowGroupIndex: 0,
        initialHide: true,
        cellRendererParams: {
          displayLogoInGroupRow: true,
        },
        cellRenderer: FundCompanyCellRenderer,
        sortable: true,
        getQuickFilterText: (params: GetQuickFilterTextParams<ReservesRowData>) => {
          return `${params.data?.company?.name ?? ''} ${params.data?.company?.website ?? ''}`;
        },
        comparator: (a: string, b: string, nodeA: IRowNode, nodeB: IRowNode) => {
          const companyTypeA = nodeA?.data?.company?.companyType;
          const companyTypeB = nodeB?.data?.company?.companyType;
          if (companyTypeA == companyTypeB) {
            return a?.localeCompare(b) ?? 0;
          } else {
            return companyTypeA === CompanyType.portfolio
              ? 1
              : companyTypeB === CompanyType.portfolio
                ? -1
                : 0;
          }
        },
      },
      reservesRemaining: {
        filter: false,
        cellClass: numericCellClasses,
      },
      type: {
        valueFormatter: (params: ValueFormatterParams<ReservesRowData>) =>
          params.value ? investmentTypeToLabel[params.value as InvestmentType] : '',
        sortable: true,
        initialSort: 'desc',
        sortIndex: 1,
        minWidth: 150,
      },
      date: {
        sortable: true,
        initialSort: 'asc',
        sortIndex: 2,
        minWidth: 150,
      },
      transactionGroupName: {
        hide: true,
        rowGroup: true,
        rowGroupIndex: 1,
      },
    };

    const colDefs = schemaToGrid(schema, [
      'company',
      'type',
      'date',
      'investmentAmount',
      'initialInvestment',
      'reservesInvested',
      'reservesAssigned',
      'reservesRemaining',
      'transactionGroupName',
    ]);

    const menuColumn = {
      filter: false,
      maxWidth: 100,
      pinned: 'right' as const,
      cellRenderer: InvestmentActionsRenderer,
    };
    colDefs.push(menuColumn);
    return withOverrides(colDefs, overrides) as ColDef[];
  }, [schemaToGrid]);
}

export const defaultReservesColDefs: ColDef<ReservesRowData> = {
  flex: 1,
  minWidth: 200,
};

export function ReservesRemainingRenderer(params: CustomCellRendererProps<ReservesRowData>) {
  if (!(params.node?.group && params.node?.level < 1)) return null;

  const { value, valueFormatted } = params;
  const derivedValue = value?.value;
  if (derivedValue == null) return null;
  if (derivedValue >= 0) return valueFormatted ?? derivedValue;

  return (
    <Stack display={'grid'} height='100%' width='100%' alignContent={'center'}>
      <Typography
        variant='body2'
        color='error'
        sx={{
          width: 'fit-content',
          justifySelf: 'end',
          borderRadius: '4px',
          paddingInline: '0.2rem',
          textShadow: `0 0 6px ${colors.primary[0]}`,
        }}
      >
        {params.valueFormatted ?? derivedValue}
      </Typography>
    </Stack>
  );
}

export const defaultReservesColGroupDef: ColDef = {
  headerName: 'Company',
  filter: true,
  getQuickFilterText: () => '',
};

export function useDefaultReservesColGroupDef() {
  const portcosByName = useRecoilValue(portCosByNameMapState);
  return getDefaultReservesColGroupDef(portcosByName);
}
export function getDefaultReservesColGroupDef(
  portcosByName: Map<string, ICompanyDataModel>
): ColDef<ReservesRowData> {
  return {
    headerName: 'Company & Transaction Group',
    filter: true,
    filterValueGetter: (params: ValueGetterParams) => {
      if (!params.data) return null;
      return params.data.company?.name ?? '';
    },
    sortable: true,
    comparator: (a: string, b: string) => {
      if (a?.toLowerCase() === 'hypothetical') return -1;
      if (b?.toLowerCase() === 'hypothetical') return 1;
      if (
        (portcosByName.has(a) && portcosByName.has(b)) ||
        (!portcosByName.has(a) && !portcosByName.has(b))
      ) {
        return a?.localeCompare(b) ?? 0;
      } else {
        return portcosByName.has(a) ? 1 : -1;
      }
    },
    initialSort: 'asc',
    sortIndex: 0,
    minWidth: 250,
  };
}
