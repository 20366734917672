import { useMemo } from 'react';
import * as yup from 'yup';
import { set } from 'lodash-es';
import { ColDef, GridApi, ValueGetterParams } from 'ag-grid-community';
import { DataType, SourcingTable } from '../../../../schemas/SourcingTable.schema';
import { snakeCaseToCapitalizedString } from '../../../../util/stringUtils';
import { useSchemaToGrid } from '../../../../util/schema-utils';
import { createCompanyDataModel } from '../../../../data-models/company.data-model';
import { withOverrides } from '../../../../util/ag-grid-utils';
import { CompanyCellRenderer } from '../../../../components/grid-renderers/CompanyCellRenderer';
import { ServerSideDatasource } from './ServerSideDatasource';

// TODO: BE is not so simple - fields with same data_type have different filter types
// (see 'state' and 'founded_on' below)
const SupportedNumericFilterOptions = ['equals'];
const SupportedTextFilterOptions = ['contains'];
// TODO: currently hard-coded based on CompanyFilter schema
const FilterableColumns = new Set([
  'harmonic_id',
  'name',
  'domain',
  'city',
  'state', // checks for equality (unlike most text filters, which use 'contains' - but both are case-sensitive)
  'country',
  'num_funding_rounds',
  'total_funding', // TODO: no such field - should prob be 'total_funding_usd'
  'founded_on', // this field filter checks for equality - unlike other field filters with data_type  == 'text'
  'headcount',
  'linkedin_url',
]);

function dataTypeToYupField(dataType: DataType): yup.AnySchema {
  switch (dataType) {
    case 'bigint':
    case 'integer':
      return yup.number().customMeta({
        formatter: 'integer',
      });
    case 'double precision':
      return yup.number().customMeta({
        formatter: 'numeric2DP',
      });
    case 'text':
      return yup.string();
    default:
      return yup.string();
  }
}

export function useSourcingColDefs(table?: SourcingTable | null): ColDef[] {
  // TODO: tmp fix, 'crunchbase_url' should not be in the response, columns should match graphql schema
  const filteredTable = useMemo(() => {
    if (!table) return;
    const filteredCols = table?.columns.filter((column) => column.column_name !== 'crunchbase_url');
    return {
      ...table,
      columns: filteredCols,
    };
  }, [table]);

  const schemaToGrid = useSchemaToGrid();

  return useMemo(() => {
    if (!filteredTable) return [];
    const schema = filteredTable.columns.reduce((acc, column) => {
      set(
        acc,
        column.column_name,
        dataTypeToYupField(column.data_type).label(snakeCaseToCapitalizedString(column.column_name))
      );
      return acc;
    }, {});
    return withOverrides([...schemaToGrid(yup.object(schema)).map(setSupportedFilterTypes)], getOverrides());
  }, [schemaToGrid, filteredTable]);
}

export const defaultSourcingColDef: ColDef = {
  flex: 1,
  width: 150,
};

export function getServerSideDatasource(gridApi: GridApi, token: string): ServerSideDatasource {
  return new ServerSideDatasource(gridApi, token);
}

function getOverrides(): Record<string, ColDef> {
  return {
    name: {
      cellRenderer: CompanyCellRenderer,
      filterParams: {
        filterValueGetter: (params: ValueGetterParams) => {
          return params.data?.name;
        },
      },
      pinned: 'left',
      valueGetter: (params: ValueGetterParams) => {
        return createCompanyDataModel({
          name: params.data?.name,
          logoUrl: params.data?.logo_url,
        });
      },
      valueFormatter: (params) => {
        return params.value?.name;
      },
    },
  };
}

function setSupportedFilterTypes(colDef: ColDef): ColDef {
  const res = { ...colDef };
  switch (colDef.type) {
    case 'string': {
      if (FilterableColumns.has(colDef.field!)) {
        set(res, 'filter', 'agTextColumnFilter');
        set(res, 'filterParams.filterOptions', SupportedTextFilterOptions);
        set(res, 'filterParams.caseSensitive', false);
      } else {
        set(res, 'filter', false);
      }
      break;
    }
    case 'number': {
      if (FilterableColumns.has(colDef.field!)) {
        set(res, 'filter', 'agNumberColumnFilter');
        set(res, 'filterParams.filterOptions', SupportedNumericFilterOptions);
      } else {
        set(res, 'filter', false);
      }
      break;
    }
    default: {
      set(res, 'filter', false);
    }
  }
  set(res, 'sortable', false);
  set(res, 'filterParams.maxNumConditions', 1);
  set(res, 'filterParams.buttons', ['reset']);
  return res;
}
