import { SelectionOptions } from 'ag-grid-community';

export const multiRowSelectionDefs: SelectionOptions = {
  headerCheckbox: true,
  checkboxes: true,
  selectAll: 'filtered',
  mode: 'multiRow',
  enableMultiSelectWithClick: true,
  groupSelects: 'descendants',
  enableClickSelection: false,
};
