export function removeAllWhitespace(str: string): string {
  return str.replaceAll(/\s/g, '');
}

export function getInitials(nameParts: string[]) {
  return nameParts
    .reduce((res, namePart) => {
      return res + (namePart[0] ?? '');
    }, '')
    .toUpperCase();
}

/** Pluralizes given word
 * A very general implementation, only works for a few cases;
 * add more cases as needed
 */
export function pluralizeWord(word: string, count = 1) {
  if (count === 1) return word;

  if (word.toLowerCase() === 'is') {
    return 'are';
  }

  const lastLetter = word[word.length - 1];

  if (lastLetter === 'y') {
    return word.match(/[aeiou]y/) ? `${word}s` : `${word.slice(0, -1)}ies`;
  }

  if (lastLetter === 's' || lastLetter === 'x' || lastLetter === 'z') {
    return `${word}es`;
  }

  if (lastLetter === 'h' && (word[word.length - 2] === 'c' || word[word.length - 2] === 's')) {
    return `${word}es`;
  }

  return `${word}s`;
}

export function toCamelCase(str: string) {
  return str
    .replace(/\s(.)/g, ($1) => $1.toUpperCase())
    .replace(/\s/g, '')
    .replace(/^(.)/, ($1) => $1.toLowerCase());
}

export function camelCaseToCapitalizedString(camelCaseString: string) {
  const stringWithSpaces = camelCaseString.replace(/([A-Z])/g, ' $1');

  return stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);
}

export function sanitizeAsFileName(str: string): string {
  const specialCharacters = /[./#%&{}\\<>*? $!'":@+`|=]/g;
  return str.replace(specialCharacters, '_');
}

export function sanitizeToAlphaNumeric(str: string, replaceWith = ''): string {
  return str.replace(/[^a-zA-Z0-9]/g, replaceWith);
}

export function snakeCaseToCapitalizedString(snakeCaseString: string) {
  return snakeCaseString
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
