import FilterListIcon from '@mui/icons-material/FilterList';
import { useRecoilValue } from 'recoil';
import { motion } from 'framer-motion';
import { areAllFundsSelectedState } from '../../../state/FundFilterState';
import { colors } from '../../../../../theme/colors';

export function FundFilterIndicator() {
  const hasAllSelected = useRecoilValue(areAllFundsSelectedState);

  if (hasAllSelected) return null;

  return (
    <motion.div animate={{ opacity: [0, 1] }} transition={{ duration: 0.4 }}>
      <FilterListIcon
        fontSize='small'
        titleAccess='Fund filter applied'
        htmlColor={colors.neutral[50]}
        sx={{ marginTop: '0.25rem' }}
      />
    </motion.div>
  );
}
