import { useCallback } from 'react';
import { atom, useSetAtom } from 'jotai';
import { GridApi, SelectionChangedEvent } from 'ag-grid-community';
import { IKpiCompanyData } from '../AllCompaniesData';

export const selectedCompaniesDC = atom<IKpiCompanyData[]>([]);

export function useAllCompaniesRowSelection(gridApi: GridApi | null) {
  const setSelected = useSetAtom(selectedCompaniesDC);

  const deselectAll = useCallback(() => {
    if (gridApi) {
      gridApi?.deselectAll();
      setSelected([]);
    }
  }, [gridApi, setSelected]);

  const onSelectionChanged = useCallback(
    (e: SelectionChangedEvent) => {
      const selectedRows = e.api.getSelectedRows();
      setSelected(selectedRows);
    },
    [setSelected]
  );

  return { deselectAll, onSelectionChanged };
}
