import { Alert, AlertProps, SxProps } from '@mui/material';
import { colors } from '../../../../theme/colors';

interface IHelpMessageProps {
  message: string;
  severity?: AlertProps['severity'];
  sx?: SxProps;
}

export function HelpMessage({ message, severity }: IHelpMessageProps) {
  return <Alert severity={severity ?? 'error'}>{message}</Alert>;
}

export function ErrorOccurredMessage() {
  return <HelpMessage severity={'error'} message={'An error has occurred'} />;
}

export function NoDataMessage2() {
  return (
    <Alert
      severity='info'
      sx={{
        backgroundColor: `${colors.neutral[10]}aa`,
        color: 'text.secondary',
        '& svg': { fill: colors.neutral[60] },
        display: 'grid',
        gridTemplateColumns: 'auto max-content',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      {'No data available'}
    </Alert>
  );
}
