import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import type { ECharts } from 'echarts/core';
import { ComposeOption, init as echartsInit, use as echartsUse } from 'echarts/core';
import { BarChart as EChartsBar } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  TransformComponent,
} from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import {
  BarSeriesOption,
  DatasetComponentOption,
  GridComponentOption,
  LegendComponentOption,
  TitleComponentOption,
  TooltipComponentOption,
} from 'echarts';
import { BarDataItemOption } from 'echarts/types/src/chart/bar/BarSeries';
import { CallbackDataParams } from 'echarts/types/dist/shared';
import { ChartTitleWrapper } from '../../../../components/Charts/ChartTitleWrapper';
import { ChartWrapper } from '../../../../components/Charts/ChartWrapper/ChartWrapper';
import { IconTypes } from '../../../../components/Icon';
import { useValuationBridge } from '../../../../components/Charts/Barchart/hooks/useValuationBridge';
import { Chart, ChartSizes } from '../../../../components/Charts/chart-utils';
import { CHART_COMPONENTS } from '../../../../types';
import { useIsInViewport } from '../../../../hooks/useIsInViewport';
import { formatUSD, formatUSDShort } from '../../../../util/formatters/NumericFormatters';

type ECBarOptions = ComposeOption<
  | BarSeriesOption
  | DatasetComponentOption
  | GridComponentOption
  | LegendComponentOption
  | TitleComponentOption
  | TooltipComponentOption
>;

// Register the required components
echartsUse([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  DatasetComponent,
  GridComponent,
  TransformComponent,
  EChartsBar,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);

const axisTickTextStyle = {
  fontFamily: 'Wotfard-Regular',
  fontSize: 11,
};

const tooltipTextStyle = {
  fontFamily: 'Wotfard-Regular',
  fontSize: 12,
};

export interface ValuationBridgeBar extends BarDataItemOption {
  amount?: number;
  barLabel: string;
}

export const ValuationBridgeChart = memo(function ValuationBridgeChart() {
  const data = useValuationBridge();
  const [widthState, setWidthState] = useState<string | number>(640);
  const [isHovering, setIsHovering] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [size, setSize] = useState('1/4 Screen');
  const chartContainer = useRef<HTMLDivElement | null>(null);
  const barChart = useRef<ECharts | null>(null);
  const isInViewport = useIsInViewport(
    {
      threshold: 0.6,
    },
    chartContainer
  );

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setShowMore(false);
    setIsHovering(false);
  };

  useLayoutEffect(() => {
    const updateSize = () => {
      setWidthState(ChartSizes[size as keyof typeof ChartSizes]);
    };

    updateSize();
  }, [size]);

  useEffect(() => {
    const container = chartContainer.current;

    if (!container) {
      return;
    }

    if (!barChart.current) {
      barChart.current = echartsInit(container);
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          barChart.current!.resize();
        }
      }
    });

    resizeObserver.observe(container);

    return () => {
      if (container) {
        resizeObserver.unobserve(container);
      }
    };
  }, [chartContainer]);

  useEffect(() => {
    if (!(barChart.current && isInViewport)) {
      return;
    }

    const xData: string[] = [];
    const series1: ValuationBridgeBar[] = [];
    const series2: ValuationBridgeBar[] = [];
    const series3: ValuationBridgeBar[] = [];

    data.reduce(
      (res, item) => {
        res.xData.push(item.name.replaceAll(' ', '\n'));

        if (item.name === 'Investment Amount') {
          res.series1.push({
            amount: item.amount,
            barLabel: '',
            itemStyle: { color: 'rgba(231, 232, 255,0.5)' },
            value: item.moic,
          });
          res.series2.push({ barLabel: '', value: 0 });
          res.series3.push({
            barLabel: `Projected\nMOIC\n${item.multiplier}`,
            itemStyle: { color: item.colour },
            value: item.value,
          });
        } else {
          res.series1.push({
            amount: item.amount,
            barLabel: `${item.multiplier}x`,
            itemStyle: { color: item.colour },
            value: item.value,
          });
          res.series2.push({
            barLabel: '',
            value: item.hidden,
          });
          res.series3.push({ barLabel: '', value: 0 });
        }

        return res;
      },
      { xData, series1, series2, series3 }
    );

    const options: ECBarOptions = {
      grid: {
        containLabel: true,
        top: 20,
        bottom: 0,
        left: 0,
        right: 0,
      },
      xAxis: {
        axisLabel: {
          ...axisTickTextStyle,
        },
        data: xData,
        type: 'category',
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          ...axisTickTextStyle,
          formatter: (value: number) => {
            return formatUSDShort(value);
          },
        },
      },
      series: [
        {
          data: series2,
          emphasis: {
            itemStyle: {
              borderColor: 'transparent',
              color: 'transparent',
            },
          },
          itemStyle: {
            borderColor: 'transparent',
            color: 'transparent',
          },
          stack: 'Total',
          type: 'bar',
        },
        {
          data: series3,
          itemStyle: {
            borderRadius: [4, 4, 0, 0],
          },
          label: {
            ...tooltipTextStyle,
            formatter: (params) => {
              const item = params.data as ValuationBridgeBar;
              return item.barLabel;
            },
            position: 'top',
            show: true,
          },
          stack: 'Total',
          type: 'bar',
        },
        {
          backgroundStyle: {
            color: 'rgba(180, 180, 180, 0.2)',
          },
          data: series1,
          itemStyle: {
            borderRadius: [4, 4, 0, 0],
          },
          label: {
            ...tooltipTextStyle,
            formatter: (params) => {
              const item = params.data as ValuationBridgeBar;
              return item.barLabel;
            },
            position: 'top',
            show: true,
          },
          showBackground: false,
          stack: 'Total',
          type: 'bar',
        },
      ],
      tooltip: {
        axisPointer: {
          type: 'shadow',
        },
        formatter: function (params) {
          const bar2 = (params as CallbackDataParams[])[2];
          const bar1 = (params as CallbackDataParams[])[1];
          const data = bar2.data as ValuationBridgeBar;
          const color = bar2.name === 'Investment\nAmount' ? bar1.color : bar2.color;

          return `<b>${bar2.name}</b><br/><span style="color: ${color}">${formatUSD(
            data.amount ?? 0
          )}</span>`;
        },
        trigger: 'axis',
        confine: true,
        ...tooltipTextStyle,
      },
    };

    barChart.current.setOption(options, true);
  }, [data, isInViewport]);

  return (
    <ChartWrapper
      id={CHART_COMPONENTS.VALUATION_BRIDGE}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter}
      width={widthState}
      dataTestid={'line-chart'}
    >
      <ChartTitleWrapper
        isHovering={isHovering}
        title={'Valuation Bridge'}
        showMore={showMore}
        setShowMore={setShowMore}
        id={CHART_COMPONENTS.VALUATION_BRIDGE}
        icon={IconTypes.FINANCE}
        handleSizeChange={setSize}
        size={size}
        refProp={chartContainer}
      />
      <Chart width={'100%%'} ref={chartContainer} />
    </ChartWrapper>
  );
});
