import { css } from '@mui/material/styles';
import { ZINDEX } from '../constants/styles';
import { theme } from './index';

export const globalStyles = css`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Wotfard-Regular;
  }

  html,
  #root {
    height: 100%;
    --scrollbarBG: transparent;
    --thumbBG: #00000030;
  }

  body {
    height: 100vh;
    padding: 0;
  }
  .monospace {
    font-family: IBMPlexSans-Regular;
  }

  .text-align-right {
    text-align: right;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  button.IconButtonPrimary {
    border-radius: 4px;
    border: thin solid ${theme.colors.neutral[30]};
  }

  button.IconButtonSecondary {
    border-radius: 4px;
    background-color: ${theme.colors.secondary[10]};
    border: thin solid ${theme.colors.secondary[10]};
  }

  .LinkButtonWithIcon {
    display: flex;
    align-items: flex-start;
    gap: 0.2rem;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: transparent;
  }
  .LinkButtonWithIcon:hover {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: inherit;
  }
  .LinkButtonWithIcon:disabled {
    color: ${theme.colors.neutral[50]};
    border-bottom: 1px solid transparent;
  }

  body {
    overflow: overlay;
  }

  * {
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  }

  *::-webkit-scrollbar {
    width: 0.6rem;
    height: 0.6rem;
  }

  *::-webkit-scrollbar-button {
    display: none;
  }

  *::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }

  *:hover::-webkit-scrollbar-track {
    background: var(--scrollbarBG);
  }

  *::-webkit-scrollbar-track-piece {
    background: var(--scrollbarBG);
  }

  *::-webkit-scrollbar-thumb {
    background: var(--scrollbarBG);
  }

  *:hover::-webkit-scrollbar-thumb {
    background: var(--thumbBG);
    border: thin solid #ffffff40;
    border-radius: 8px;
  }

  *::-webkit-scrollbar-thumb:hover {
    background: var(--thumbBG);
    border: thin solid #ffffff40;
    border-radius: 8px;
  }

  .componentWithActionOnHover {
    position: relative;
  }
  .actionsContainer {
    position: absolute;
    z-index: 2;
    display: none;
  }
  .componentWithActionOnHover:hover .actionsContainer {
    display: flex;
    align-items: center;
  }

  // Disable MUI label margin for label as we use custom labelling.
  .form-control.MuiFormControl-root {
    margin-top: 0;
  }

  .form-control > .MuiFormControl-root {
    margin-top: 0;
  }

  .form-inline .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }

  .form-inline .MuiOutlinedInput-notchedOutline:hover {
    border-color: initial;
  }

  // Hide dropdown arrow
  .form-inline:not(.form-inline:hover, .form-inline:focus-within) {
    .MuiAutocomplete-endAdornment,
    svg.MuiSvgIcon-root.MuiSelect-iconOutlined {
      visibility: hidden;
    }
  }

  .form-inline {
    .MuiSelect-select {
      padding: 0;
      align-items: center;
      display: flex;
      height: 2.5rem;
    }
    .MuiSelect-select ~ fieldset {
      margin-left: -0.75rem;
    }
  }

  // Disable should look plain.
  .form-inline .MuiInputBase-root .Mui-disabled {
    color: black;
    -webkit-text-fill-color: black;
  }

  .form-inline:has(.Mui-disabled) {
    pointer-events: none;
  }

  .form-inline .Mui-disabled fieldset.MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }

  .form-inline .MuiFormControl-root,
  .form-select .MuiFormControl-root {
    margin-top: 0;
  }

  .form-inline-input.MuiTextField-root {
    margin-left: -0.75rem;
  }

  .form-inline-select .MuiFormControl-root {
    margin-left: -0.75rem;
  }

  .form-inline-multiselect .MuiFormControl-root {
    margin-left: -0.5rem;
  }

  .form-select .MuiOutlinedInput-input {
    color: currentColor;
    font-size: 0.875rem;
    padding: 0.425rem;
    padding-left: 0.75rem; // make it same as in Autocomplete
  }

  .form-select .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }

  .form-select:not(.form-select:hover) .MuiSelect-iconOutlined:not(.Mui-focused .MuiSelect-iconOutlined) {
    visibility: hidden;
  }

  .form-select:hover:not(.form-select:focus-within) fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #b6bbc4;
  }

  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #282e9a !important; //FIXME: https://foresightdata.atlassian.net/browse/MAGGIE-3994
  }

  .MuiInputBase-root {
    min-height: 2.5rem;
  }

  .MuiInputBase-root:not(.MuiInputBase-root:focus-within):not(.MuiInputBase-root.Mui-disabled):not(
      .MuiInputBase-root.Mui-error
    ):hover
    fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #99a1ad;
  }
  // Disable should look plain.
  .form-select:has(.Mui-disabled) {
    pointer-events: none;
  }

  .form-select.MuiInputBase-root.Mui-disabled .MuiSelect-select {
    color: black;
    -webkit-text-fill-color: black;
  }

  .form-select.MuiInputBase-root .Mui-disabled + fieldset.MuiOutlinedInput-notchedOutline {
    border-color: transparent;
  }

  a {
    text-decoration: none;
  }

  .form-date-picker.form-inline-input:not(.form-date-picker.form-inline-input:hover):not(
      .form-date-picker.read-write-input:focus-within
    )
    .MuiInputAdornment-root,
  .form-date-picker.form-inline-input:has(.Mui-disabled) .MuiInputAdornment-root {
    visibility: hidden;
  }

  .MuiPopper-root.MuiAutocomplete-popper {
    z-index: ${ZINDEX.MENU_INDEX};
  }
`;
