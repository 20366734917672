import { yupResolver } from '@hookform/resolvers/yup';
import { Collapse, Stack } from '@mui/material';
import { useSetAtom } from 'jotai';
import { merge } from 'lodash-es';
import { Fragment, useCallback, useMemo } from 'react';
import { FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useRecoilValue } from 'recoil';
import { ObjectSchema } from 'yup';
import { FormFieldsContainer } from '../../../components/Form/FormComponents';
import { FormFieldWithLabelFactory } from '../../../components/Form/FormFieldAndLabelFactory';
import { StickyFormButtons } from '../../../components/Form/StickyFormButtons';
import { Fund, fundFormSchema } from '../../../schemas/Fund.schema';
import { fromFundViewModel, FundViewModel, fundViewModelSchema } from '../../../schemas/FundViewModel.schema';
import { schemaToFormFields } from '../../../util/schema-utils';
import { selectedFundStateFP, showFundFormsState } from '../state/FPState';

export interface IFundFormProps {
  defaultValues?: Partial<FundViewModel>;
  onSubmit: (data: Partial<Fund>) => Promise<Fund | void>;
  onCancel: () => void;
}

export function FundWaterfallForm({ defaultValues, onCancel, onSubmit }: IFundFormProps) {
  const fund = useRecoilValue(selectedFundStateFP);

  if (!fund) return null;

  return (
    <FormFieldsContainer>
      <WaterfallSettingsForm onSubmit={onSubmit} defaultValues={defaultValues} onCancel={onCancel} />
    </FormFieldsContainer>
  );
}

function WaterfallSettingsForm({ defaultValues, onCancel, onSubmit }: IFundFormProps) {
  const setShowForm = useSetAtom(showFundFormsState);

  const methods = useForm<FundViewModel>({
    defaultValues: defaultValues as FundViewModel,
    resolver: yupResolver(fundViewModelSchema() as ObjectSchema<FundViewModel>),
    mode: 'all',
  });

  const _onSubmit = useCallback(async () => {
    const isValid = await methods.trigger();
    if (isValid) {
      const payload = prepareFundPayload(fromFundViewModel(merge({}, defaultValues, methods.getValues())));
      await onSubmit(payload);
      setShowForm(null);
      return true;
    } else {
      return false;
    }
  }, [defaultValues, methods, onSubmit, setShowForm]);

  return (
    <FormProvider {...methods}>
      <WaterfallFormFields />
      <StickyFormButtons onSubmit={_onSubmit} onCancel={onCancel} style={{ padding: '0 0 2rem' }} />
    </FormProvider>
  );
}

function WaterfallFormFields() {
  const tierToggles = useMemo(() => {
    return schemaToFormFields(fundViewModelSchema(), [
      '_viewModel.tier1',
      '_viewModel.tier2',
      '_viewModel.tier3',
    ]);
  }, []);
  const tier1Fields = useMemo(() => {
    return schemaToFormFields(fundViewModelSchema(), ['lpGpSplit', 'lpGpSplitThreshold']);
  }, []);
  const [tier2Field] = useMemo(() => {
    return schemaToFormFields(fundViewModelSchema(), ['gpCatchUpPercentage']);
  }, []);
  const [tier3Field] = useMemo(() => {
    return schemaToFormFields(fundViewModelSchema(), ['superReturnSplit']);
  }, []);

  const { control } = useFormContext<FundViewModel>();
  const [tier1, tier2, tier3] = useWatch({
    name: ['_viewModel.tier1', '_viewModel.tier2', '_viewModel.tier3'],
    control,
  });

  return (
    <>
      {tierToggles.map((field) => {
        const open =
          field.key === '_viewModel.tier1' ? !!tier1 : field.key === '_viewModel.tier2' ? !!tier2 : !!tier3;
        return (
          <Fragment key={field.key}>
            <FormFieldWithLabelFactory key={field.key} formField={field} />
            <Collapse in={open}>
              <Stack gap='0.5rem' mt='-0.75rem'>
                {field.key === '_viewModel.tier1' &&
                  tier1Fields.map((field) => {
                    return <FormFieldWithLabelFactory key={field.key} formField={field} />;
                  })}
                {field.key === '_viewModel.tier2' && (
                  <FormFieldWithLabelFactory key={field.key} formField={tier2Field} />
                )}
                {field.key === '_viewModel.tier3' && (
                  <FormFieldWithLabelFactory key={field.key} formField={tier3Field} />
                )}
              </Stack>
            </Collapse>
          </Fragment>
        );
      })}
    </>
  );
}

function prepareFundPayload(fund: Partial<Fund>): Partial<Fund> {
  const { calculationType, isProceedsPercentAdjusted } = fundFormSchema().getDefault();
  return {
    ...fund,
    calculationType,
    isProceedsPercentAdjusted,
  };
}
