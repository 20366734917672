import { css } from '@emotion/react';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CompanyLogoAndName } from '../../../../../components/grid-renderers/CompanyCellRenderer';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { IKPIRequestDataModel, KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';
import { PermissionKey } from '../../../../../services/PermissionAndRolesKeys';
import { PermissionService } from '../../../../../services/PermissionService';
import { FMT, FormatterService } from '../../../../../util/formatter-service';
import { KPIRecipientActions } from './hooks/useKPIRequestRecipientActions';

const headerContainerCSS = css`
  display: grid;
  grid-template-columns: max-content max-content 1fr max-content;
  grid-column-gap: 1rem;
`;

export interface IKPIRecipientViewHeaderProps {
  company: ICompanyDataModel;
  isModified: boolean;
  isSubmitting: boolean;
  kpiRequest: IKPIRequestDataModel;
  onRejectResponse: KPIRecipientActions['skipKPIResponse'];
  onAcceptResponse: KPIRecipientActions['submitKPIResponse'];
}

export function KPIReviewHeader(props: IKPIRecipientViewHeaderProps) {
  const { company, isModified, isSubmitting, kpiRequest, onRejectResponse, onAcceptResponse } = props;
  const { colors } = useTheme();
  const respondentFormatter = FormatterService.get().getFormatterForId('userByEmail');

  return (
    <div css={headerContainerCSS}>
      <CompanyLogoAndName
        name={company.name}
        logoUrl={company.logoUrl ?? ''}
        typographyProps={{ fontSize: '26px' }}
      />
      <Stack direction='column'>
        <Typography variant='body2' sx={{ color: colors.gray[700] }}>
          <b>From:</b> {respondentFormatter(kpiRequest?.respondent ?? [])}
        </Typography>
        <Typography variant='body2' sx={{ color: colors.gray[700] }}>
          <b>On:</b> {FMT.format('date', kpiRequest?.sentAt)}
        </Typography>
      </Stack>
      <div />
      <KPIReviewButtons
        isModified={isModified}
        isSubmitting={isSubmitting}
        onAcceptResponse={onAcceptResponse}
        onRejectResponse={onRejectResponse}
        status={kpiRequest.status as KPIRequestStatus}
      />
    </div>
  );
}

interface IKPIRecipientViewFooterProps {
  isModified: boolean;
  isSubmitting: boolean;
  onRejectResponse: KPIRecipientActions['skipKPIResponse'];
  onAcceptResponse: KPIRecipientActions['submitKPIResponse'];
  status: KPIRequestStatus;
}

export function KPIReviewButtons({
  isModified,
  isSubmitting,
  onRejectResponse,
  onAcceptResponse,
  status,
}: IKPIRecipientViewFooterProps) {
  if (
    PermissionService.get().hasPermission(PermissionKey.canWriteDataCollection) &&
    (status === KPIRequestStatus.Accepted || status === KPIRequestStatus.Rejected)
  ) {
    return <MoveBackToReview />;
  }

  return (
    <Stack direction='row' gap='1rem' height={'min-content'}>
      <LoadingButton
        loading={isSubmitting}
        onClick={onRejectResponse}
        size='medium'
        variant='outlined'
        color='error'
      >
        Reject
      </LoadingButton>
      <LoadingButton
        loading={isSubmitting}
        onClick={onAcceptResponse}
        size='medium'
        variant='contained'
        color='secondary'
      >
        {isModified ? 'Save changes & Accept' : 'Accept'}
      </LoadingButton>
    </Stack>
  );
}

function MoveBackToReview() {
  const navigate = useNavigate();

  return (
    <Button
      onClick={() => navigate(`/${ROUTES.KPI}/${ROUTES.KPI_RESPONSES}`)}
      variant='contained'
      color='secondary'
    >
      Go to KPI Responses
    </Button>
  );
}
