import { atom, selector } from 'recoil';
import { IProviderConfigDataModel } from '../../../data-models/provider-config.data-model';
import { getProviderConfig } from '../../../services/queries/MappingsQueries';

export const providersConfigState = atom<IProviderConfigDataModel<unknown>[]>({
  key: 'providersState',
  default: [],
  effects: [
    ({ setSelf, trigger }) => {
      if (trigger === 'get') {
        const config = getProviderConfig();
        setSelf(config);
      }
    },
  ],
});

export const providerConfigByNameMapState = selector<Map<string, IProviderConfigDataModel<unknown>> | null>({
  key: 'providerConfigByNameMapState',
  get: ({ get }) => {
    const providersConfig = get(providersConfigState);
    return (
      providersConfig?.reduce((res, config) => {
        return res.set(config.provider, config);
      }, new Map<string, IProviderConfigDataModel<unknown>>()) ?? null
    );
  },
});

export const lastFieldUpdateDateState = atom<string | null>({
  key: 'lastFieldUpdateDateState',
  default: null,
});
