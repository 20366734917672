import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import {
  ColDef,
  KeyCreatorParams,
  ValueFormatterFunc,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { IRoundReconDataModel } from '../../../../data-models/finance-round-recon.data-model';
import { CompanyCellRenderer } from '../../../../components/grid-renderers/CompanyCellRenderer';
import { ICompanyDataModel } from '../../../../data-models/company.data-model';
import { useGetCompanyIfSet } from '../../../CompanyProfiles/hooks/useGetCompanyData';
import { getNumericColdDef } from '../../common-grid-defs/commonColDefs';
import { RoundsByCompanyLink } from '../../common-grid-defs/RoundsByCompanyLink';
import { roundReconConfigState } from '../../state/FinanceState';
import { ITransactionReconDataModel } from '../../../../data-models/recon.data-model';
import { AgFormatterService } from '../../../../util/ag-formatter-service';
import { createField3DataModel } from '../../../../data-models/field3.data-model';
import { RendererType } from '../../../../data-models/field.data-model';
import { NumericFormattersConfig } from '../../../../util/formatters/NumericFormatters';

export const CommonReconValueColDefs = {
  ...getNumericColdDef(),
  valueFormatter: ReconValueFormatter as ValueFormatterFunc,
};
const conditionalColumns = [
  { field: 'aumniValue', headerName: 'Aumni', ...CommonReconValueColDefs },
  { field: 'cartaValue', headerName: 'Carta', ...CommonReconValueColDefs },
  { field: 'deValue', headerName: 'Diligent Equity', ...CommonReconValueColDefs },
];

export function useRoundReconColDefs() {
  const roundReconConfig = useRecoilValue(roundReconConfigState);
  const getCompanyData = useGetCompanyIfSet();

  const conditionalColDefs = useMemo<ColDef<IRoundReconDataModel>[]>(() => {
    return conditionalColumns.filter(({ field }) =>
      Boolean(roundReconConfig[field as keyof IRoundReconDataModel])
    ) as ColDef<IRoundReconDataModel>[];
  }, [roundReconConfig]);

  const colDefs = useMemo<ColDef<IRoundReconDataModel>[]>(
    () => [
      {
        headerName: 'Fields',
        field: 'field',
        pinned: 'left',
      },
      {
        headerName: 'Company',
        colId: 'company',
        valueGetter: (params: ValueGetterParams<IRoundReconDataModel>) => {
          return getCompanyData(params.data?.round?.companyId ?? -1);
        },
        keyCreator: (params: KeyCreatorParams<IRoundReconDataModel>) => {
          return params.value?.name || '';
        },
        cellRenderer: CompanyCellRenderer,
        comparator: (a: ICompanyDataModel, b: ICompanyDataModel) => {
          return a?.name?.localeCompare(b?.name || '') ?? 0;
        },
      },
      {
        headerName: 'Round',
        enableRowGroup: true,
        field: 'round.name',
      },
      {
        headerName: 'Foresight',
        field: 'foresightValue',
        ...CommonReconValueColDefs,
      } as ColDef,
    ],
    [getCompanyData]
  );

  const actionColumn = useMemo<ColDef<IRoundReconDataModel>>(
    () => ({
      headerName: '',
      cellRenderer: RoundsByCompanyLink,
      maxWidth: 70,
      flex: 0,
      suppressHeaderMenuButton: true,
      pinned: 'right',
      resizable: false,
    }),
    []
  );

  return [...colDefs, ...conditionalColDefs, actionColumn];
}

export function ReconValueFormatter(
  params: ValueFormatterParams<IRoundReconDataModel | ITransactionReconDataModel>
): string {
  const { formatting } = params.data ?? {};
  if (!formatting) {
    return params.value ?? '';
  }
  const formatter =
    formatting === RendererType.currency
      ? AgFormatterService.get().getFormatterForModel(NumericFormattersConfig.usd)
      : AgFormatterService.get().getFormatterForFieldV3(
          createField3DataModel({
            entityKey: params.colDef.field ?? '',
            format: formatting,
            dataType: 'number',
          })
        );

  return formatter(params as ValueFormatterParams);
}
