import { FC, useCallback, useMemo, useState } from 'react';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import { Alert, Button, Collapse, IconButton, TextField, Typography, useTheme } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { styled } from '@mui/material/styles';
import { IUpdateProviderPayload, useUpdateMappings } from '../../../services/hooks/useMappingsActions';
import { MappingsEventType } from '../MappingsGrid/mappingsGridEvents';
import { ConfirmDeletionDialog } from '../../../components/Dialog/ConfirmDialog/ConfirmDeletionDialog';
import {
  FieldAndButtonContainer,
  FieldContainer,
  FormContainer,
  Label,
  LabelAndButtonContainer,
} from './components/FormUI';
import { vendorIdsSchema } from './schemas/vendorIdsSchema';
import { FormFooterButtons } from './components/FormFooterButtons';
import { getInitialVendorIdFormValues, prepareVendorIDsPayload } from './utils';
import { FieldActionButtons } from './components/FieldActionButtons';
import { IMappingsFormProps } from './IMappingsFormProps';

const SecondaryFieldContainer = styled('div')`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto auto;
  gap: 0.5rem;
`;

const placeholder = 'Enter ID';
const textFieldProps = { margin: 0 };

export interface IVendorIdValues {
  primary: string;
  secondary: string[];
}

export const VendorIdsForm: FC<IMappingsFormProps> = ({ data, onCancel }) => {
  const updateMappings = useUpdateMappings();
  const [showConfirmDelete, setShowConfirmDelete] = useState<boolean>(false);
  const [showConfirmDeletePrimary, setShowConfirmDeletePrimary] = useState<boolean>(false);

  const { colors } = useTheme();

  const initialFormValues = useMemo(() => {
    return getInitialVendorIdFormValues(data);
  }, [data]);

  const formik = useFormik<IVendorIdValues>({
    initialValues: initialFormValues,
    validateOnChange: true,
    validationSchema: vendorIdsSchema,
    onSubmit: async (values) => {
      const payload: IUpdateProviderPayload = prepareVendorIDsPayload(data, values);
      await updateMappings(payload, MappingsEventType.editVendorIds);
      onCancel?.();
    },
  });

  const { values, errors, touched, handleSubmit, handleChange, setFieldValue, handleBlur, isSubmitting } =
    formik;

  const handleSetAsPrimary = useCallback(
    (index: number) => {
      const currPrimary = values.primary;
      setFieldValue('primary', values.secondary[index]);
      setFieldValue(`secondary.${index}`, currPrimary);
    },
    [setFieldValue, values.primary, values.secondary]
  );

  return (
    <>
      <FormikProvider value={formik}>
        <FormContainer>
          <FieldContainer>
            <Label htmlFor='primary'>Primary Vendor ID</Label>
            <FieldAndButtonContainer>
              <TextField
                sx={textFieldProps}
                name={'primary'}
                value={values.primary ?? ''}
                onChange={handleChange}
                onBlur={handleBlur}
                error={!!touched.primary && !!errors.primary}
                helperText={touched.primary && errors.primary ? (errors.primary as string) : ''}
                placeholder={placeholder}
              />
              {Boolean(values.primary) && (
                <IconButton
                  aria-label='Delete Primary ID'
                  onClick={() => {
                    setShowConfirmDeletePrimary(true);
                  }}
                  sx={{
                    color: colors.neutral[60],
                  }}
                >
                  <DeleteOutlineIcon fontSize='small' />
                </IconButton>
              )}
            </FieldAndButtonContainer>
            <Collapse
              in={data.provider.toLowerCase() === 'carta' && values.primary !== initialFormValues.primary}
            >
              <Alert severity='warning' style={{}}>
                Changing the Carta ID will impact the company&apos;s fully diluted shares, displayed cap table
                and erase any existing scenarios created for it.
              </Alert>
            </Collapse>
          </FieldContainer>

          <ConfirmDeletionDialog
            open={showConfirmDeletePrimary}
            onClose={() => setShowConfirmDeletePrimary(false)}
            onConfirm={() => {
              setFieldValue('primary', '');
              setShowConfirmDeletePrimary(false);
            }}
            title={`Delete Primary ID "${values.primary}"?`}
          >
            <>
              <Typography
                variant='body2'
                color={colors.neutral[60]}
                sx={{ pt: '.25rem', minWidth: '30rem' }}
              >{`All data vendor links to "${values.primary}" will be removed`}</Typography>
            </>
          </ConfirmDeletionDialog>

          <FieldContainer>
            <FieldArray
              name='secondary'
              render={(arrayHelpers) => (
                <>
                  <LabelAndButtonContainer>
                    <Label>Secondary Vendor IDs</Label>
                    <Button
                      variant='text'
                      color='secondary'
                      size='small'
                      sx={{ fontSize: '.75rem' }}
                      onClick={() => arrayHelpers.push('')}
                      startIcon={<AddIcon />}
                    >
                      Add New
                    </Button>
                  </LabelAndButtonContainer>
                  {values.secondary && values.secondary.length > 0 ? (
                    values.secondary.map((val, index) => (
                      <SecondaryFieldContainer key={index}>
                        <TextField
                          sx={{ ...textFieldProps, width: '100%' }}
                          value={val}
                          name={`secondary.${index}`}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder={placeholder}
                          error={!!touched.secondary && !!errors.secondary?.[index]}
                          helperText={(touched.secondary && errors.secondary?.[index]) ?? ''}
                        />
                        <ConfirmDeletionDialog
                          open={showConfirmDelete && Boolean(val)}
                          onClose={() => setShowConfirmDelete(false)}
                          onConfirm={() => {
                            arrayHelpers.remove(index);
                            setShowConfirmDelete(false);
                          }}
                          title={`Delete Secondary ID "${val}"?`}
                        >
                          <>
                            <Typography
                              variant='body2'
                              color={colors.neutral[60]}
                              sx={{ pt: '.25rem', minWidth: '30rem' }}
                            >{`All data vendor links to "${values.secondary?.[index]}" will be removed`}</Typography>
                          </>
                        </ConfirmDeletionDialog>
                        <FieldActionButtons
                          markAsPrimaryDisabled={!val}
                          onRemove={() => {
                            if (val) {
                              setShowConfirmDelete(true);
                            } else {
                              arrayHelpers.remove(index);
                            }
                          }}
                          onMakeAsPrimary={() => {
                            handleSetAsPrimary(index);
                          }}
                        />
                      </SecondaryFieldContainer>
                    ))
                  ) : (
                    <Typography variant='body2' color={colors.neutral[40]}>
                      There are no secondary websites
                    </Typography>
                  )}
                </>
              )}
            />
            <FormFooterButtons
              isSubmitting={isSubmitting}
              disabled={Object.keys(errors).length !== 0 || isSubmitting}
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              onClose={onCancel}
            />
          </FieldContainer>
        </FormContainer>
      </FormikProvider>
    </>
  );
};
