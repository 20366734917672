import { useMemo } from 'react';
import { Tooltip, useTheme } from '@mui/material';
import { object, SchemaDescription } from 'yup';
import {
  StyledTableHeaderRow,
  StyledTableCell,
  StyledTableRow,
} from '../../CompanyProfiles/components/Table/StyledGridComponents';
import { FMT } from '../../../util/formatter-service';
import { WaterfallGridData, waterfallGridDataFields } from './FPWaterfallData';
import { LpGpPortions } from './DistributionBar';

export function waterfallModellingSummaryDataFields() {
  const { lpDistributed, gpDistributed } = waterfallGridDataFields();
  return {
    lpDistributed: lpDistributed.label('LP NAV'),
    gpDistributed: gpDistributed.label('GP Incentive (Carry)'),
  };
}

export function waterfallModellingSummaryDataSchema() {
  return object().shape(waterfallModellingSummaryDataFields());
}

export function WaterfallModellingSummaryRow({ output }: { output: WaterfallGridData }) {
  const { colors } = useTheme();

  const fieldDescriptionsMap = useMemo(() => {
    return new Map(
      Object.entries(
        waterfallModellingSummaryDataSchema().pick(['lpDistributed', 'gpDistributed']).fields
      ).map(([fieldKey, value]) => [
        fieldKey as keyof WaterfallGridData,
        value.describe() as SchemaDescription,
      ])
    );
  }, []);

  return (
    <>
      <StyledTableHeaderRow style={{ marginTop: '1rem' }}>
        <StyledTableCell style={{ gridColumn: 5 }} type={'numeric'} header>
          {fieldDescriptionsMap.get('lpDistributed')!.label}
        </StyledTableCell>
        <StyledTableCell style={{ gridColumn: 6 }} type='numeric' header>
          {fieldDescriptionsMap.get('gpDistributed')!.label}
        </StyledTableCell>
      </StyledTableHeaderRow>

      <StyledTableRow style={{ background: colors.primary[10] }}>
        <>
          {output.lpDistributed != null ? (
            <Tooltip
              title={<LpGpPortions data={output} />}
              slotProps={{ popper: { sx: { minWidth: 'fit-content' } } }}
              leaveDelay={200}
              placement='top'
            >
              <div style={{ gridColumn: 5 }}>
                <StyledTableCell type={'numeric'}>
                  {FMT.format(
                    fieldDescriptionsMap.get('lpDistributed')!.meta!.formatter!,
                    output.lpDistributed
                  )}
                </StyledTableCell>
              </div>
            </Tooltip>
          ) : (
            <StyledTableCell style={{ gridColumn: 5 }} type={'numeric'}>
              -
            </StyledTableCell>
          )}
        </>
        <StyledTableCell style={{ gridColumn: 6 }} type={'numeric'}>
          {output.gpDistributed != null
            ? FMT.format(fieldDescriptionsMap.get('gpDistributed')!.meta!.formatter!, output.gpDistributed)
            : '-'}
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}
