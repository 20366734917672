import { CustomCellRendererProps } from 'ag-grid-react';

/** If the keyCreator and valueFormatter return the same string,
 * this renderer makes sure the same formatted value is displayed when grouping;
 */
export function GenericIdRenderer(params: CustomCellRendererProps) {
  if (isGroupColumn(params)) {
    return <>{params.node.key}</>;
  }
  return <>{params.valueFormatted ?? params.value}</>;
}

export function isGroupColumn(params: CustomCellRendererProps) {
  return (
    params.column?.getColDef().cellRenderer === 'agGroupCellRenderer' ||
    params.column?.getColId() === 'ag-Grid-AutoColumn'
  );
}
