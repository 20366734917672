import { useRecoilCallback } from 'recoil';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { useSetAtom } from 'jotai';
import { IProviderFieldsDataModel } from '../../data-models/provider-fields.data-model';
import { putProviderFields } from '../queries/MappingsQueries';
import { fieldsMappingByCompanyState } from '../state/CompanyProvidersState';
import { useToastMessageState } from '../../components/ToastMessage/ToastMessageProvider';
import { ConflictErrorStatus, getErrorMessage, getErrorStatusCode } from '../queryHelpers';
import { duplicateErrorState } from '../../pages/Mappings/state/MappingsUIState';
import { MappingsEventType } from '../../pages/Mappings/MappingsGrid/mappingsGridEvents';
import { ICompanyDataModel } from '../../data-models/company.data-model';
import { companyState } from '../state/CompanyState';
import { lastFieldUpdateDateState } from '../../pages/Mappings/state/MappingsState';

export interface IUpdateProviderPayload extends Partial<IProviderFieldsDataModel<unknown>> {
  id: number;
  companyId: number;
}

export function useUpdateMappings() {
  const { pushErrorToast } = useToastMessageState();

  return useRecoilCallback(
    ({ snapshot, gotoSnapshot }) =>
      async (
        updatedFieldData: IUpdateProviderPayload,
        actionType: MappingsEventType,
        params?: AxiosRequestConfig
      ) => {
        const payload = {
          ...updatedFieldData,
          id: Math.abs(updatedFieldData.id),
          primary: updatedFieldData.primary || null,
        };

        try {
          const newData = await putProviderFields(payload, params);

          const updatedSnapshot = snapshot.map((mutable) => {
            mutable.set(fieldsMappingByCompanyState(newData.companyId), (current) => {
              return (
                current?.map((fieldData) => {
                  if (fieldData.id === newData.id) {
                    return newData;
                  }
                  return fieldData;
                }) ?? []
              );
            });
            mutable.set(lastFieldUpdateDateState, new Date().toISOString());
          });
          gotoSnapshot(updatedSnapshot);
        } catch (err) {
          if (isConflictError(err)) {
            console.error(err);
            const url = getConflictingWebsite(err as AxiosError);
            return pushErrorToast({
              message: `Website ${url ? url + ' ' : ''}is linked to another company.
              Please contact support to make this change.`,
              buttonAction: () => window.open('mailto:support@foresightdata.com'),
              buttonLabel: 'Contact Support',
            });
          }
          const message = getErrorMessage(err, 'Error updating mapping');
          pushErrorToast({ message });
          return null;
        }
      },
    []
  );
}

/** @returns true if the error was handled, false otherwise  */
export function useHandleConflicts() {
  const setDuplicateError = useSetAtom(duplicateErrorState);
  const handleConflicts = useRecoilCallback(
    ({ set }) =>
      (error: unknown, editedCompanyId: number, actionType: MappingsEventType) => {
        if (error instanceof AxiosError && error.response?.status === 409) {
          const { data } = error.response;

          if (isDuplicateErrorResponseData(data)) {
            set(companyState(data.company.id), data.company);
            setDuplicateError({
              id: editedCompanyId,
              intoId: data.company.id,
              key: data.key,
              value: data.value,
              action: actionType,
            });
            return true;
          }
        }

        return false;
      },
    []
  );
  return handleConflicts;
}

interface DuplicateErrorResponseData {
  company: ICompanyDataModel;
  key: 'primary' | 'secondary';
  value: string;
}
interface ITempDuplicateErrorResponse {
  message: DuplicateErrorResponseData & { error: string };
  statusCode: 409;
}

function isDuplicateErrorResponseData(error: unknown): error is DuplicateErrorResponseData {
  return error instanceof Object && 'company' in error && 'key' in error && 'value' in error;
}

function isConflictError(error: unknown): error is AxiosError {
  return error instanceof AxiosError && getErrorStatusCode(error) === ConflictErrorStatus;
}

function getConflictingWebsite(error?: AxiosError): string | undefined {
  return (error?.response?.data as ITempDuplicateErrorResponse)?.message?.value;
}
