export const AppName = {
  AirTable: 'AirTable',
  Carta: 'Carta',
  Dropbox: 'Dropbox',
  GoogleDrive: 'Google Drive',
  QuickBooks: 'QuickBooks',
  Salesforce: 'Salesforce',
  Slack: 'Slack',
} as const;
export type AppName = (typeof AppName)[keyof typeof AppName];

export interface IIntegrationsAppDataModel {
  appName: AppName;
  id: number;
  isConnected: boolean;
  oauthUrl: string;
}

export function createIntegrationsAppDataModel(
  overrides: Partial<IIntegrationsAppDataModel> = {}
): IIntegrationsAppDataModel {
  return {
    id: -1,
    isConnected: false,
    oauthUrl: '',
    appName: AppName.AirTable,
    ...overrides,
  };
}
