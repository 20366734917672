import { Menu, MenuItem, useTheme, styled } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Dispatch, FC, SetStateAction } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import UndoIcon from '@mui/icons-material/Undo';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import {
  activeCompanyListIdState,
  listsByIdState,
  listsState,
} from '../../../../../pages/CompanyProfiles/state/ListsState';
import { useSetArchiveDateOnList } from '../hooks/useSetArchiveDateOnList';
import { ListType } from '../../../../../data-models/list.data-model';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { useLoadingBarState } from '../../../../LoadingBar/LoadingBarContext';
import { LoadingId } from '../../../../../types';
import { useModalState } from '../../../../Modal/ModalContext';
import { EditCompanyListForm } from './UploadCompanyList/EditCompanyListForm';

const ActionButtonWrapper = styled('div')`
  display: flex;
  align-items: center;
  gap: 0.3rem;
`;

interface IProps {
  anchorEl: null | HTMLElement;
  setAnchorEl: Dispatch<SetStateAction<null | HTMLElement>>;
}

export const CPListActionMenu: FC<IProps> = ({ anchorEl, setAnchorEl }) => {
  const activeListId = useRecoilValue(activeCompanyListIdState);
  const companyLists = useRecoilValueLoadable(listsState).valueMaybe();
  const listsMap = useRecoilValue(listsByIdState);
  const activeList = activeListId != null ? listsMap.get(activeListId) : null;
  const { neutral } = useTheme().colors;
  const open = Boolean(anchorEl);
  const [archiveList, restoreList, deleteForever] = [
    useSetArchiveDateOnList(30),
    useSetArchiveDateOnList(null),
    useSetArchiveDateOnList(-1),
  ];
  const navigate = useNavigate();
  const { onOpenModal } = useModalState();
  const { actions } = useLoadingBarState();

  const isArchived = Boolean(activeList?.archiveDate);

  const handleEdit = () => {
    onOpenModal(<EditCompanyListForm />);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteWrapper = async (callBackFn: () => Promise<void>) => {
    actions.startLoading(LoadingId.deleteCompanyList);
    await callBackFn();
    actions.stopLoading(LoadingId.deleteCompanyList);
  };

  const handlePermDelete = async () => {
    await deleteForever();
    const defaultListIndex = companyLists?.find(
      (list) => Boolean(list.default) && list.type === ListType.DISCOVERY
    )?.id;
    navigate(`/${ROUTES.COMPANIES}/${ROUTES.LISTS}/${defaultListIndex}`);
  };

  return (
    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
      <MenuItem onClick={handleEdit}>
        <ActionButtonWrapper>
          <EditIcon style={{ color: neutral['60'] }} /> Edit
        </ActionButtonWrapper>
      </MenuItem>
      {isArchived && (
        <MenuItem onClick={() => handleDeleteWrapper(restoreList)}>
          <ActionButtonWrapper>
            <UndoIcon style={{ color: neutral['60'] }} /> Restore
          </ActionButtonWrapper>
        </MenuItem>
      )}
      {isArchived && (
        <MenuItem onClick={() => handleDeleteWrapper(handlePermDelete)}>
          <ActionButtonWrapper>
            <DeleteOutlineIcon style={{ color: neutral['60'] }} /> Delete Forever
          </ActionButtonWrapper>
        </MenuItem>
      )}
      {!isArchived && (
        <MenuItem onClick={() => handleDeleteWrapper(archiveList)}>
          <ActionButtonWrapper>
            <DeleteOutlineIcon style={{ color: neutral['60'] }} /> Delete
          </ActionButtonWrapper>
        </MenuItem>
      )}
    </Menu>
  );
};
