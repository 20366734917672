import { useCallback, useRef } from 'react';
import { Subscription } from 'rxjs';
import { useNavigate } from 'react-router-dom';
import { WebSocketService } from '../../../../services/WebSocketService';
import {
  BulkUploadStatusEvent,
  IBulkUploadStatusMessageDataModel,
  UploadStatus,
} from '../../../../data-models/bulk-upload-status.data-model';
import { useToastMessageState } from '../../../../components/ToastMessage/ToastMessageProvider';
import { IGenericSocketUpdate } from '../../../../data-models/socket-base.data-model';
import { useToken } from '../../../../services/hooks/useToken';
import { ROUTES } from '../../../../constants/RouteNameMapping';

export function useBulkUploadStatus() {
  const statusListener = useRef<Subscription | null>(null);
  const { pushStatusToast } = useToastMessageState();
  const navigate = useNavigate();

  const getToken = useToken();

  const subscribe = useCallback(() => {
    statusListener.current?.unsubscribe();

    WebSocketService.initService(getToken).then(() => {
      statusListener.current = WebSocketService.get().listen<
        IGenericSocketUpdate<IBulkUploadStatusMessageDataModel>
      >(
        BulkUploadStatusEvent,
        (update) => {
          const message = update.data;
          if (message) {
            pushStatusToast({
              message,
              onViewReport:
                message.status === UploadStatus.error && message.uploadId
                  ? () => {
                      navigate(`/${ROUTES.BULK_UPLOAD_REPORT}/${message.uploadId}`);
                    }
                  : undefined,
            });
          }
        },
        false
      );
    });
  }, [getToken, navigate, pushStatusToast]);

  const unsubscribe = useCallback(() => {
    statusListener.current?.unsubscribe();
  }, []);

  return { subscribe, unsubscribe };
}
