import { CSSProperties, FC } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { styled, Stack, Typography, Tooltip, Divider } from '@mui/material';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { startCase } from 'lodash-es';
import { compareAsc } from 'date-fns';
import {
  IKPIRequestDataModel,
  IKPIRequestReminderDataModel,
  ReminderType,
} from '../../../../../data-models/kpi-requests.data-model';
import { BorderlessTooltipProps } from '../../../../../constants/styles';
import { FMT } from '../../../../../util/formatter-service';

const StyledListTable = styled('ul')`
  @supports (grid-template-columns: subgrid) {
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: space-between;
    align-items: center;
  }
  width: fit-content;
  min-width: 16rem;
  max-height: 18rem;
  overflow-y: auto;
`;
const StyledListItem = styled('li')`
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  min-height: 2rem;

  @supports not (grid-template-columns: subgrid) {
    justify-items: space-between;
    column-gap: 2rem;
    align-items: center;
  }
  @supports (grid-template-rows: subgrid) {
    grid-template-columns: subgrid;
  }
`;

const StyledListHolder = styled('span')`
  display: grid;
  padding: 0.5rem;
  gap: 0.5rem;
  position: relative;
`;

export const SentAtRenderer: FC<ICellRendererParams<IKPIRequestDataModel>> = (params) => {
  const sentAt = params.data?.sentAt;
  const reminders = params.data?.kpiRequestReminders ?? [];
  return (
    <Stack direction='row' gap='1rem' alignItems={'center'} width={'100%'} justifyContent={'space-between'}>
      <span className='monospace'>{FMT.format('date', sentAt)}</span>
      {reminders.length > 0 && (
        <Tooltip {...BorderlessTooltipProps} title={<SentRequestsList reminders={reminders} />}>
          <Typography variant='body2' color='error' display={'flex'} gap='0.25rem' alignItems={'center'}>
            {reminders.length}
            <NotificationsActiveOutlinedIcon fontSize='small' titleAccess='reminders' />
          </Typography>
        </Tooltip>
      )}
    </Stack>
  );
};

interface ISentRequestsListProps {
  reminders: IKPIRequestReminderDataModel[];
}

export const SentRequestsList: FC<ISentRequestsListProps> = ({ reminders }) => {
  if (reminders.length === 0) return null;
  const { scheduled, manual } = splitAndSortReminders(reminders);
  const showDivider = scheduled.length > 0 && manual.length > 0;
  return (
    <StyledListHolder>
      <Typography variant='body1' component={'h6'}>
        Reminders
      </Typography>
      <StyledListTable>
        {scheduled.map((reminder) => (
          <ReminderItem reminder={reminder} key={reminder.id} />
        ))}

        {showDivider && <Divider style={{ gridColumn: '1 / -1', marginBottom: '0.25rem' }} />}

        {manual.map((reminder) => (
          <ReminderItem reminder={reminder} key={reminder.id} />
        ))}
      </StyledListTable>
    </StyledListHolder>
  );
};

interface IReminderItemProps {
  reminder: IKPIRequestReminderDataModel;
  style?: CSSProperties;
}
export const ReminderItem: FC<IReminderItemProps> = ({ reminder, style }) => {
  const { type, sentAt } = reminder;
  return (
    <>
      <StyledListItem style={style}>
        <Stack direction='row' alignItems={'center'} gap='0.25rem'>
          <NotificationsActiveOutlinedIcon fontSize='small' color='error' />
          <Typography variant='caption' color={'text.primary'}>
            {startCase(ReminderType[type])}
          </Typography>
        </Stack>
        <Typography variant='caption' color={'text.secondary'}>
          Sent
        </Typography>
        <Typography variant='caption' color={'text.secondary'}>
          {FMT.format('date', sentAt)}
        </Typography>
      </StyledListItem>
    </>
  );
};

export function splitAndSortReminders(reminders: IKPIRequestReminderDataModel[]) {
  const sortedBySentAt = [...reminders].sort((a, b) => compareAsc(new Date(a.sentAt), new Date(b.sentAt)));
  const result = sortedBySentAt.sort((a, b) => {
    if (a.type === b.type) return 0;
    if (a.type === 'scheduled') return -1;
    if (a.type === 'manual') return 1;
    return 0;
  });

  const scheduled: IKPIRequestReminderDataModel[] = [];
  const manual: IKPIRequestReminderDataModel[] = [];
  result.forEach((reminder) => {
    if (reminder.type === 'scheduled') {
      scheduled.push(reminder);
    } else {
      manual.push(reminder);
    }
  });

  return { scheduled, manual };
}
