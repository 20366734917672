import { useMemo } from 'react';
import { Fund } from '../../../schemas/Fund.schema';
import { FundMetricsByFund } from '../../../schemas/FundMetrics.schema';
import { WaterfallGridData } from './FPWaterfallData';
import { WaterfallFieldsGenerator } from './WaterfallFieldsGenerator';

export function useWaterfallCalcsData(
  fund: Fund,
  fundMetricsByFund: FundMetricsByFund,
  gridData: WaterfallGridData[]
) {
  return useMemo(() => {
    const generator = new WaterfallFieldsGenerator(fund, fundMetricsByFund, gridData);
    const fundInputs = generator.getFundInputs();
    const fundMetricsInputs = generator.getFundMetricsInputs();
    const fundMetricsByFundInputs = generator.getFundMetricsByFundInputs();
    const initialTierFields = generator.getInitialTierFields();
    const tier0Fields = generator.getTier0Fields();
    const tier1Fields = generator.getTier1Fields();
    const tier2Fields = generator.getTier2Fields();
    const tier3Fields = generator.getTier3Fields();
    const addBackDistributionsFields = generator.getAddBackFields();
    const outputFields = generator.getOutputFields();

    return {
      fundInputs,
      fundMetricsInputs,
      fundMetricsByFundInputs,
      initialTierFields,
      tier0Fields,
      tier1Fields,
      tier2Fields,
      tier3Fields,
      addBackDistributionsFields,
      outputFields,
    };
  }, [fund, fundMetricsByFund, gridData]);
}
