import { useCallback } from 'react';
import { useAtom } from 'jotai';
import { useRecoilValue } from 'recoil';
import { selectedFundStateFP, showFundFormsState, useResetFundFormState } from '../state/FPState';
import { BasicDialog } from '../../../components/Dialog/BasicDialog';
import { FundWaterfallForm, IFundFormProps } from './FundWaterfallForm';
import { FundCapitalAllocationForm } from './Allocation/FundCapitalAllocationForm';

export function FundFormModal({ defaultValues, onSubmit }: Omit<IFundFormProps, 'onCancel'>) {
  const fund = useRecoilValue(selectedFundStateFP);
  const [showForm, setShowForm] = useAtom(showFundFormsState);
  const resetFormState = useResetFundFormState();

  const onClose = useCallback(() => {
    setShowForm(null);
    resetFormState();
  }, [resetFormState, setShowForm]);

  const title =
    defaultValues?.name &&
    `${defaultValues.name} ${showForm === 'waterfall' ? 'Waterfall' : 'Allocation'} Settings`;

  if (!fund) return null;

  return (
    <BasicDialog open={showForm != null} title={title} onClose={onClose}>
      {showForm === 'waterfall' && (
        <FundWaterfallForm defaultValues={defaultValues} onSubmit={onSubmit} onCancel={onClose} />
      )}
      {showForm === 'allocation' && (
        <FundCapitalAllocationForm defaultValues={defaultValues} onSubmit={onSubmit} />
      )}
    </BasicDialog>
  );
}
