import { NumericFormatterId } from './formatters/NumericFormatters';

export const StandardParserId = {
  Number: 'number',
} as const;
export type StandardParserId = (typeof StandardParserId)[keyof typeof StandardParserId];

export const CustomParserId = {
  Fund: 'fund',
} as const;
export type CustomParserId = (typeof CustomParserId)[keyof typeof CustomParserId];

export type ParserFn<T> = (str?: string | null) => T;
export interface IParser<OutputValueType> {
  parse: ParserFn<OutputValueType>;
}
export class ParserService {
  static #instance: ParserService | undefined;
  #parserMap = new Map<string, ParserFn<unknown>>();

  setParserForId(id: StandardParserId | CustomParserId, parser: ParserFn<unknown>) {
    this.#parserMap.set(id, parser);
  }

  parse = (id: StandardParserId | CustomParserId, str: string) => {
    const parser = this.#parserMap.get(id);
    return parser ? parser(str) : str;
  };

  #init = () => {
    this.setParserForId(StandardParserId.Number, new NumberParser().parse);
  };

  static initService() {
    if (!this.#instance) {
      this.#instance = new ParserService();
      this.#instance.#init();
    }

    return this.#instance;
  }

  static get() {
    if (!this.#instance) {
      throw new Error('Parser service has not been initialized');
    }
    return this.#instance;
  }

  static destroyService() {
    this.#instance = undefined;
  }
}

class NumberParser implements IParser<number | null> {
  #decimal;
  #group;
  #index;
  #numeral;

  constructor() {
    const format = new Intl.NumberFormat();
    const parts = format.formatToParts(12345.6);
    const numerals = Array.from({ length: 10 }).map((_, i) => format.format(i));
    const index = new Map<string, number>(numerals.map((d, i) => [d, i]));

    this.#group = new RegExp(`[${parts.find((d) => d.type === 'group')?.value}]`, 'g');
    this.#decimal = new RegExp(`[${parts.find((d) => d.type === 'decimal')?.value}]`);
    this.#numeral = new RegExp(`[${numerals.join('')}]`, 'g');
    this.#index = (d: string) => String(index.get(d));
  }

  parse = (str?: string | null): number | null => {
    if (!str) return null;
    const result = str
      .trim()
      .replace(this.#group, '')
      .replace(this.#decimal, '.')
      .replace(this.#numeral, this.#index)
      .replace(/[^0-9.-]+/g, '');
    const floatMaybe = parseFloat(result);
    return !isNaN(floatMaybe) ? floatMaybe : null;
  };
}

export const supportedNumberFormats = new Set<NumericFormatterId>([
  NumericFormatterId.integer,
  NumericFormatterId.multiplier,
  NumericFormatterId.multiplierHighPrecision,
  NumericFormatterId.numeric,
  NumericFormatterId.numeric1DP,
  NumericFormatterId.numeric2DP,
  NumericFormatterId.percent2dpAsIs,
  NumericFormatterId.percentHighPrecision,
  NumericFormatterId.usd,
  NumericFormatterId.usdRound,
]);
