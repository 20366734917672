import { useRecoilCallback } from 'recoil';
import { useUpdateCompanyAndState } from '../../../../../services/hooks/useUpdateCompanyAndState';
import { useToastMessageState } from '../../../../../components/ToastMessage/ToastMessageProvider';
import { getErrorMessage } from '../../../../../services/queryHelpers';
import { bulkUpdateReportingStatus } from '../../../../../services/queries/MaggieCompanyQueries';
import { companyState } from '../../../../../services/state/CompanyState';
import { useInvalidateKPIRequests } from '../../KPIRequests/KPIRequestResponse/hooks/useInvalidateKPIRequests';
import { ICompanyDataModel } from '../../../../../data-models/company.data-model';
import { useLoadingBarState } from '../../../../../components/LoadingBar/LoadingBarContext';
import { LoadingId } from '../../../../../types';

interface IBulkUpdateReportingStatusError {
  companyId: number;
  name: string;
  message: string;
}
export interface IBulkUpdateReportingStatusResponse {
  processed: ICompanyDataModel[];
  errors: IBulkUpdateReportingStatusError[];
}

export interface IBulkUpdateReportingStatusPayload {
  companyIds: number[];
  value: boolean;
}

export function useSetReportingStatus() {
  const updateCompany = useUpdateCompanyAndState();
  const { pushSuccessToast, pushErrorToast } = useToastMessageState();
  const invalidateKpiRequests = useInvalidateKPIRequests();
  const { actions } = useLoadingBarState();

  return useRecoilCallback(
    ({ set, snapshot }) =>
      async (companyIds: number[], reporting: boolean) => {
        actions.startLoading(LoadingId.bulkUpdateReportingStatus);
        try {
          const res = await bulkUpdateReportingStatus(companyIds, reporting);

          if (res!.processed && res!.processed.length > 0) {
            res!.processed
              .map(({ id }) => id)
              .forEach((id) => {
                set(companyState(id), (prev) =>
                  !prev
                    ? prev
                    : {
                        ...prev,
                        reporting,
                      }
                );
              });
            invalidateKpiRequests();
          }

          if (res!.errors && res!.errors.length > 0) {
            const names = res!.errors
              .map((err) => snapshot.getLoadable(companyState(err.companyId)).valueMaybe()?.name)
              .join(', ');
            pushErrorToast({
              message: `Failed to update reporting status for ${names}`,
            });
          } else {
            pushSuccessToast({
              message: `Data collection is now ${reporting ? 'enabled' : 'disabled'}`,
            });
          }
        } catch (error) {
          console.error(error);
          pushErrorToast({
            message: getErrorMessage(error, 'Failed to update reporting status'),
          });
        } finally {
          actions.stopLoading(LoadingId.bulkUpdateReportingStatus);
        }
      },
    [pushSuccessToast, updateCompany]
  );
}
