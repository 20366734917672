import { atom, atomFamily, selectorFamily } from 'recoil';
import { endOfDay } from 'date-fns';
import { IInvestmentDataModel } from '../../../data-models/investment.data-model';
import { fetchRoundTracker } from '../../../services/queries/MaggieMetricsQueries';
import { dateToPlainDateString } from '../../../util/date-utilts';

export const forceRoundTrackerRefresh = atom<number>({
  key: 'forceRoundTrackerRefresh',
  default: 0,
});

// FIXME: As part of MAGGIE-2942, avoid the below and move this logic into an atom + hook.
let todaysRoundTrackerMetrics: { date: Date; metrics: IInvestmentDataModel[] | null } = {
  date: endOfDay(new Date()),
  metrics: null,
};

export const roundTrackerMetricsByDate = selectorFamily<IInvestmentDataModel[], Date>({
  key: 'roundTrackerMetricsByDate',
  get:
    (date) =>
    async ({ get }) => {
      get(forceRoundTrackerRefresh);
      const today = endOfDay(new Date());
      const d = date ?? today;
      const metrics = await fetchRoundTracker(dateToPlainDateString(d));
      todaysRoundTrackerMetrics = { date: d, metrics };
      // }

      return todaysRoundTrackerMetrics.metrics!.filter((metric) => {
        return new Date(metric.investmentDate).getTime() < d.getTime();
      });
    },
});

export const roundTrackerMetricsFilteredByViewState = atomFamily<IInvestmentDataModel[], number>({
  key: 'roundTrackerMetricsFilteredByViewState',
  default: [],
});
