import { useCallback, useMemo, useState } from 'react';
import { Stack, Typography, IconButton } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { GridApi } from 'ag-grid-community';
import { AgTable } from '../../../components/AgTable/AgTable';
import { FadeInGridWrapper } from '../../../components/grid-renderers/FadeInGridWrapper';
import { BulkUploadReportResponse } from '../../../schemas/BulkUploadReport.schema';
import { getBulkUploadReportGridData, BulkUploadReportDefaultColDef } from './bulkUploadReportGridData';

export function BulkUploadReportGrid({ report }: { report: BulkUploadReportResponse }) {
  const { fileName, rowData, colDefs } = useMemo(() => getBulkUploadReportGridData(report), [report]);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const onGridReady = useCallback((params: { api: GridApi }) => {
    setGridApi(params.api);
  }, []);
  const exportParams = {
    fileName: `Bulk-upload-report-${fileName}`,
  };
  return (
    <div style={{ height: '80dvh' }}>
      <Stack direction='row' width='100%' justifyContent={'space-between'} alignItems={'center'}>
        <Typography component='h6' mb='0.25rem'>
          {fileName}
        </Typography>
        <IconButton color='secondary' onClick={() => gridApi?.exportDataAsExcel(exportParams)}>
          <FileDownloadIcon />
        </IconButton>
      </Stack>
      <FadeInGridWrapper>
        <AgTable
          onGridReady={onGridReady}
          columnDefs={colDefs}
          rowData={rowData}
          defaultColDef={BulkUploadReportDefaultColDef}
          rowGroupPanelShow='never'
        />
      </FadeInGridWrapper>
    </div>
  );
}
