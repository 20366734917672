import { ApolloClient, createHttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import {
  ColDef,
  ColGroupDef,
  FilterModel,
  GridApi,
  IServerSideGetRowsParams,
  IServerSideGetRowsRequest,
} from 'ag-grid-community';
import gql from 'graphql-tag';
import { getEnvVariable } from '../../../../util/environment';

// https://github.com/ForesightData/microservices/blob/eee61a74bca255cd401c6cb620bad6a9f1fb397b/airquery/graph/schema/schema.graphqls
export class ServerSideDatasource {
  #gridApi: GridApi;
  #client: ApolloClient<NormalizedCacheObject>;
  constructor(gridApi: GridApi, token: string) {
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      };
    });

    this.#gridApi = gridApi;
    this.#client = new ApolloClient({
      link: authLink.concat(
        createHttpLink({ uri: `${getEnvVariable('VITE_MAGGIE_SOURCING_API')}/api/graphql` })
      ),
      cache: new InMemoryCache(),
    });
  }

  getRows(params: IServerSideGetRowsParams) {
    const columns = this.#gridApi.getColumnDefs()?.filter((c) => !(c as ColDef).hide);

    this.#client
      .query(query(params.request, columns ?? []))
      .then((response) => {
        const rows = response.data.companies;

        // determine last row to size scrollbar and last block size correctly
        // let rowCount = -1;
        // if (rows.length <= (this.gridOptions.cacheBlockSize ?? 100)) {
        //   rowCount = params.request.startRow + rows.length;
        // }

        params.success({ rowData: rows /* rowCount */ });
      })
      .catch((err) => {
        console.error(err);
        params.fail();
      });
  }
}

const query = (request: IServerSideGetRowsRequest, columns: (ColDef | ColGroupDef)[]) => {
  const fields = columns.reduce((acc, col) => {
    if ((col as ColDef).field) {
      return `${acc}${(col as ColDef).field}\n`;
    }
    return acc;
  }, '');

  return {
    query: gql`
      query GetCompanies($filter: CompanyFilter) {
        companies(filter: $filter) {
          ${fields}
        }
      }
    `,
    variables: {
      filter: gridFilterModelToInput(request.filterModel ?? {}),
      // end: request.endRow,
      // filterModel: request.filterModel,
      // sortModel: request.sortModel,
      // start: request.startRow,
    },
  };
};

function gridFilterModelToInput(filterModel: FilterModel) {
  return Object.entries(filterModel).reduce(
    (acc, [key, value]) => {
      acc[key] = value.filter;
      return acc;
    },
    {} as Record<string, unknown>
  );
}
