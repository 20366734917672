import { atom, atomFamily, selector, selectorFamily } from 'recoil';
import { IMetricsDataModel, IMetricsResponseDataModel } from '../../../data-models/metrics.data-model';
import {
  fetchMetrics,
  getFundMetrics,
  getPlainDateString,
} from '../../../services/queries/MaggieMetricsQueries';
import { IFundMetricsResponseDataModel } from '../../../data-models/fund-metrics.data-model';
import { selectedViewDatePF } from './PageState';

export const metricsByDateState = selectorFamily<IMetricsDataModel[], Date>({
  key: 'metricsByDateState',
  get:
    (date) =>
    ({ get }) => {
      const metricsResponse = get(metricsResponseByDateState(date));
      return metricsResponse.metrics;
    },
});

export const metricsFilteredByViewState = atomFamily<IMetricsDataModel[], number>({
  key: 'metricsFilteredByViewState',
  default: [],
});

export const forceMetricsUpdate = atom<number>({
  key: 'forceMetricsUpdate',
  default: 0,
});

export const metricsResponseByDateState = atomFamily<IMetricsResponseDataModel, Date>({
  key: 'metricsByDateState',
  default: selectorFamily({
    key: 'metricsByDateState/default',
    get:
      (date) =>
      ({ get }) => {
        get(forceMetricsUpdate);
        return fetchMetrics(getPlainDateString(date));
      },
  }),
});

export const forceFundMetricsUpdate = atom<number>({
  key: 'forceFundMetricsUpdate',
  default: 0,
});

export const fundMetricsByDateState = selector<IFundMetricsResponseDataModel>({
  key: 'fundMetricsState',
  get: ({ get }) => {
    get(forceFundMetricsUpdate);
    const selectedDate = get(selectedViewDatePF);
    return getFundMetrics(getPlainDateString(selectedDate));
  },
});

// Data as filtered by the funds overview grid (which also updates when the main fund filter changes) mapped to fundIds;
// Source for kpi and chart section fund filter
export const fundMetricsFilteredFundIdsState = atomFamily<number[], number>({
  key: 'fundMetricsFilteredByViewState',
  default: selector({
    key: 'fundMetricsFilteredByViewState/default',
    get: ({ get }) => {
      return get(fundMetricsByDateState).metrics.map((metrics) => metrics.fundId);
    },
  }),
});
