import { ColDef, ColDefField, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { get, merge } from 'lodash-es';
import { CalendarShortcut } from '../components/DatePicker/Calendar';
import { createFormField, IFormField } from '../view-models/form.view-model';
import { FormatterService } from '../util/formatter-service';
import { numericCellClasses, numericColDefs } from '../pages/Finance2/common-grid-defs/commonColDefs';
import {
  getExcelClassForCurrency,
  rendererTypeToExcelStyleId,
} from '../components/AgTable/exportToExcelDefs';
import { SearchType } from '../services/queries/MaggieCompanyQueries';
import { NumericFormattersConfig } from '../util/formatters/NumericFormatters';
import { DateFormattersConfig } from '../util/formatters/DateFormatters';
import { BooleanFormattersConfig } from '../util/formatters/BooleanFormatters';
import { StringFormattersConfig } from '../util/formatters/StringFormatters';
import { ArrayFormattersConfig } from '../util/formatters/ArrayFormattersConfig';
import {
  createFieldDataModel,
  createFieldFormConfigDataModel,
  FieldEntity,
  getRendererTypeFromFieldType,
  IField,
  ISimpleChoice,
} from './field2.data-model';
import { RendererType } from './field.data-model';
import { createFormatterDataModel, IFormatterDataModel } from './formatter.data-model';

export const PrimitiveTypesAsArray = ['string', 'number', 'boolean', 'array', 'date'] as const;
export type PrimitiveType = (typeof PrimitiveTypesAsArray)[number];

export interface IField3<MetaType> {
  createdAt?: string | null;
  createdBy?: string | null;
  dataType: PrimitiveType;
  description: string;
  displayName: string;
  entity: FieldEntity;
  entityKey: string;
  format?: RendererType;
  id: number;
  meta?: MetaType;
  providerOrder: string[];
  updatedBy?: string | null;
}

export function isField3<T>(field: IField<unknown> | IField3<unknown>): field is IField3<T> {
  return 'dataType' in field && 'entityKey' in field;
}

export interface ITextMeta {
  maxLength?: number;
}

export interface ISelectMeta<T> {
  multi?: boolean;
  values: ISimpleChoice<T>[];
  displayEmpty?: boolean;
  formatValueToLabel?: boolean;
}

export interface ICurrencyMeta extends INumberMeta {
  defaultCurrency: string;
}

export interface INumberMeta {
  maximum?: number;
  minimum?: number;
  groupingSeparator?: string;
}

export interface IDateMeta {
  minimum?: string;
  maximum?: string;
  presets?: CalendarShortcut[];
}

export interface ICompanySearchMeta {
  addLabel?: (typedValue: string) => string;
  multiSelect?: boolean;
  onAdd?: (data: unknown) => void;
  searchType?: SearchType;
  showAdd?: boolean;
  createOnSelection?: boolean;
}

export interface IFileMeta {
  maxSize?: number;
  acceptTypes?: string[];
  // multiple?: boolean; // TODO
}

// Examples:

// const simpleField: IField3 = {
//   dataType: 'string',
//   description: '',
//   displayIn: [],
//   displayName: '',
//   entity: '',
//   entityKey: '',
//   id: 0,
//   source: FieldSource.Default,
// };

export function createField3DataModel<T>(overrides: Partial<IField3<T>>): IField3<T> {
  return {
    dataType: 'string',
    description: '',
    displayName: 'field foo',
    entity: FieldEntity.company,
    entityKey: 'bar',
    id: 0,
    providerOrder: [],
    ...overrides,
  };
}

export function createIntegerField(overrides: Partial<IField3<INumberMeta>>) {
  return createField3DataModel<INumberMeta>({
    dataType: 'number',
    format: RendererType.integer,
    ...overrides,
  });
}

export function createPercentField(overrides: Partial<IField3<INumberMeta>>) {
  return createField3DataModel<INumberMeta>({
    dataType: 'number',
    format: RendererType.percent,
    ...overrides,
  });
}

export function field3ToFormField<T>(
  field3: IField3<T>,
  overrides: Partial<IFormField<T>> = {}
): IFormField<T> {
  const { entityKey, displayName, dataType, meta, description, format } = field3;

  return createFormField<T>({
    key: entityKey,
    label: displayName,
    description,
    dataType,
    formatter: getFormatterModelForField3(field3),
    renderer: format ?? getRendererTypeFromFieldType(dataType),
    rendererMeta: meta,
    ...overrides,
  });
}

export function field3ToField2(field3: IField3<unknown>): IField<unknown> {
  const { description, id, entity, entityKey, displayName, dataType, meta } = field3;
  const field2 = createFieldDataModel({
    description,
    id,
    entity,
    entityField: entityKey,
    displayName,
    type: dataType,
  });

  const rendererType = field3.format;
  field2.formMeta = createFieldFormConfigDataModel();
  switch (rendererType) {
    case RendererType.boolean: {
      field2.formMeta.renderer!.type = RendererType.boolean;
      break;
    }
    case RendererType.text: {
      field2.formMeta.renderer!.type = RendererType.text;
      field2.formMeta.renderer!.config = {
        maxLength: (meta as ITextMeta).maxLength,
      };
      break;
    }
    case RendererType.number: {
      field2.formMeta.renderer!.type = RendererType.number;
      break;
    }
    case RendererType.currency: {
      field2.formMeta.renderer!.type = RendererType.currency;
      field2.formMeta.formatter = createFormatterDataModel({
        type: RendererType.currency,
        config: {
          currency: (meta as ICurrencyMeta).defaultCurrency,
        },
      });
      break;
    }
    case RendererType.percent: {
      field2.formMeta.renderer!.type = RendererType.percent;
      break;
    }
    case RendererType.multiplier: {
      field2.formMeta.renderer!.type = RendererType.multiplier;
      break;
    }
    case RendererType.singleSelect: {
      field2.formMeta.renderer!.type = RendererType.singleSelect;
      field2.formMeta.renderer!.config = {
        values: (meta as ISelectMeta<unknown>).values,
      };
      break;
    }
    case RendererType.multiSelect: {
      field2.formMeta.renderer!.type = RendererType.multiSelect;
      field2.formMeta.renderer!.config = {
        values: (meta as ISelectMeta<unknown>).values,
      };
      break;
    }
    case RendererType.date: {
      field2.formMeta.renderer!.type = RendererType.date;
      break;
    }
    default: {
      field2.formMeta.renderer!.type = RendererType.text;
    }
  }

  return field2;
}

export function field3ToColumnDef<T>(field: IField3<unknown>): ColDef<T> {
  const format = getFormatterForGridField(field);

  let defs: ColDef<T> = {
    field: field.entityKey as ColDefField<T>,
    colId: field.entityKey,
    headerName: field.displayName,
    valueFormatter: (params: ValueFormatterParams<T>) => {
      if (params.value == null) return '';

      return format(params.value);
    },
    valueGetter: (params: ValueGetterParams<T>) => {
      return get(params.data, field.entityKey);
    },
  };

  if (field.dataType === 'number') {
    defs = merge({}, numericColDefs, defs);

    // add cellClasses for export to excel
    if (field.format === RendererType.currency) {
      defs = merge({}, defs, {
        cellClass: [
          ...numericCellClasses,
          getExcelClassForCurrency((field.meta as ICurrencyMeta).defaultCurrency ?? 'USD'),
        ],
      });
    } else if (field.format) {
      defs = merge({}, defs, {
        cellClass: [...numericCellClasses, rendererTypeToExcelStyleId[field.format]],
      });
    }
  }

  return defs;
}

export function getFormatterForRenderer(rendererType: Exclude<RendererType, RendererType.currency>) {
  switch (rendererType) {
    case RendererType.boolean:
      return BooleanFormattersConfig.yesNo;
    case RendererType.date:
      return DateFormattersConfig.date;
    case RendererType.freeLabels:
      return ArrayFormattersConfig.stringArray;
    case RendererType.integer:
      return NumericFormattersConfig.integer;
    case RendererType.multiplier:
      return NumericFormattersConfig.multiplier;
    case RendererType.number:
      return NumericFormattersConfig.numeric;
    case RendererType.percent:
      return NumericFormattersConfig.percent2dpAsIs;

    default:
      return StringFormattersConfig.string;
  }
}

export function getFormatterModelForField3(field: IField3<unknown>): IFormatterDataModel<unknown> {
  switch (field.format) {
    case RendererType.currency: {
      const currencyName = (field.meta as ICurrencyMeta)?.defaultCurrency ?? 'USD';

      return {
        type: 'number',
        id: `${currencyName}-short`,
        config: {
          compactDisplay: 'short',
          currency: currencyName,
          notation: 'compact',
          style: 'currency',
        },
      };
    }

    case RendererType.number: {
      if ((field.meta as INumberMeta)?.groupingSeparator === '') {
        return StringFormattersConfig.string;
      } else {
        return getFormatterForRenderer(field.format);
      }
    }

    case undefined:
      return getFormatterForType(field.dataType);

    default:
      return getFormatterForRenderer(field.format);
  }
}

export function getFormatterForType(type: PrimitiveType): IFormatterDataModel<unknown> {
  switch (type) {
    case 'boolean':
      return BooleanFormattersConfig.yesNo;
    case 'array':
      return ArrayFormattersConfig.stringArray;
    case RendererType.number: {
      return NumericFormattersConfig.numeric;
    }
    case 'date': {
      return DateFormattersConfig.date;
    }

    case 'string':
    default:
      return StringFormattersConfig.string;
  }
}

function getFormatterForGridField(field: IField3<unknown>) {
  if (field.format === RendererType.currency) {
    const currencyField = field as IField3<ICurrencyMeta>;
    const currencyName = currencyField.meta?.defaultCurrency ?? 'USD';

    return FormatterService.get().getFormatterForModel({
      type: 'number',
      id: `${currencyName}-long`,
      config: {
        currency: currencyName,
        style: 'currency',
      },
    });
  }

  return FormatterService.get().getFormatterForFieldV3(field);
}
