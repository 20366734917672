import { Button, ButtonProps, Typography } from '@mui/material';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';
import { SelectItem } from '../types';
import { PopupMenu } from './PopupMenu/PopupMenu';

export interface IPopupFilterProps {
  selected: SelectItem[] | null;
  setSelected: (newValue: SelectItem[] | null) => void;
  options: SelectItem[];
  labels: {
    item: string;
    all?: string;
  };
  groupable?: boolean;
  searchable?: boolean;
}
export function PopupFilter({
  selected,
  setSelected,
  options,
  labels,
  groupable,
  searchable = true,
}: IPopupFilterProps) {
  const onChange = (newValue: SelectItem[]) => {
    if (newValue.length === options.length) {
      setSelected(null);
    } else {
      setSelected(newValue);
    }
  };
  const label = (
    <Typography variant='body2'>
      {`${labels.item} ${selected?.length ?? options.length}/${options.length}`}
    </Typography>
  );

  return (
    <PopupMenu
      items={options}
      groupable={groupable}
      searchable={searchable}
      label={label}
      onChange={onChange}
      selectedItems={selected ?? options}
      ActionComponent={FilterSelectorButton}
    />
  );
}

function FilterSelectorButton({ children, ...rest }: ButtonProps) {
  return (
    <Button endIcon={<FilterListOutlinedIcon />} variant={'outlined'} size='medium' {...rest}>
      {children}
    </Button>
  );
}
