import { useEffect, useMemo, useRef, useState } from 'react';
import { styled } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { IPortfolioChartProps } from '../ChartComponent';
import { IMetricsDataModel } from '../../../../../data-models/metrics.data-model';
import { ChartTitleWrapper } from '../../../../../components/Charts/ChartTitleWrapper';
import { Chart, ChartSizes } from '../../../../../components/Charts/chart-utils';
import { ChartWrapper } from '../../../../../components/Charts/ChartWrapper/ChartWrapper';
import { IconTypes } from '../../../../../components/Icon';
import { CHART_COMPONENTS } from '../../../../../types';
import { AgTable } from '../../../../../components/AgTable/AgTable';
import { selectedViewDatePF } from '../../../state/PageState';
import { useGetCompanyIfSet } from '../../../../CompanyProfiles/hooks/useGetCompanyData';
import { computeData } from './MetricsByUserUtils';
import { useMetricsByUserColDefs } from './useMetricsByUserColDefs';

const TableContainer = styled('div')`
  overflow: auto;
  height: 100%;
  width: 100%;
`;

export function MetricsByUserChart(props: IPortfolioChartProps<IMetricsDataModel>) {
  const [showMore, setShowMore] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [widthState, setWidthState] = useState<string | number>(640);
  const [size, setSize] = useState('1/2 Screen');
  const ref = useRef(null);
  const date = useRecoilValue(selectedViewDatePF);
  const columnDefs = useMetricsByUserColDefs();
  const getCompany = useGetCompanyIfSet();

  const metrics = useMemo(() => {
    return computeData(props.filteredMetrics, date, getCompany);
  }, [date, getCompany, props.filteredMetrics]);

  useEffect(() => {
    const updateSize = () => {
      setWidthState(ChartSizes[size as keyof typeof ChartSizes]);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [size]);

  return (
    <ChartWrapper
      id={CHART_COMPONENTS.METRICS_BY_USER}
      onMouseLeave={() => {
        setShowMore(false);
        setIsHovering(false);
      }}
      onMouseEnter={() => setIsHovering(true)}
      dataTestid={'mostRecentRoundsChart'}
      width={widthState}
    >
      <ChartTitleWrapper
        id={CHART_COMPONENTS.METRICS_BY_USER}
        isHovering={isHovering}
        title={'Metrics By User'}
        showMore={showMore}
        setShowMore={setShowMore}
        icon={IconTypes.STATISTICS_1}
        handleSizeChange={setSize}
        size={size}
        refProp={ref}
      />
      <Chart width={'100%'} ref={ref}>
        <TableContainer>
          <AgTable columnDefs={columnDefs} rowData={metrics} rowGroupPanelShow='never' />
        </TableContainer>
      </Chart>
    </ChartWrapper>
  );
}
