import {
  CellClassFunc,
  ColDef,
  CsvExportParams,
  ExcelExportParams,
  ExcelStyle,
  ProcessCellForExportParams,
} from 'ag-grid-community';
import { get, merge } from 'lodash-es';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { RendererType } from '../../data-models/field.data-model';
import { getPlainDateString } from '../../services/queries/MaggieMetricsQueries';
import { currenciesState } from '../../services/state/AppConfigState';
import { FMT, StandardFormatterId } from '../../util/formatter-service';
import { DateFormattersId } from '../../util/formatters/DateFormatters';
import { sanitizeAsFileName } from '../../util/stringUtils';

export function commonProcessCb(params: ProcessCellForExportParams) {
  const val = params.value;
  if (val === undefined || val === null) return '';

  if (val instanceof Date) {
    // Return the raw date from the BE which will be in ISO date format YYYY-MM-DD
    return get(params.node?.data, (params.column?.getDefinition() as ColDef)?.field ?? '') ?? '';
  }

  const type = params.column.getColDef().type;
  const cellClass = [params.column.getColDef().cellClass].flat();

  if (isPercentColumn(type, cellClass)) {
    return processValueForExport(val, 'percent2dpAsIs');
  }

  return val;
}

export const defaultExcelStyles: ExcelStyle[] = [
  { id: 'currencyUSD', numberFormat: { format: '$#,##0.00' } },
  { id: 'currencyUSDRound', numberFormat: { format: '$#,##0' } },
  { id: 'dateISO', dataType: 'DateTime', numberFormat: { format: 'mm/dd/yyyy;;;' } },
  { id: 'default', dataType: 'String' },
  { id: 'int', numberFormat: { format: '#,##0' } },
  { id: 'multiplier', numberFormat: { format: '#,##0.00x' } },
  { id: 'naturalNumber', numberFormat: { format: '#,##0.00;"0.00"' } },
  { id: 'numberTwoDecimals', numberFormat: { format: '#,##0.00' } },
  { id: 'percent', numberFormat: { format: '#,##0.00%' } },
  { id: 'usdPositive', numberFormat: { format: '$#,##0.00;"$0.00"' } },
];

// returns excel styles for all supported currencies
export function useExcelStylesForCurrencies() {
  const currencies = useRecoilValue(currenciesState);

  if (!currencies) return [];
  return currencies.map((currency) => ({
    id: getExcelClassForCurrency(currency.code),
    numberFormat: {
      format: `${currency.symbol}#,##0.00`,
    },
  }));
}

export function getExcelClassForCurrency(currencyCode: string) {
  return `currency${currencyCode}`;
}
export function useExportToExcelStyles() {
  const currencyStyles = useExcelStylesForCurrencies();
  const customFormatterStyles = FMT.get().getCustomExcelStyles();

  return [...defaultExcelStyles, ...customFormatterStyles, ...currencyStyles];
}

export const rendererTypeToExcelStyleId: Partial<Record<RendererType, string>> = {
  [RendererType.date]: 'dateISO',
  [RendererType.percent]: 'percent',
  [RendererType.number]: 'numberTwoDecimals',
  [RendererType.currency]: 'currencyUSD',
  [RendererType.integer]: 'int',
  [RendererType.multiplier]: 'multiplier',
  [RendererType.text]: 'default',
};

export const formatterIdToExcelStyleId: Partial<Record<StandardFormatterId | string, string>> = {
  date: 'dateISO',
  integer: 'int',
  multiplier: 'multiplier',
  naturalNumber: 'naturalNumber',
  percent2dpAsIs: 'percent',
  percent2dpAsIsPositive: 'percent2dpAsIsPositive',
  usd: 'currencyUSD',
  usdPositive: 'usdPositive',
  usdShort: 'currencyUSD',
};

/**
 *
 * @param colIds set of column ids (`column.colId`) that should be exported with custom formatting,
 * by calling the defined valueFormatter for each column
 * @returns  export params that can be passed as defaultExcelExportParams or defaultCsvExportParams
 */
export function useExtendedExportSettings<T>(colIds: Set<string>, fileName?: string) {
  const exportParams: ExcelExportParams & CsvExportParams = useMemo(() => {
    function processCb(params: ProcessCellForExportParams<T>) {
      const colId = params.column.getColId();
      if (colIds.has(colId)) {
        return params.formatValue(params.value);
      }
      return commonProcessCb(params);
    }

    return {
      processCellCallback: processCb,
      fileName,
    };
  }, [colIds, fileName]);

  return { exportParams };
}

export function getExportParams(params: Partial<ExcelExportParams | CsvExportParams> = {}) {
  let fileName = params?.fileName;
  if (fileName && typeof fileName === 'string') {
    fileName = sanitizeAsFileName(fileName!);
    return merge({}, params, { fileName });
  }
  return params;
}

const dateFormatters = new Set<string>(Object.values(DateFormattersId));
export function processValueForExport(value: unknown, format?: StandardFormatterId | string) {
  switch (true) {
    case dateFormatters.has(format ?? '') && value instanceof Date: {
      return getPlainDateString(value);
    }
    case format?.startsWith('percent2dpAsIs') && typeof value === 'number': {
      return value / 100;
    }

    default:
      return value;
  }
}

function isPercentColumn(
  type: string | string[] | undefined,
  cellClass: (string | CellClassFunc | undefined)[] | undefined
) {
  return (
    type === 'percent' ||
    type?.includes('percent') ||
    cellClass?.some((cls) => typeof cls === 'string' && cls.includes('percent'))
  );
}
