import { useEffect, useMemo, ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import { Stepper, Step, StepLabel } from '@mui/material';
import { fundFormCurrentStepState, useResetFundFormState, selectedFundStateFP } from '../../state/FPState';
import { StepperContainer, StepperSx, StepSx } from '../../../../components/Form/FormComponents';
import { IFundFormProps } from '../FundWaterfallForm';
import { AllocationStep1 } from './AllocationStep1';
import { AllocationStep2 } from './AllocationStep2';

export function FundCapitalAllocationForm({ defaultValues, onSubmit }: Omit<IFundFormProps, 'onCancel'>) {
  const fund = useRecoilValue(selectedFundStateFP);
  const currentStep = useRecoilValue(fundFormCurrentStepState);
  const resetForm = useResetFundFormState();

  useEffect(() => {
    return resetForm;
  }, [resetForm]);

  const steps = useMemo(
    () => [
      { label: 'Allocation', component: <AllocationStep1 defaultValues={defaultValues} /> },
      { label: 'Pacing', component: <AllocationStep2 onSubmit={onSubmit} /> },
    ],
    [defaultValues, onSubmit]
  );

  if (!fund) return null;

  return (
    <StepperContainer>
      <Stepper activeStep={currentStep} sx={StepperSx}>
        {steps.map(({ label }) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: ReactNode;
          } = {};
          return (
            <Step key={label} {...stepProps} sx={StepSx}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <>{steps[currentStep]?.component}</>
    </StepperContainer>
  );
}
