import { useMemo } from 'react';
import { sub } from 'date-fns';
import { useRecoilValue } from 'recoil';
import { fetchTransactions2 } from '../../../services/queries/MaggieTransactionQueries';
import { ITransactionDataModel } from '../../../data-models/transaction.data-model';
import { allInvestmentRoundsMapState } from '../state/FinanceState';
import { getPlainDateString } from '../../../services/queries/MaggieMetricsQueries';
import { useAsync } from '../../../util/hook-utils';
import { transactionsDateFilterState } from './AllTransactionsFilterState';

export interface IExtendedTransactionDataModel extends ITransactionDataModel {
  investmentName?: string | null;
}

export function defaultDateRange() {
  return {
    fromDate: getPlainDateString(sub(new Date(), { years: 1 })),
    toDate: getPlainDateString(new Date()),
  };
}

interface ITransactionDataParams {
  fromDate?: string;
  toDate?: string;
  forceRefetchCount?: number;
}

export function useAllTransactionsData({ forceRefetchCount }: ITransactionDataParams = {}) {
  const investmentsById = useRecoilValue(allInvestmentRoundsMapState);
  const { fromDate, toDate } = useRecoilValue(transactionsDateFilterState);

  const fetchData = useMemo(() => {
    return async () => fetchTransactions2({ fromDate, toDate });
  }, [fromDate, toDate]);

  const { data, loading, error } = useAsync(fetchData, {
    id: String(forceRefetchCount),
  });

  const allTransactions: IExtendedTransactionDataModel[] | undefined = useMemo(() => {
    if (!data) return;
    return data.transactions.map((t) => {
      const investment = investmentsById.get(t.investmentRoundId ?? -1);
      return {
        ...t,
        investmentName: investment?.name,
        roundId: investment?.roundId ?? t.roundId,
      };
    });
  }, [data, investmentsById]);

  return { loading, error, allTransactions };
}
