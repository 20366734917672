import { getDatesInRange } from '../../CompanyProfiles/Performance/components/Charts/PerfBarChart';
import { KpiPeriod } from '../../../data-models/company-financials.data-model';
import { getFormattedFiscalDate } from '../../CompanyProfiles/utils/financialUtils';
import { getPlainDateString } from '../../../services/queries/MaggieMetricsQueries';
import {
  FundTransaction,
  fundTransactionsFields,
  FundTransactionType,
} from '../../../schemas/FundContributions.schema';
import { colors } from '../../../theme/colors';
import { MixedSeriesOption } from '../../../components/Charts/MixedChart/MixedChart';
import { formatFieldValue } from '../../../util/schema-utils';

const typeToColor = {
  [FundTransactionType.lpContributions]: colors.neutral[30],
  [FundTransactionType.gpContributions]: colors.neutral[50],
  [FundTransactionType.lpDistributions]: colors.tertiary[40],
  [FundTransactionType.gpDistributions]: colors.secondary[40],
};

export const YearRange = 5;
const today = new Date();
export function getTimeCategories(): string[] {
  return getDatesInRange(KpiPeriod.quarter, today, YearRange * 4).map((date) => {
    return getFormattedFiscalDate(getPlainDateString(date), KpiPeriod.quarter, 12);
  });
}

export function getContributionsDistributionsSeries(data: FundTransaction[]): MixedSeriesOption[] {
  const dataMap = data.reduce((acc, curr) => {
    const formattedPeriod = getFormattedFiscalDate(curr.date, KpiPeriod.quarter, 12);
    const type = curr.type;
    const isContribution = curr.type.match(/contributions/i);
    const amount = isContribution ? -curr.amount : curr.amount;

    if (acc.has(type)) {
      if (acc.get(type)?.get(formattedPeriod)) {
        acc.get(type)?.set(formattedPeriod, {
          amount: (acc.get(type)!.get(formattedPeriod)?.amount ?? 0) + amount,
          type: curr.type,
          date: formattedPeriod,
        });
      } else {
        acc.get(type)?.set(formattedPeriod, { amount, type: curr.type, date: formattedPeriod });
      }
    } else {
      acc.set(
        type,
        new Map<string, FundTransaction>([
          [formattedPeriod, { amount, type: curr.type, date: formattedPeriod }],
        ])
      );
    }

    return acc;
  }, new Map<FundTransactionType, Map<string, FundTransaction>>());
  const result: MixedSeriesOption[] = [];

  [...dataMap.keys()].forEach((key) => {
    const series: MixedSeriesOption = {
      barMinHeight: 1,
      name: key,
      stack: 'Contributions / Distributions',
      color: typeToColor[key as FundTransactionType],
      type: 'bar',
      data: [...dataMap.get(key)!.values()].map((transaction) => [
        transaction.date,
        transaction.amount || null,
      ]),
      label: {
        formatter: (value: number | null) => formatFieldValue(fundTransactionsFields().amount, value, ''),
      },
      tooltip: {
        valueFormatter: (value: number | null) =>
          formatFieldValue(fundTransactionsFields().amount, value, '-'),
      },
    };
    result.push(series);
  });

  return result;
}
