import { ColDef } from 'ag-grid-community';
import { useMemo } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import NotSentActionsCellRenderer from '../components/NotSentActionsCellRenderer';
import { MaggieFeatureFlags } from '../../../../../util/feature-flags';
import { useCommonKpiColDefs } from './useCommonKpiColDefs';
import { IKpiRequestGridData } from './useKPIRequestsFilters';

export default function useKPIRequestsNotSentColDefs() {
  const {
    respondentColDef,
    templateColDef,
    frequencyColDef,
    periodColDef,
    fyeColDef,
    commonCompanyDefs,
    granularityColDef,
  } = useCommonKpiColDefs();
  const { showGranularitySelector } = useFlags<MaggieFeatureFlags>();

  const columnDefs: ColDef<IKpiRequestGridData>[] = useMemo(() => {
    const res = [
      commonCompanyDefs,
      templateColDef,
      frequencyColDef,
      fyeColDef,
      periodColDef,
      respondentColDef,
      {
        maxWidth: 120,
        pinned: 'right',
        sortable: false,
        resizable: false,
        cellRenderer: NotSentActionsCellRenderer,
      },
    ] as ColDef<IKpiRequestGridData>[];

    if (showGranularitySelector) {
      res.splice(3, 0, granularityColDef as unknown as ColDef<IKpiRequestGridData>);
    }

    return res;
  }, [
    commonCompanyDefs,
    frequencyColDef,
    fyeColDef,
    granularityColDef,
    periodColDef,
    respondentColDef,
    showGranularitySelector,
    templateColDef,
  ]);

  const defaultColDef = useMemo<ColDef>(
    () => ({
      flex: 1,
      minWidth: 120,
      resizable: true,
      sortable: true,
      filter: false,
      menuTabs: [],
      suppressMovable: true,
      getQuickFilterText: () => {
        return '';
      },
    }),
    []
  );

  return {
    columnDefs,
    defaultColDef,
  };
}
