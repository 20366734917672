import { useCallback, useMemo, useState } from 'react';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { Box, IconButton, useTheme } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import { IScenarioRunDataModel } from '../../../../../data-models/scenario-run.data-model';
import { AgTable } from '../../../../../components/AgTable/AgTable';
import { defaultColDef } from '../../../../Finance2/common-grid-defs/defaultColDefs';
import { FadeInGridWrapper } from '../../../../../components/grid-renderers/FadeInGridWrapper';
import { runAggFuncs } from '../../utils/runAggFuncs';
import { removeTotalRow } from '../../utils/runUtils';
import { ScenarioTypeByTransactions } from './ScenarioVisualization';

export interface IScenarioRunGridProps {
  colDefs: ColDef[];
  scenarioRunData: IScenarioRunDataModel[];
  scenarioType: ScenarioTypeByTransactions;
}
export function ScenarioRunGrid(props: IScenarioRunGridProps) {
  const { colDefs, scenarioRunData, scenarioType } = props;
  const { colors } = useTheme();

  const [api, setApi] = useState<GridApi | null>(null);

  const onGridReady = useCallback((event: GridReadyEvent<unknown>) => {
    setApi(event.api);
    event.api.sizeColumnsToFit();
    setTimeout(() => event.api.autoSizeColumns(['name']), 0);
  }, []);

  const onExportToExcel = useCallback(() => {
    api?.exportDataAsExcel({
      sheetName: 'Scenario Run',
      fileName: 'scenario-run',
    });
  }, [api]);

  const rowData = useMemo(() => removeTotalRow(scenarioRunData), [scenarioRunData]);

  return (
    <FadeInGridWrapper>
      <Box
        sx={{
          width: '100%',
          height: '25rem',

          position: 'relative',
          '& .ag-row-footer': {
            backgroundColor: `${colors.secondary[5]}`,
            fontWeight: 'bolder',
            borderTop: `1px solid ${colors.neutral[30]}`,
          },
        }}
      >
        <IconButton
          onClick={onExportToExcel}
          color='primary'
          title='export to excel'
          sx={{ position: 'absolute', top: '-2.25rem', right: '0.5rem' }}
        >
          <DownloadIcon />
        </IconButton>
        <AgTable
          onGridReady={onGridReady}
          rowData={rowData}
          columnDefs={colDefs}
          defaultColDef={{ ...defaultColDef, minWidth: 130 }}
          rowGroupPanelShow='never'
          suppressMovableColumns={true}
          suppressRowDrag={true}
          grandTotalRow='bottom'
          aggFuncs={runAggFuncs}
          context={{ scenarioType }}
        />
      </Box>
    </FadeInGridWrapper>
  );
}
