import { useCallback, useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { GridOptions, GridReadyEvent } from 'ag-grid-community';
import { useAsync } from '../../../../util/hook-utils';
import { getSourcingTables } from '../../../../services/queries/MaggieSourcingQueries';
import { AgTableWithBaseStyle } from '../../../../components/AgTable/AgTable2';
import { useToken } from '../../../../services/hooks/useToken';
import { getServerSideDatasource, useSourcingColDefs } from './sourcingGridDefs';

export function MinerOverviewProto() {
  const getToken = useToken();
  const { data: token } = useAsync(getToken);
  const { data: companyTable } = useAsync(
    useCallback(
      () => getSourcingTables().then((tables) => tables.tables?.find((t) => t.name == 'companies')),
      []
    )
  );
  const initialColDefs = useSourcingColDefs(companyTable);

  const gridOptions: GridOptions = useMemo(() => {
    return {
      columnDefs: initialColDefs,
      rowModelType: 'serverSide',
      maxBlocksInCache: 3,
      cacheBlockSize: 100,
    };
  }, [initialColDefs]);

  const onGridReady = useCallback(
    (e: GridReadyEvent) => {
      e.api.setGridOption('serverSideDatasource', getServerSideDatasource(e.api, token ?? ''));
    },
    [token]
  );

  if (!companyTable) return null;
  return (
    <Stack width='100%'>
      <Typography variant='h4'>Overview</Typography>
      <Stack height='85vh' width='100%' mt='1rem'>
        <AgTableWithBaseStyle gridOptions={gridOptions} onGridReady={onGridReady} />
      </Stack>
    </Stack>
  );
}
