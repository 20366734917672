import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, debounce, Stack, SxProps, TextField } from '@mui/material';
import { ControllerRenderProps } from 'react-hook-form';
import { BorderlessInputSx } from '../../../../../constants/styles';
import { colorStatusField } from '../../../../../data-fields/CommonFields';
import { createRubric } from '../../../../../schemas/Rubric.schema';
import { EntityType } from '../../../../../data-models/field2.data-model';
import { FieldFactory } from '../../../../../components/Form/FieldFactory';
import { getPlainDateString } from '../../../../../services/queries/MaggieMetricsQueries';
import { StatusColorName } from '../../../../../components/Form/ColorStatus/ColorStatus';
import { useCreateOrEditRubric } from './rubricActions';
import { useRubricFormData } from './useRubricFormData';
import { RubricsRowStyles } from './RubricList';

const newColorField = {
  ...colorStatusField({
    required: true,
    key: 'newField',
    label: '',
    inlineLabel: true,
    rendererMeta: {
      // hide until we support deleting rubrics (https://foresightdata.atlassian.net/browse/MAGGIE-8421)
      displayEmpty: false,
    },
  }),
};
interface IAddRubricProps {
  companyId: number;
}
export function AddRubric({ companyId }: IAddRubricProps) {
  const [status, setStatus] = useState<StatusColorName | string>(StatusColorName.Green);
  const onStatusChange = useCallback((newStatus: string) => {
    setStatus(newStatus);
  }, []);
  const [rubricConfigId, setRubricConfigId] = useState<number | null>(null);

  const editOrCreateRubric = useCreateOrEditRubric();
  const { configOptions, getConfigLabel, selectedDate } = useRubricFormData();

  const createNew = useMemo(
    () =>
      debounce(async () => {
        if (!status || !rubricConfigId) return;
        const payload = {
          ...createRubric({
            rubricConfigId,
            value: status,
            date: getPlainDateString(selectedDate),
            entity: EntityType.company,
            entityId: companyId,
          }),
        };
        await editOrCreateRubric(payload);
        setRubricConfigId(null);
        setStatus(StatusColorName.Green);
      }, 300),
    [companyId, editOrCreateRubric, rubricConfigId, selectedDate, status]
  );

  useEffect(() => {
    if (!status || !rubricConfigId) return;
    createNew();
  }, [status, rubricConfigId, createNew]);

  if (configOptions.length === 0) {
    return null;
  }
  return (
    <div css={RubricsRowStyles} style={{ paddingRight: '1rem' }}>
      <Stack direction={'row'} alignItems={'center'} mr='2rem'>
        <FieldFactory
          formField={newColorField}
          formProps={{ onChange: onStatusChange, value: status } as ControllerRenderProps}
        />
        <InlineSelect
          options={configOptions}
          getOptionLabel={getConfigLabel}
          value={rubricConfigId}
          onChange={setRubricConfigId}
          style={{ marginLeft: '-1rem' }}
        />
      </Stack>
    </div>
  );
}

const sx: SxProps = {
  ...BorderlessInputSx,
  width: '20rem',
  '& .MuiAutocomplete-endAdornment': {
    visibility: 'hidden',
  },
};

interface IInlineSelectProps {
  value: number | null;
  onChange: (value: number | null) => void;
  options: number[];
  getOptionLabel: (id: number) => string;
  style?: CSSProperties;
}
export function InlineSelect({ value, onChange, options, getOptionLabel, style }: IInlineSelectProps) {
  return (
    <>
      <Autocomplete
        sx={sx}
        style={style}
        options={options}
        renderInput={(params) => {
          const { inputProps, InputProps, ...rest } = params;
          return (
            <TextField
              inputProps={inputProps}
              InputProps={{
                ...InputProps,
              }}
              placeholder={'Add rubric'}
              {...rest}
            />
          );
        }}
        getOptionLabel={getOptionLabel}
        value={value}
        onChange={(e, newValue) => {
          onChange(newValue);
        }}
        componentsProps={{
          paper: {
            style: {
              fontSize: '0.875rem',
            },
          },
        }}
      />
    </>
  );
}
