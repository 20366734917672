import { IconButton, Stack, useTheme } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import { Link } from 'react-router-dom';
import { ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';
import { CustomCellRendererProps } from 'ag-grid-react';
import { ROUTES } from '../../../../../constants/RouteNameMapping';
import { KPIRequestStatus } from '../../../../../data-models/kpi-requests.data-model';
import { IKpiRequestGridData } from '../hooks/useKPIRequestsFilters';
import { colors } from '../../../../../theme/colors';

export default function ActionsCellRenderer(params: ICellRendererParams) {
  const { colors } = useTheme();
  const requestIdResponseRoute = useMemo(() => {
    return ROUTES.KPI_REQUEST_REVIEW.replace(':requestId', `${params?.data?.id}`);
  }, [params?.data?.id]);

  const status = params?.data?.status;
  let Icon = ManageSearchIcon;
  if (status === KPIRequestStatus.Accepted) Icon = PlaylistAddCheckIcon;
  if (status === KPIRequestStatus.Rejected) Icon = PlaylistRemoveIcon;

  if (params?.node?.group) return null;

  return (
    <Stack
      display='grid'
      gridTemplateColumns={'repeat(2, minmax(0, 1fr))'}
      justifyContent='space-between'
      alignItems='center'
    >
      <IconButton
        target='_blank'
        component={Link}
        to={`/${requestIdResponseRoute}`}
        color='secondary'
        size={'medium'}
      >
        <Icon sx={{ color: colors.primary[60] }} />
      </IconButton>
      <FileAttachedRenderer {...params} />
    </Stack>
  );
}

function FileAttachedRenderer(params: CustomCellRendererProps<IKpiRequestGridData>) {
  if (!params.data?.kpiRequestResponse?.sectionData?.some((s) => (s.value as { fileId: string })?.fileId)) {
    return null;
  }

  return (
    <Stack
      width={'100%'}
      height={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
      title='Contains attached file(s)'
    >
      <AttachFileIcon htmlColor={colors.neutral[50]} fontSize='small' />
    </Stack>
  );
}
