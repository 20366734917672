import { ColDef, GetRowIdParams, GridApi, GridReadyEvent, IRowNode } from 'ag-grid-community';
import { useAtomValue } from 'jotai';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Box } from '@mui/material';
import { AgTable } from '../../../components/AgTable/AgTable';
import { companyState } from '../../../services/state/CompanyState';
import { NoDataMessage2 } from '../../CompanyProfiles/components/Messages/HelpMessage';
import { selectedCompanyIdProfileAtom } from '../../CompanyProfiles/state/UIStateJ';
import { lastFieldUpdateDateState } from '../state/MappingsState';
import { filterOutErrorsState } from '../state/MappingsUIState';
import { ErrorFilter } from './ErrorFilter';
import { ErrorInfo } from './ErrorInfo';
import { useMappingGridColDefs } from './hooks/useMappingGridColDefs';
import { useMappingsRowData } from './hooks/useMappingsRowData';
import { categoryComparator, IMappingsRowDataModel, isWebsiteProvider } from './mappingDataUtils';

export const MappingsGrid: FC = () => {
  const filterErrors = useAtomValue(filterOutErrorsState);
  const [gridApi, setGridApi] = useState<GridApi>();
  const companyId = useAtomValue(selectedCompanyIdProfileAtom);
  const selectedCompany = useRecoilValue(companyState(companyId));
  const lastFieldUpdateDate = useRecoilValue(lastFieldUpdateDateState);

  const onGridReady = useCallback((event: GridReadyEvent) => {
    setGridApi(event.api);
  }, []);

  const isExternalFilterPresent = useCallback(() => filterErrors, [filterErrors]);
  const doesExternalFilterPass = useCallback((node: IRowNode<IMappingsRowDataModel>) => {
    const { data } = node;
    if (!data) return false;
    return data.hasError || isWebsiteProvider(data);
  }, []);

  const { rowData, loading } = useMappingsRowData();

  const colDefs = useMappingGridColDefs();
  const defaultColDefs: ColDef = {
    flex: 1,
    width: 200,
    minWidth: 200,
    resizable: true,
  };
  const autoGroupColumnDef: ColDef = useMemo(
    () => ({
      width: 260,
      pinned: 'left',
      menuTabs: ['filterMenuTab', 'generalMenuTab'],
      comparator: categoryComparator,
      initialSort: 'asc',
      sortable: false,
    }),
    []
  );

  const getRowId = useCallback((params: GetRowIdParams) => String(params.data?.id), []);

  useEffect(() => {
    gridApi?.onFilterChanged();
  }, [filterErrors, gridApi]);

  const gridKey = selectedCompany?.id ?? '';

  if (!rowData && !loading) return <NoDataMessage2 />;
  if (!rowData || !selectedCompany) return null;

  return (
    <div style={{ position: 'relative' }}>
      <ErrorFilter />
      <ErrorInfo rowData={rowData} />
      <Box height='62dvh'>
        <AgTable
          key={gridKey + (lastFieldUpdateDate ?? '')}
          onGridReady={onGridReady}
          columnDefs={colDefs}
          rowData={rowData}
          defaultColDef={defaultColDefs}
          groupDefaultExpanded={1}
          suppressMovableColumns={true}
          suppressRowDrag={true}
          rowGroupPanelShow={'never'}
          autoGroupColumnDef={autoGroupColumnDef}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          getRowId={getRowId}
        />
      </Box>
    </div>
  );
};
