import { useMemo } from 'react';
import { Stack } from '@mui/system';
import {
  GeneralLedgerUploadData,
  generalLedgerUploadDataSchema,
} from '../../../schemas/GeneralLedgerUploadData.schema';
import { useSchemaToGrid } from '../../../util/schema-utils';
import { FadeInGridWrapper } from '../../../components/grid-renderers/FadeInGridWrapper';
import { AgTable } from '../../../components/AgTable/AgTable';

export function GeneralLedgerUploadsGrid({ data }: { data: GeneralLedgerUploadData[] }) {
  const columnDefs = useGeneralLedgerUploadsColDefs();
  return (
    <FadeInGridWrapper>
      <Stack height='80vh' mt='1rem'>
        <AgTable
          columnDefs={columnDefs}
          rowData={data}
          defaultColDef={defaultColDef}
          rowGroupPanelShow='never'
        />
      </Stack>
    </FadeInGridWrapper>
  );
}

function useGeneralLedgerUploadsColDefs() {
  const schemaToGrid = useSchemaToGrid();
  return useMemo(() => {
    const schema = generalLedgerUploadDataSchema();
    return schemaToGrid(schema);
  }, [schemaToGrid]);
}

const defaultColDef = {
  flex: 1,
};
