import { useFlags } from 'launchdarkly-react-client-sdk';
import { capitalize, cloneDeep } from 'lodash-es';
import { UseFormReturn } from 'react-hook-form';
import {
  FieldEntity,
  IField,
  ISimpleChoice,
  KpiConfigScenario,
  KpiConfigScenarioToName,
  KpiConfigSection,
} from '../../../data-models/field2.data-model';
import { KpiConfigPeriod, KpiConfigPeriodToName } from '../../../data-models/company-financials.data-model';
import { RendererType } from '../../../data-models/field.data-model';
import {
  createField3DataModel,
  IField3,
  ISelectMeta,
  ITextMeta,
} from '../../../data-models/field3.data-model';
import { sortGenericSortable } from '../../../data-models/app-config.data-model';
import { useGetKPIGroupField } from '../../../data-fields/KPIFields';
import { MaggieFeatureFlags } from '../../../util/feature-flags';
import { KPIGroup } from '../../../schemas/kpi-group.schema';
import { IForm } from '../../../view-models/form.view-model';

export const KPINoGroupName = 'No Group';

enum KpiGroupOptions {
  incomeStatement = 'Income Statement',
  balanceSheet = 'Balance Sheet',
  cash = 'Cash',
  drivers = 'Drivers',
  other = 'Other',
}

export function useGetKpiFields(): IField3<unknown>[] {
  const { showManageGroups, showConfigKPIForm2 } = useFlags<MaggieFeatureFlags>();
  const getGroupField = useGetKPIGroupField();

  const fields: IField3<unknown>[] = [
    createField3DataModel<ITextMeta>({
      id: 0,
      entity: FieldEntity.universalKPI,
      entityKey: 'displayName',
      displayName: 'Name',
      format: RendererType.text,
      meta: {
        maxLength: -1,
      },
    }),
    createField3DataModel<ISelectMeta<string>>({
      id: 1,
      entity: FieldEntity.universalKPI,
      entityKey: 'entity',
      format: RendererType.singleSelect,
      displayName: 'Type',
      meta: {
        multi: false,
        values: [
          { value: FieldEntity.universalKPI, displayName: 'Universal KPI' },
          { value: FieldEntity.userKPI, displayName: 'Custom KPI' },
          { value: FieldEntity.systemKPI, displayName: 'System KPI', disabled: true },
        ],
        formatValueToLabel: true,
      },
    }),
    createField3DataModel<ISelectMeta<string>>({
      id: 2,
      entity: FieldEntity.universalKPI,
      entityKey: 'formMeta.formatter.type',
      displayName: 'Unit',
      format: RendererType.singleSelect,
      meta: {
        multi: false,
        values: [
          { value: RendererType.currency, displayName: 'currency ($)' },
          { value: RendererType.percent, displayName: 'percent (%)' },
          { value: RendererType.numeric, displayName: 'numeric (#)' },
          { value: RendererType.multiplier, displayName: 'multiplier (X)' },
        ],
        formatValueToLabel: true,
      },
    }),
  ];

  if (showManageGroups) {
    fields.push(getGroupField('kpiGroupId', 'Group'));
  } else {
    fields.push(
      createField3DataModel<ISelectMeta<string>>({
        id: 3,
        entity: FieldEntity.universalKPI,
        entityKey: 'gridMeta.group',
        displayName: 'Group',
        meta: {
          values: Object.values(KpiGroupOptions).map((value) => ({ value, displayName: value })),
          formatValueToLabel: true,
        },
      })
    );
  }

  if (showConfigKPIForm2) {
    fields.push(
      createField3DataModel<ISelectMeta<string>>({
        id: 4,
        entity: FieldEntity.universalKPI,
        entityKey: 'sectionType',
        displayName: 'Scenario',
        format: RendererType.singleSelect,
        meta: {
          multi: false,
          values: Object.values(KpiConfigSection).map((value) => ({ value, displayName: capitalize(value) })),
          formatValueToLabel: true,
        },
      }),
      createField3DataModel<ISelectMeta<string>>({
        id: 5,
        description:
          "Accrued KPIs are aggregated for quarterly and yearly totals, while End of Period KPIs use the most recent month's value for these totals",
        entity: FieldEntity.universalKPI,
        entityKey: 'kpiScenario',
        displayName: 'Aggregation',
        format: RendererType.singleSelect,
        meta: {
          multi: false,
          values: Object.entries(KpiConfigScenarioToName).map(([key, value]) => ({
            value: key,
            displayName: value,
          })),
          formatValueToLabel: true,
        },
      }),
      createField3DataModel<ISelectMeta<string>>({
        id: 6,
        description:
          'Please choose the periods that you want to display for this KPI in the portfolio reporting grid',
        entity: FieldEntity.universalKPI,
        entityKey: 'periods',
        displayName: 'Period',
        format: RendererType.multiSelect,
        meta: {
          multi: false,
          values: Object.entries(KpiConfigPeriodToName).map(([key, value]) => ({
            value: key,
            displayName: value,
          })),
          formatValueToLabel: true,
        },
      })
    );
  }

  return fields;
}

export function getKPIsByGroupSorted(kpis: IField<unknown>[], kpiGroups: Map<number, KPIGroup>) {
  const kpisByGroup = kpis.reduce(
    (res, kpi) => {
      const kpiGroup = kpi.kpiGroupId ? kpiGroups.get(kpi.kpiGroupId) : undefined;
      const groupName = kpiGroup?.name ?? KPINoGroupName;

      if (res[groupName]) {
        res[groupName].push(kpi);
      } else {
        res[groupName] = [kpi];
      }
      return res;
    },
    {} as Record<string, IField<unknown>[]>
  );

  Object.values(kpisByGroup).forEach((kpis) => kpis.sort(sortGenericSortable));

  return kpisByGroup;
}

export const allowedValuesForEndOfPeriod = new Set<KpiConfigPeriod>([
  KpiConfigPeriod.GrowthMonth,
  KpiConfigPeriod.GrowthQuarter,
  KpiConfigPeriod.GrowthYear,
  KpiConfigPeriod.Latest,
]);
const allowedValuesForOtherScenarios = new Set<KpiConfigPeriod>(
  Object.values(KpiConfigPeriod).filter((v) => v !== KpiConfigPeriod.Latest)
);
export const unitFieldPath = 'formMeta.formatter.type';
export const SystemKpiUneditableFields = new Set(['entity', unitFieldPath, 'displayName', 'kpiScenario']);
export function getKpiFormFields(form: IForm<IField<unknown>>, methods: UseFormReturn<IField<unknown>>) {
  if (!methods) return form;
  const dynamicForm = cloneDeep(form);
  const scenario = methods.watch('kpiScenario');
  syncPeriodsWithScenario(dynamicForm, methods, scenario);

  if (isEditMode(form)) {
    dynamicForm.fields.set(unitFieldPath, {
      ...dynamicForm.fields.get(unitFieldPath)!,
      disabled: true,
    });
    if (methods.watch('entity') === FieldEntity.systemKPI) {
      SystemKpiUneditableFields.forEach((field) => {
        dynamicForm.fields.set(field, {
          ...dynamicForm.fields.get(field)!,
          disabled: true,
        });
      });
    }
  }
  return dynamicForm;
}

function isEditMode(form: IForm) {
  return typeof form.data?.['id' as keyof typeof form.data] === 'number';
}

function syncPeriodsWithScenario(
  form: IForm<IField<unknown>>,
  methods: UseFormReturn<IField<unknown>>,
  scenario?: KpiConfigScenario
) {
  const allowedValues =
    scenario === KpiConfigScenario.EndOfPeriod ? allowedValuesForEndOfPeriod : allowedValuesForOtherScenarios;

  methods.setValue('periods', methods.getValues('periods')?.filter((v) => allowedValues.has(v)) ?? []);

  form.fields.set('periods', {
    ...form.fields.get('periods')!,
    rendererMeta: {
      ...(form.fields.get('periods')?.rendererMeta ?? {}),
      values: Object.entries(KpiConfigPeriodToName).reduce((acc, [key, value]) => {
        if (allowedValues.has(key as KpiConfigPeriod)) {
          acc.push({
            value: key,
            displayName: value,
          });
        }
        return acc;
      }, [] as ISimpleChoice<string>[]),
    },
  });
}
